import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Button, CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

// Icons
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AddRunIcon } from "src/icons/NewUX";

import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { useAutoMLRecipeContext } from "../AutoMLRecipeContext/useAutoMLRecipeContext";
import { QUERY_KEY_RECIPE, useGetScenario } from "src/hooks/api";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { handleResponse } from "src/utils/apiService";
import { useValidateRecipeName } from "../../common/useValidateRecipeName";
import { Scenario } from "src/types";
import { DISABLED_RECIPE_LOGS, READONLY_ENTITY } from "src/constants";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import useStopRecipe from "src/pages/private/ProjectsModule/pages/Dag/hooks/useStopRecipe";
import { useCanvasStore } from "src/store/store";
import StopCircleOutlined from "src/icons/StopCircleOutlined";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { toastWrapper } from "src/utils/toastWrapper";
import { useQueryClient } from "@tanstack/react-query";

export const AutoMLRecipeHeader = ({ isDefaultScenario }: { isDefaultScenario: boolean }) => {
  const { projectId, scenarioId, jobRunId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  const {
    jobData,
    jobRunData,
    recipe,
    recipeDisplayName,
    handleSave,
    handleRun,
    handleUpdateRecipeRunTimeout,
    isSaveDisabled,
    isSaveInProgress,
    isAddRecipeToQueue,
    isAddingRecipeToQueue,
    isRunDisabled,
    isRunInProgress,
    isRecipeTimeoutUpdating,
    handleUpdateRecipeName,
    saveDisabledMessage,
    runDisabledMessage,
    scenarioData,
    setScenarioData
  } = useAutoMLRecipeContext();
  const queryClient = useQueryClient();
  const { canAccessRecipeTimeout } = useAccessControlContext();
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const stopRecipe = useStopRecipe();
  const [stopRecipeModalOpen, setStopRecipeModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);
  const setReloadTriggerWithDelay = useCanvasStore((state) => state.setReloadTriggerWithDelay);

  const { isLoading: isScenarioLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });
  const { validateName } = useValidateRecipeName();

  const handleLogsClick = () => {
    setOpenLogs(true);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick();
    }
  }, [isOpenLogs]);

  const stopRunningRecipe = () => {
    if (recipe?.id) {
      stopRecipe.mutate(
        { groupId: recipe?.id, scenarioId },
        {
          onSuccess: () => {
            setStopRecipeModalOpen(false);
            toastWrapper({ type: "info", content: "Recipe stop action is completed" });
            setReloadTriggerWithDelay();
            queryClient.invalidateQueries([QUERY_KEY_RECIPE]);
          }
        }
      );
    }
  };

  const handleCancel = () => {
    setStopRecipeModalOpen(false);
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleBack = useCallback(() => {
    if (projectId && scenarioId) {
      navigate(-1);
    }
  }, [history, projectId, scenarioId]);

  const updateTransformName = React.useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else {
        handleUpdateRecipeName({
          recipeName: cleanedName,
          onError: () => setShouldResetName(Date.now())
        });
      }
    },
    [handleUpdateRecipeName, validateName]
  );

  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSearchParams({});
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={handleBack}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: Deleting this might impact associated dataapps(if any).</span>
            {psMessage}
          </div>
        }
      />
      {!!stopRecipeModalOpen && (
        <NewThemeWrapper>
          <Modal
            open
            variant={ModalVariants.Delete}
            title={
              <Grid direction="column" container>
                <Typography>Stop Recipe Run</Typography>
                <span style={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                  This process may take some time to get completed.
                </span>
              </Grid>
            }
            content={[
              "This action updates the recipe status to Unbuilt, shuts down the related project environment, and restarts it. This may cause any other recipes depending on this environment to fail.",
              <>
                <b>Note:</b> If recipe execution gets completed before associated environment
                restart then the status of the recipe will be based on the run result.
              </>,
              "Are you sure you want to proceed with this?"
            ]}
            submitLabel="Yes, Proceed"
            onClose={handleCancel}
            isSubmitting={stopRecipe.isLoading}
            onSubmit={stopRunningRecipe}
          />
        </NewThemeWrapper>
      )}
      {openLogs && (
        <RecipeLogsDrawer
          isRunRecipeLogs={true}
          isRecipeRunning={isRunInProgress}
          name={recipe?.displayName || ""}
          open={openLogs}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              isScenarioLoading={isScenarioLoading}
              recipeName={recipeDisplayName || recipe?.name}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath && !!recipe?.id
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !isDefaultScenario,
                    ...(!isDefaultScenario ? { tooltip: READONLY_ENTITY } : {})
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={(timeout) => handleUpdateRecipeRunTimeout({ timeout })}
                              disabled={isRecipeTimeoutUpdating}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {!isJobPath && (
                <>
                  <Tooltip title={isDefaultScenario ? saveDisabledMessage : READONLY_ENTITY}>
                    <span>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => handleSave()}
                        disabled={isSaveDisabled || !isDefaultScenario}>
                        {isSaveInProgress ? <CircularProgress size={22} /> : "Save"}
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip title={runDisabledMessage}>
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => handleRun()}
                        disabled={isRunDisabled}
                        style={{ textTransform: "none" }}
                        startIcon={
                          isAddingRecipeToQueue || isRunInProgress ? (
                            <CircularProgress size={18} />
                          ) : isAddRecipeToQueue ? (
                            <AddRunIcon />
                          ) : (
                            <RunIcon />
                          )
                        }>
                        {isAddRecipeToQueue ? "Add to Queue" : "Run"}
                      </Button>
                    </span>
                  </Tooltip>
                  {isRunInProgress && (
                    <Tooltip
                      title={
                        stopRecipe.isLoading
                          ? "Stop Recipe Run is in Progress..."
                          : "Stop Recipe Run"
                      }>
                      <span>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => setStopRecipeModalOpen(true)}
                          style={{ textTransform: "none" }}
                          disabled={stopRecipe.isLoading}
                          startIcon={
                            stopRecipe.isLoading ? (
                              <CircularProgress size={18} />
                            ) : (
                              <StopCircleOutlined />
                            )
                          }>
                          Stop
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}

              <Tooltip title={!recipe?.id ? DISABLED_RECIPE_LOGS : ""}>
                <span>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleLogsClick}
                    disabled={!recipe?.id}>
                    <LogsNewIconV2
                      width="22"
                      height="22"
                      // New UX change
                      // The static value will be replaced with new theme at platform-level.
                      color={!recipe?.id ? "#4646b550" : "#4646b5"}
                    />
                  </Button>
                </span>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  );
};
