import React, { Dispatch, ReactNode, SetStateAction, useMemo } from "react";

// Packages
import { Link, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";

// Icons
import { FileImportIcon } from "src/icons/NewUX";

// Hooks
import { IEntity, QUERY_KEY_ENTITIES } from "src/hooks/api/entities/useEntities";

// Utils
import { dateFormat } from "src/utils/dateFormat";
import { WebPaths } from "src/routing/routes";

// Components
import { Table } from "src/components";

// Constants
import { SourceType } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";

// Types
import { SourceDataCell } from "./Source.types";

// Styles
import useStyles from "./Source.styles";
import { Button } from "@material-ui/core";
import { MODE } from "./Source";

type Props = {
  isJobPath: boolean;
  isDefaultScenario: boolean;
  dataset: IEntity;
  fileNames: ReactNode;
  connectorsMap: { [key: string]: any };
  mode: MODE | undefined;
  setMode: Dispatch<SetStateAction<MODE | undefined>>;
};

const SourceTable = (props: Props) => {
  const { isJobPath, dataset, fileNames, connectorsMap, isDefaultScenario, mode, setMode } =
    props || {};

  const classes = useStyles();

  const queryClient = useQueryClient();

  const onEdit = () => {
    queryClient.invalidateQueries([QUERY_KEY_ENTITIES]);
    setMode(MODE.EDIT);
  };

  const onAuditHistory = () => {
    setMode(MODE.AUDIT_HISTORY);
  };

  const columns = useMemo(
    () => [
      {
        id: "Source Type",
        accessor: "sourceType",
        Header: "Source Type",
        Cell: ({ row }: SourceDataCell) => {
          const { dataSourceId } = row?.original || {};

          if (!dataSourceId) {
            return (
              <Grid container direction="row">
                <Box className={classes.sourceImage}>
                  <FileImportIcon viewBox="0 0 18 18" />
                </Box>
                <Box>Local File</Box>
              </Grid>
            );
          }

          const connector = connectorsMap[dataSourceId ?? SourceType.FileUpload];

          return (
            <Grid container direction="row">
              <Box className={classes.sourceImage}>{connector?.dataSourceType?.image}</Box>
              <Box>{connector?.dataSourceType?.displayName}</Box>
            </Grid>
          );
        }
      },
      ...(!!dataset?.dataSourceId
        ? [
            {
              id: "Connection Name",
              accessor: "dataSourceName",
              Header: "Connection Name",
              Cell: ({ row }: SourceDataCell) => {
                const { dataSourceId, dataSourceName } = row?.original || {};

                return !!dataSourceId ? (
                  <Link
                    className={classes.link}
                    to={generatePath(WebPaths.DataConnector, { id: dataSourceId })}>
                    {dataSourceName}
                  </Link>
                ) : (
                  dataSourceName
                );
              }
            }
          ]
        : [
            {
              id: "File Names",
              Header: "File Names",
              Cell: () => fileNames
            }
          ]),
      {
        id: "Updated on",
        accessor: "updated",
        Header: "Updated on",
        Cell: ({ row }: SourceDataCell) => {
          const { updated, created } = row.original || {};

          return <span>{(dateFormat(updated) || dateFormat(created)) ?? ""}</span>;
        }
      },
      ...(!!connectorsMap[dataset?.dataSourceId]?.dataSourceType?.isSql
        ? [
            {
              id: "Query",
              accessor: "query",
              Header: "Query",
              Cell: () =>
                mode === MODE.EDIT ? (
                  !!isJobPath || !isDefaultScenario ? (
                    "View"
                  ) : (
                    "Edit"
                  )
                ) : (
                  <MuiLink className={classes.link} onClick={onEdit}>
                    {!!isJobPath || !isDefaultScenario ? "View" : "Edit"}
                  </MuiLink>
                )
            }
          ]
        : []),

      {
        id: "Actions",
        accessor: "query",
        Header: "Actions",
        Cell: () => (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled={mode === MODE.AUDIT_HISTORY}
            onClick={onAuditHistory}>
            Audit History
          </Button>
        )
      }
    ],
    [isJobPath, dataset?.dataSourceId, isDefaultScenario, fileNames, connectorsMap, mode, setMode]
  );

  return <Table size="small" data={[dataset]} columns={columns} />;
};

export default SourceTable;
