import React, { useMemo } from "react";
import SyncIcon from "@material-ui/icons/Sync";
import { Grid, Button, Chip, Tooltip } from "@material-ui/core";
import { includes, forEach } from "lodash";
import { useQueryClient } from "@tanstack/react-query";

import { FivetranStatus } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { QUERY_KEY_DATA_CONNECTOR } from "hooks/api/dataSources/useGetDataConnector";
import { UseGetDataSourcesQueryKeys } from "hooks/api/dataSources/useGetDataSources";
import { getStatusColor } from "utils/fivetran";
import { DataSourcesHelperText } from "../utils/DataSources.constants";
import { syncDataSource } from "src/api/dataSources";

interface IProps {
  isWrapped?: boolean;
  buttonsVariant?: "contained" | "outlined" | "text";
  buttonsSize?: "small" | "medium" | "large";
  id: string;
  status: FivetranStatus;
  onManualSync: (id: string) => void;
  showManualSync?: boolean;
}

const DataConnectorStatus: React.FC<IProps> = (props) => {
  const { buttonsVariant, buttonsSize, id, status, onManualSync, showManualSync } = props;

  const queryClient = useQueryClient();

  const statusColor = useMemo(() => getStatusColor(status), [status]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    syncDataSource(id);
    onManualSync(id);

    const timeout = setTimeout(async () => {
      forEach([UseGetDataSourcesQueryKeys.DataSources, QUERY_KEY_DATA_CONNECTOR], (query) =>
        queryClient.invalidateQueries([query])
      );
      clearTimeout(timeout);
    }, 30 * 1000);
  };

  return (
    <>
      <Tooltip
        title={
          status === FivetranStatus.inActive
            ? "A data connector in an inactive state is not accessible for importing data."
            : status === FivetranStatus.syncPaused
              ? "There was an issue during automatic syncing. Please perform manual sync to maintain data consistency."
              : ""
        }>
        <Chip
          label={status}
          style={{
            color: statusColor,
            border: `1px solid ${statusColor}`
          }}
          variant="outlined"
        />
      </Tooltip>

      {showManualSync &&
        includes([FivetranStatus.syncPaused, FivetranStatus.syncIssues], status) && (
          <Button
            variant={buttonsVariant || "text"}
            size={buttonsSize || "medium"}
            color="primary"
            onClick={handleClick}
            startIcon={<SyncIcon />}
            data-testid="manualSyncConnector">
            {DataSourcesHelperText.ManualSync}
          </Button>
        )}
    </>
  );
};

const wrapper: React.FC<IProps> = (props) => {
  const { isWrapped = true, id, status, onManualSync } = props;

  return isWrapped ? (
    <Grid container alignItems="center" wrap="nowrap" style={{ gap: 10 }}>
      <DataConnectorStatus
        id={id}
        status={status}
        onManualSync={onManualSync}
        buttonsVariant="outlined"
        buttonsSize="small"
      />
    </Grid>
  ) : (
    <DataConnectorStatus id={id} status={status} onManualSync={onManualSync} />
  );
};

export default wrapper;
