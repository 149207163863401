import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import ApiConnectorRecipeHeader from "./ApiConnectorRecipeHeader/ApiConnectorRecipeHeader";
import ApiConnectorRecipeTabContainer from "./ApiConnectorRecipeTabContainer/ApiConnectorRecipeTabContainer";
import CodeRecipeInputContainer from "../AddCodeRecipe/CodeRecipeInputContainer/CodeRecipeInputContainer";
import { AddEntitiesDialog } from "../AddStandardRecipe/AddEntitiesDialog/AddEntitiesDialog";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { EntityFeaturesResponse } from "src/types";
import { CollapseIcon, ExpandIcon } from "src/icons/NewUX";
import { Spinner } from "src/components";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { useApiConnectorRecipeContext } from "./ApiConnectorRecipeContext/useApiConnectorRecipeContext";
import { useGetEntityFeatures } from "src/hooks/api/transforms/useGetEntityFeatures";
import { useGetScenario } from "src/hooks/api/scenario/useGetScenario";
import { useScenariosStore } from "src/store/store";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import CodeRecipeCanvasEntitiesContainer from "../AddCodeRecipe/CodeRecipeCanvasEntites/CodeRecipeCanvasEntitiesContainer";

const useStyles = makeStyles({
  codeRecipeContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    backgroundColor: "#f5f5f5"
  },
  leftContainer: {
    borderRadius: "12px",
    background: "#FFFFFF",
    height: "fit-content",
    maxHeight: "100%"
  },
  leftSection: {
    gap: "18px",
    padding: "16px 0px 16px 16px",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    maxHeight: "100%",
    width: "calc(100% - 22px)",
    height: "inherit"
  },
  gridContainer: {
    height: "100%",
    gap: "18px",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0
  },
  codeRecipeBodyContainer: {
    flex: 1,
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    flexWrap: "nowrap",
    background: "#f5f5f5"
  },
  codeContainer: {
    borderLeft: "1px solid #CECECE",
    flexGrow: 1
  }
});

interface IProps {
  entityIds?: string[];
}

const ApiConnectorRecipeContainer: React.FC<IProps> = () => {
  const { projectId, scenarioId, jobRunId, jobId } = useParams();
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [showAddEntitiesDialog, setShowAddEntitiesDialog] = React.useState<boolean>(false);
  const scenarios = useScenariosStore((state) => state.scenarios);
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const classes = useStyles({ isJobPath, isExpanded });
  const [value, setValue] = React.useState<string>("codeEditor");

  const {
    inputDatasets,
    recipe,
    entityFeaturesMap,
    isSelectedEntitiesUpdateInProgess,
    setEntityFeaturesMap,
    handleInputDatasetsChange,
    allEntities,
    setCurrentSelectedApiRecipe,
    selectedApiRecipe,
    setSelectedApiRecipe,
    selectedArtifacts,
    selectedModels
  } = useApiConnectorRecipeContext();

  const { isLoading: isScenarioLoading, data } = useGetScenario({
    scenarioId,
    options: { cacheTime: undefined, refetchOnMount: true }
  });

  const { isFetching: isEntitiesLoading } = useGetEntityFeatures({
    datasetsToFetch: inputDatasets,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (entityFeatures: EntityFeaturesResponse) => {
      const newFeatures = inputDatasets?.reduce(
        (acc, dataset, index) => ({
          ...acc,
          [dataset?.name]: entityFeatures[index]
        }),
        {}
      );
      setEntityFeaturesMap(newFeatures);
    }
  });

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(data ?? null, scenarios, scenarioId),
    [data, scenarios, scenarioId]
  );
  const handleCollapse = () => {
    setIsExpanded((prev) => !prev);
  };

  if (isScenarioLoading) {
    return <Spinner />;
  }

  return (
    <Grid className={classes.codeRecipeContainer}>
      <ApiConnectorRecipeHeader scenarioData={data} isDefaultScenario={isDefaultScenario} />
      <Grid container direction="row" className={classes.codeRecipeBodyContainer}>
        <Grid
          item
          className={classes.gridContainer}
          style={{ width: isExpanded ? "350px" : "15px" }}>
          <Grid
            container
            style={{ flexWrap: "nowrap", height: "100%", gap: "10px", width: "inherit" }}>
            {isExpanded && (
              <Grid className={classes.leftSection}>
                {isEntitiesLoading ? (
                  <Spinner />
                ) : (
                  <CodeRecipeInputContainer
                    inputDatasets={inputDatasets}
                    entityFeaturesMap={entityFeaturesMap}
                    handleInputDatasetsChange={handleInputDatasetsChange}
                    isDefaultScenario={isDefaultScenario}
                    openAddEntitiesDialog={() => setShowAddEntitiesDialog(true)}
                    isSelectedEntitiesUpdateInProgess={isSelectedEntitiesUpdateInProgess}
                    isApiConnetorRcipe
                    selectedArtifacts={selectedArtifacts}
                    selectedModels={selectedModels}
                  />
                )}

                <Grid style={{ maxHeight: "50%", width: "100%", padding: "10px 5px" }}>
                  <CodeRecipeCanvasEntitiesContainer
                    projectId={projectId}
                    scenarioId={scenarioId}
                    isJobpath={isJobPath}
                    jobId={jobId}
                    jobRunId={jobRunId}
                    setValue={setValue}
                    recipeId={recipe?.id}
                    isApiConnector
                    selectedCodeRecipe={selectedApiRecipe}
                    setCurrentSelectedCodeRecipe={setCurrentSelectedApiRecipe}
                    setSelectedCodeRecipe={setSelectedApiRecipe}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container style={{ height: "100%", width: "12px" }} alignItems="center">
              <div
                onClick={handleCollapse}
                style={{
                  background: "#D9D9D9",
                  height: "66px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}>
                <IconButton style={{ padding: 0, width: "12px", color: "black" }}>
                  {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.codeContainer}>
          {isEntitiesLoading ? (
            <Spinner />
          ) : (
            <NewThemeWrapper>
              <ApiConnectorRecipeTabContainer
                isDefaultScenario={isDefaultScenario}
                value={value}
                setValue={setValue}
              />
            </NewThemeWrapper>
          )}
        </Grid>
      </Grid>
      {showAddEntitiesDialog && (
        <AddEntitiesDialog
          recipeId={recipe?.id}
          selectedInputDatasets={inputDatasets}
          selectedArtifacts={selectedArtifacts}
          selectedModels={selectedModels}
          showArtifacts
          showModels
          allEntities={allEntities || []}
          onClose={() => {
            setShowAddEntitiesDialog(false);
          }}
          onSubmit={({ updatedInputDatasets, updatedArtifacts, updatedModels }) =>
            handleInputDatasetsChange(updatedInputDatasets, updatedArtifacts, updatedModels)
          }
          isSubmitInProgress={isSelectedEntitiesUpdateInProgess}
          noEntitiesText="If you cannot see datasets here, please add it to the canvas first"
        />
      )}
    </Grid>
  );
};

export default ApiConnectorRecipeContainer;
