import { Box, Button, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { first, get } from "lodash";
import React, { useMemo, useState } from "react";
import { ExpandLess, ExpandMore, InfoOutlined } from "@material-ui/icons";

import { useAutoMLRecipeContext } from "../../AutoMLRecipeContext/useAutoMLRecipeContext";

import { useNavigate } from "react-router";
import _ from "lodash";

const useStyles = makeStyles({
  title: {
    fontSize: "14px",
    fontWeight: 400
  },
  freezeColBox: {
    padding: "4px 0px",
    gap: "4px"
  },
  tooltip: {
    paddingLeft: "8px",
    display: "flex",
    paddingTop: "2px"
  },
  advancedInputsContainer: {
    padding: "0px 16px 16px 16px",
    flexWrap: "nowrap",
    overflow: "auto",
    maxHeight: "100%",
    overflowX: "hidden"
  },
  header: {
    padding: "0px 16px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    background: "#d9d9d933"
  },
  collapsableHeader: {
    cursor: "pointer",
    justifyContent: "space-between"
  },
  helperText: {
    margin: "4px 0px"
  }
});

export const AutoMlRecipeCode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const { recipe } = useAutoMLRecipeContext();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const handleEditRecipe = () => {
    navigate("notebook");
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      <Box className={clsx(classes.header, classes.collapsableHeader)} onClick={handleToggle}>
        <Typography variant="subtitle1">Recipe Code</Typography>
        <IconButton size="small">{isExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </Box>

      {isExpanded && (
        <Grid container direction="column" className={classes.advancedInputsContainer}>
          {recipe?.id && !isJobPath && !!get(first(recipe?.runConfigs), "id") && (
            <>
              <Grid container style={{ gap: "10px", alignItems: "center", padding: " 10px 0" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="EditInNotebookButton"
                  size="small"
                  onClick={handleEditRecipe}>
                  Edit in Notebook{recipe.codeChanged && "*"}
                </Button>
                <Tooltip
                  title={
                    "This allows you to modify the underlying code of this Recipe and customize it as per your needs."
                  }>
                  <InfoOutlined fontSize="small" style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </Tooltip>
              </Grid>
              {recipe.codeChanged && (
                <span
                  data-testid="isEditedRecipeCodeMessage"
                  style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }}>
                  * Default Recipe Code is Edited
                </span>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};
