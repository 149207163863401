/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import React, { useState, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { delay, size, trim, upperCase } from "lodash";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  makeStyles,
  withStyles,
  Card,
  CardContent,
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { RunIcon } from "src/icons/NewUX/RunIcon";

import { useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";

import { TrashIcon } from "src/icons/NewUX/TrashIcon";

import { RenderText } from "src/utils";
import {
  NodeTypes,
  NodeTypesIconMapping,
  RecipeTypesNameMapping,
  RecipeTypesPathMapping
} from "src/pages/private/ProjectsModule/utils";

import { runTransformGroupWithRethrow } from "../../../api";
import {
  useDrawerStore,
  useRunConfigStore,
  useCanvasStore,
  useScenariosStore
} from "../../../store/store";
import { sideComponentSetter, getNodes, configGroupSetter } from "../../../store/store.selectors";
import useTransformGroupDetails from "hooks/api/transforms/useTransformGroupDetails";
import useTransformGroup from "hooks/api/transforms/useTransformGroup";
import DeleteNodeModal, {
  psMessage
} from "../../../pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { ToastifyAlert } from "../../ToastifyAlert/ToastifyAlert";
import { dateFormat } from "src/utils/dateFormat";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { TimeoutTextField } from "src/pages/Projects/common/TimeoutTextField/TimeoutTextField";
import { TimeoutIcon } from "src/icons/TimeoutIcon";
import {
  clearRunTransformGroupCache,
  UseGetProjectCanvasQueryKeys,
  useUpdateTransformGroupMutation
} from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import { validateNameField } from "src/utils/formFieldUtils";
import { useStyles } from "./components/styling";
import DrawerAccordion from "./components/DrawerAccordion";
import DrawerAbout from "./components/DrawerAbout";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import Spinner from "components/Spinner";
import { useTransformErrorUtils } from "pages/Projects/common/hooks/useTransformErrorUtils";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { isRecipeRunInQueue as isRecipeRunInQueueHelper } from "src/pages/private/ProjectsModule/utils";

import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import RecipeLogsDrawer from "pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";
import RecipeReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Recipe/RecipeReadonly";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import _ from "lodash";
import { WebPaths } from "src/routing/routes";
import { AddRunIcon, EyeIcon } from "src/icons/NewUX";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import { UseGetRecipeRunsQueueQueryKeys, useAddRecipeToQueue } from "src/hooks/api/recipes";
import { RecipeStatuses } from "src/constants";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import StopCircleOutlined from "src/icons/StopCircleOutlined";
import useStopRecipe from "src/pages/private/ProjectsModule/pages/Dag/hooks/useStopRecipe";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import useUpdateRecipeName from "src/hooks/api/transforms/useUpdateRecipeName";

// const FONT_SIZE = 14;

const FullHeightCard = withStyles({
  root: {
    height: "100%"
  }
})(Card);
const useTimeoutStyles = makeStyles({
  timeoutContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    alignItems: "center"
  }
});

const useNodesListStyles = makeStyles({
  container: { paddingLeft: 0, paddingRight: 0 },
  icon: { minWidth: 30 },
  text: { wordBreak: "break-word" }
});

const TransformGroupModal = ({ data, canAccessRecipeTimeout }: $TSFixMe) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [stopRecipeModalOpen, setStopRecipeModalOpen] = useState(false);

  const [openLogs, setOpenLogs] = useState(false);
  const { timeoutContainer } = useTimeoutStyles();
  const nodesListStyles = useNodesListStyles();
  const classes = useStyles();
  const { fetchAndRenderFullErrorLogs } = useTransformErrorUtils();
  const updateTransformGroupMutation = useUpdateTransformGroupMutation();
  const updateTransformGroupName = useUpdateRecipeName();
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const { itemId: groupId, projectId, scenarioId, jobProps, isJobCanvas } = data;

  // Query hooks - STARTS >>
  // Queries
  const {
    data: transformDetails,
    isLoading,
    refetch: refetchDetails
  } = useTransformGroupDetails(groupId, scenarioId, jobProps?.jobRunId, {
    cacheTime: Infinity,
    refetchOnMount: true
  });

  // Mutations
  const {
    data: configGroup,
    isLoading: isTransformGroupLoading,
    refetch
  } = useTransformGroup(groupId, scenarioId, jobProps?.jobRunId, {
    cacheTime: Infinity,
    refetchOnMount: true
  });

  const {
    isLoading: isAddingRecipeToQueue,
    mutateAsync: addRecipeToQueueMutation,
    reset: resetAddRecipeToQueueMutation
  } = useAddRecipeToQueue();

  const stopRecipe = useStopRecipe();
  // << ENDS - Query hooks

  const [isRunning, setIsRunning] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const setConfigGroup = useRunConfigStore(configGroupSetter);
  const nodes = useCanvasStore(getNodes);
  const setReloadTriggerWithDelay = useCanvasStore((state) => state.setReloadTriggerWithDelay);
  const setIsRunRequestPending = useCanvasStore((state) => state.setIsRunRequestPending);
  const isRecipesRunningAcrossScenariosStore = useCanvasStore(
    (state) => state.isRecipesRunningAcrossScenarios
  );
  const isPendingRecipeRunsInQueueStore = useCanvasStore(
    (state) => state.isPendingRecipeRunsInQueue
  );
  const pendingRecipeRunsInQueueStore = useCanvasStore((state) => state.pendingRecipeRunsInQueue);

  const scenarios = useScenariosStore((state) => state.scenarios);

  const dfsGroupNodes = useMemo(() => {
    return nodes.filter((node: $TSFixMe) => (node as $TSFixMe).type === "DFSGROUP");
  }, [nodes]);

  const currentNode = useMemo(
    () => nodes?.find((node: $TSFixMe) => node.id === groupId),
    [groupId, nodes]
  );

  const goToLogs = () => {
    setOpenLogs(true);
  };

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      const jobUrl = _.replace(
        WebPaths.JobStandardRecipe,
        RecipeTypesPathMapping.STANDARD,
        _.get(RecipeTypesPathMapping, currentNode?.recipeType || RecipeTypesPathMapping.STANDARD)
      ) as typeof WebPaths.JobStandardRecipe;

      return generatePath(`${WebPaths.JobRoutes}${jobUrl}`, {
        projectId,
        jobId: jobProps?.jobId,
        scenarioId,
        jobRunId: jobProps?.jobRunId,
        groupId: groupId
      });
    } else {
      const url = _.replace(
        WebPaths.StandardRecipeDataContainer,
        RecipeTypesPathMapping.STANDARD,
        _.get(RecipeTypesPathMapping, currentNode?.recipeType || RecipeTypesPathMapping.STANDARD)
      );

      return generatePath(url, {
        projectId,
        scenarioId,
        groupId
      });
    }
  };

  const goToViewTransform = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    navigate(getNavigatePath());
  };

  const addRecipeToQueue = async () => {
    await resetAddRecipeToQueueMutation();

    await addRecipeToQueueMutation(
      { scenarioId, id: groupId },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);
          await queryClient.invalidateQueries([UseGetProjectCanvasQueryKeys.ProjectCanvas]);

          toastWrapper({
            type: ToastTypes.Info,
            content: `Recipe ${currentNode?.displayName} added to queue successfully!`
          });
        },
        onError: () =>
          toastWrapper({
            type: ToastTypes.Error,
            content: `Error while adding Recipe ${currentNode?.displayName} to queue!`
          }),
        onSettled: () =>
          setSideComponent({
            sideComponent: null,
            sideComponentProps: null
          })
      }
    );
  };

  const runTransform = async () => {
    if (!scenarioId || !groupId) {
      return;
    }

    toast.dismiss();

    !!projectId && checkEnvRelaunch(projectId);

    if (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore) {
      await addRecipeToQueue();
      return;
    }

    setIsRunning(true);

    const toastId = toastWrapper({
      content: "View the recipe run log",
      actions: [{ action: goToLogs, label: "View Log" }],
      type: ToastTypes.Info
    });

    try {
      setIsRunRequestPending(true);
      setReloadTriggerWithDelay();

      delay(
        () => queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]),
        1000
      );

      await runTransformGroupWithRethrow(groupId, scenarioId, undefined, false);
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
      await clearRunTransformGroupCache(queryClient);
      refetchDetails();
      queryClient.invalidateQueries({
        queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
      });
    } catch (e) {
      refetchDetails();
      toast.dismiss(toastId);
      fetchAndRenderFullErrorLogs(e, { projectId, scenarioId: scenarioId, groupId });
    }
    setIsRunRequestPending(false);
    setIsRunning(false);
  };

  const stopRunningRecipe = () => {
    stopRecipe.mutate(
      { groupId, scenarioId },
      {
        onSuccess: () => {
          toastWrapper({ type: "info", content: "Recipe stop action is completed" });

          setSideComponent({
            sideComponent: null,
            sideComponentProps: null
          });
          setStopRecipeModalOpen(false);
          clearRunTransformGroupCache(queryClient);
          refetchDetails();
          queryClient.invalidateQueries({
            queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
          });
        }
      }
    );
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, scenarioId),
    [scenarios, scenarioId]
  );

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    updateTransformGroupMutation.mutate(
      {
        projectId,
        recipeId: configGroup?.id,
        transformGroup: {
          ...configGroup,
          runConfigs: configGroup?.runConfigs?.length
            ? configGroup?.runConfigs?.map((item) => item.id)
            : [],
          timeout
        }
      },
      {
        onSuccess: (response) => {
          setConfigGroup(response);
          refetch();
          refetchDetails();
          handleResponse({ successMessage: "Recipe Run Timeout updated successfully." });
        }
      }
    );
  };

  const updateTransformName = async (name: string) => {
    const { isValid, error } = validateNameField({
      fieldName: name?.trim(),
      fieldNameLabel: `recipe name`
    });

    const nameExist = dfsGroupNodes.some((item: $TSFixMe) => item.displayName == name);

    if (!isValid && error) {
      handleResponse({ errorMessage: error });
      setShouldResetName(Date.now());
    } else if (nameExist) {
      handleResponse({ errorMessage: "Recipe name already exists" });
      setShouldResetName(Date.now());
    } else {
      try {
        const response = await updateTransformGroupName.mutateAsync({
          displayName: name,
          groupId,
          condition: configGroup?.condition
        });
        if (response && !response.msg) {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);
          await queryClient.invalidateQueries({
            queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
          });

          toastWrapper({
            type: ToastTypes.Success,
            content: "Recipe Name updated successfully."
          });

          refetchDetails();
          setConfigGroup(response);
        } else {
          setShouldResetName(Date.now());
        }
      } catch (error) {
        console.error(error);
        setShouldResetName(Date.now());
      }
    }
  };

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: groupId,
        scenarioId
      }),
    [pendingRecipeRunsInQueueStore, groupId, scenarioId]
  );

  const isRecipeRunDisabled = useMemo(
    () =>
      isRunning ||
      isAddingRecipeToQueue ||
      currentNode?.status === RecipeStatuses.Running ||
      isRecipeRunInQueue ||
      !configGroup?.runConfigs ||
      size(configGroup?.runConfigs) === 0,
    [
      isRunning,
      isAddingRecipeToQueue,
      currentNode?.status,
      isRecipeRunInQueue,
      configGroup?.runConfigs
    ]
  );

  const navigateToDataset = (id: string) => {
    if (!id) {
      return;
    }
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId"])) {
      return;
    }

    let path = generatePath(WebPaths.ViewData, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      entityId: id,
      section: "data"
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: id,
        section: "data"
      });
    }

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    !!path && navigate(path);
  };

  const navigateToArtifact = (name: string) => {
    if (!name) {
      return;
    }

    let path = generatePath(WebPaths.ArtifactsDetails, { artifactName: name });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId"]) ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        artifactName: name
      });
    }

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const navigateToCharts = (id: string) => {
    if (!id) {
      return;
    }
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId"])) {
      return;
    }

    let path = generatePath(WebPaths.Charts, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      chartId: id
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobChart}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        chartId: id
      });
    }

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    !!path && navigate(path);
  };

  const navigateToModel = (name: string) => {
    if (!name) {
      return;
    }

    let path = generatePath(WebPaths.ModelDetails, {
      modelName: name
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId"]) ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: name
      });
    }

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const navigateToDetils = (node: any) => {
    switch (upperCase(trim(node?.type))) {
      case NodeTypes.Artifact:
        navigateToArtifact(node?.name);
        break;
      case NodeTypes.Chart:
        navigateToCharts(node?.id);
        break;
      case NodeTypes.Dataset:
        navigateToDataset(node.id);
        break;
      case NodeTypes.Model:
        navigateToModel(node?.name);
        break;
    }
  };

  const handleCancel = () => {
    setStopRecipeModalOpen(false);
  };

  const isAddRecipeToQueue = useMemo(
    () =>
      currentNode?.status !== RecipeStatuses.Running &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [currentNode?.status, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  const handleDatasetsAccordion = () => setExpanded(!expanded);

  const onClose = () =>
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

  const handleCloseLogs = () => {
    setOpenLogs(false);
  };

  return (
    <NewThemeWrapper>
      <Box className={classes.drawerContent}>
        <DeleteNodeModal
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          deleteNote={
            <div>
              {currentNode?.recipeType === "AUTO_ML" ? (
                <span>Note: Deleting this might impact associated dataapps(if any).</span>
              ) : (
                <span>Note: </span>
              )}
              {psMessage}
            </div>
          }
          nodeId={groupId}
          nodeName={data.displayName}
          nodeType="group"
          onAfterSubmit={() => setSideComponent({ sideComponent: null, sideComponentProps: null })}
        />
        {openLogs && (
          <RecipeLogsDrawer
            isRunRecipeLogs={true}
            isRecipeRunning={currentNode?.status === "RUNNING"}
            name={currentNode?.displayName ?? data?.displayName ?? data?.name}
            open={openLogs}
            projectId={projectId}
            scenarioId={scenarioId}
            jobRunId={jobProps?.jobRunId}
            groupId={groupId}
            isJobPath={!!jobProps}
            onClose={handleCloseLogs}
          />
        )}
        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <RecipeReadonly data={data} />

                {!!data?.isJobCanvas || !isDefaultScenario ? (
                  <RenderText
                    color="textSecondary"
                    isOverflowTooltip
                    linkTo={getNavigatePath()}
                    data-testid="sidebar-recipe-name">
                    {currentNode?.displayName}
                  </RenderText>
                ) : (
                  <EditName
                    key="edit-name"
                    fieldNameLabel="recipe name"
                    inputValue={currentNode?.displayName}
                    onSave={updateTransformName}
                    isResetName={shouldResetName}
                    containerProps={{
                      style: {
                        maxWidth: 200
                        // width: ((currentNode?.displayName || "")?.length + 1) * (FONT_SIZE - 4) + 16
                      }
                    }}
                  />
                )}
              </>
            )
          }}
        />

        <div className={classes.content} style={{ marginTop: 44 }}>
          {isLoading || isTransformGroupLoading ? (
            <Spinner />
          ) : (
            <>
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      container
                      item
                      md={11}
                      xs={10}
                      alignItems="center"
                      className={classes.datasetItemGap}
                      wrap="nowrap">
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        startIcon={<EyeIcon viewBox="0 0 20 20" />}
                        onClick={goToViewTransform}>
                        Preview
                      </Button>
                      {!data?.isJobCanvas && (
                        <Tooltip
                          title={
                            !configGroup?.runConfigs || configGroup?.runConfigs?.length === 0
                              ? "No transformations in Recipe"
                              : currentNode?.status === "RUNNING"
                                ? "Recipe Run is in progress"
                                : isRecipeRunInQueue
                                  ? "Recipe is already in queue!"
                                  : ""
                          }>
                          <span>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={runTransform}
                              disabled={isRecipeRunDisabled}
                              style={{ textTransform: "none" }}
                              startIcon={isAddRecipeToQueue ? <AddRunIcon /> : <RunIcon />}>
                              {isAddRecipeToQueue ? "Add to Queue" : "Run"}
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      {!data?.isJobCanvas && currentNode?.status === "RUNNING" && (
                        <Tooltip title={"Stop Recipe Run"}>
                          <span>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => {
                                setStopRecipeModalOpen(true);
                              }}
                              style={{ textTransform: "none" }}
                              startIcon={
                                stopRecipe.isLoading ? (
                                  <CircularProgress size={18} />
                                ) : (
                                  <StopCircleOutlined />
                                )
                              }>
                              Stop
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      <Button color="primary" onClick={() => goToLogs()}>
                        <LogsNewIconV2
                          width="22"
                          height="22"
                          // New UX change
                          // The static value will be replaced with new theme at platform-level.
                          color={!groupId ? "#4646b550" : "#4646b5"}
                        />
                      </Button>
                    </Grid>
                    {!data?.isJobCanvas && isDefaultScenario && (
                      <Grid container item md={1} xs={2} className="moreIcon">
                        <MoreOptions
                          options={[
                            {
                              label: "Delete",
                              icon: <TrashIcon viewBox="0 0 20 22" />,
                              action: onDelete
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>

              {!isJobCanvas && (
                <div className={classes.cardMargin}>
                  {!isRunning &&
                    !isAddingRecipeToQueue &&
                    currentNode?.status !== "RUNNING" &&
                    size(transformDetails?.results) > 0 &&
                    transformDetails?.results[transformDetails?.results?.length - 1]?.msgList?.map(
                      (item, index: number) => (
                        <ToastifyAlert
                          key={index}
                          type={item?.level?.toLowerCase()}
                          message={item?.msg}
                        />
                      )
                    )}
                </div>
              )}
              {transformDetails?.markdown && (
                <Card className={classes.cardMargin}>
                  <DrawerAbout
                    markdownString={transformDetails?.markdown}
                    projectId={data.projectId}
                    elementId={groupId}
                    hiddenUpdateContentAction={!!isJobCanvas}
                    type="RECIPE"
                    onSuccess={refetchDetails}
                  />
                </Card>
              )}
              <Card className={classes.cardMargin}>
                <CardContent>
                  <div className={classes.detailsWrapper}>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Recipe type:</p>
                      <p>
                        {configGroup?.recipeType
                          ? !!RecipeTypesNameMapping[configGroup?.recipeType]
                            ? `${RecipeTypesNameMapping[configGroup?.recipeType]} Recipe`
                            : "-"
                          : "-"}
                      </p>
                    </div>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Created:</p>
                      <p>{dateFormat(transformDetails?.created)}</p>
                    </div>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Last modification:</p>
                      <p>{dateFormat(transformDetails?.updated)}</p>
                    </div>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Last successful build:</p>
                      <p>{dateFormat(transformDetails?.built)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
              {canAccessRecipeTimeout && (
                <Card className={classes.cardMargin}>
                  <CardContent>
                    <Grid container direction="row" className={timeoutContainer}>
                      <TimeoutIcon />
                      <TimeoutTextField
                        value={configGroup?.timeout!}
                        minValue={1}
                        onChange={onUpdateRecipeRunTimeout}
                        disabled={!!isJobCanvas || updateTransformGroupMutation.isLoading}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              )}
              <div className={classes.cardMargin}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FullHeightCard>
                      <DrawerAccordion
                        expanded={expanded}
                        onChange={handleDatasetsAccordion}
                        title="INPUTS"
                        content={
                          <List dense>
                            {configGroup?.parents?.map((item) => {
                              const node = nodes.find((node: $TSFixMe) => node.id === item.id);
                              return (
                                <ListItem key={uuidv4()} className={nodesListStyles.container}>
                                  <ListItemIcon className={nodesListStyles.icon}>
                                    {!!node?.type &&
                                      NodeTypesIconMapping[upperCase(trim(node?.type))]}
                                  </ListItemIcon>
                                  <Link
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigateToDetils(node)}>
                                    <ListItemText
                                      primary={node?.displayName}
                                      className={nodesListStyles.text}
                                    />
                                  </Link>
                                </ListItem>
                              );
                            })}
                          </List>
                        }
                      />
                    </FullHeightCard>
                  </Grid>
                  <Grid item xs={6}>
                    <FullHeightCard>
                      <DrawerAccordion
                        title="OUTPUTS"
                        expanded={expanded}
                        onChange={handleDatasetsAccordion}
                        content={
                          <List dense>
                            {configGroup?.children?.map((item) => {
                              const node = nodes.find((node: $TSFixMe) => node.id === item.id);
                              return (
                                <ListItem key={uuidv4()} className={nodesListStyles.container}>
                                  <ListItemIcon className={nodesListStyles.icon}>
                                    {!!node?.type &&
                                      NodeTypesIconMapping[upperCase(trim(node?.type))]}
                                  </ListItemIcon>
                                  <Link
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigateToDetils(node)}>
                                    <ListItemText
                                      primary={node?.displayName}
                                      className={nodesListStyles.text}
                                    />
                                  </Link>
                                </ListItem>
                              );
                            })}
                          </List>
                        }
                      />
                    </FullHeightCard>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </div>
      </Box>
      {!!stopRecipeModalOpen && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title={
            <Grid direction="column" container>
              <Typography>Stop Recipe Run</Typography>
              <span style={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                This process may take some time to get completed.
              </span>
            </Grid>
          }
          content={[
            "This action updates the recipe status to Unbuilt, shuts down the related project environment, and restarts it. This may cause any other recipes depending on this environment to fail.",
            <>
              <b>Note:</b> If recipe execution gets completed before associated environment restart
              then the status of the recipe will be based on the run result.
            </>,
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          isSubmitting={stopRecipe.isLoading}
          onSubmit={stopRunningRecipe}
        />
      )}
    </NewThemeWrapper>
  );
};

export default TransformGroupModal;
