import React, { useState } from "react";

// Packages
import { Header } from "@tanstack/react-table";
import clsx from "clsx";

// MUI
import Grid from "@material-ui/core/Grid";
import MuiTableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

// Icons
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

// Components
import { OverflowTooltip } from "src/components";
import MoreOptions from "./MoreOptions";
import HeaderName from "./HeaderName";

// Contexts
import { useTableContext } from "../../context/useTableContext";

// Types
import { TData } from "../../Table.types";

// Styles
import useStyles from "./TableCell.styles";

type Props = {
  header: Header<TData, string>;
};

const TableCell = (props: Props) => {
  const { header } = props || {};

  const classes = useStyles();

  const { resizeColumns } = useTableContext();

  const [anchorMoreOptionsEl, setMoreOptionsAnchorEl] = useState<SVGElement | null>(null);

  const onMoreOptionsClick = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    setMoreOptionsAnchorEl(() => event?.currentTarget);
  };

  return (
    <>
      {Boolean(anchorMoreOptionsEl) && (
        <MoreOptions
          anchorMoreOptionsEl={anchorMoreOptionsEl}
          setMoreOptionsAnchorEl={setMoreOptionsAnchorEl}
          column={header?.column}
        />
      )}

      <MuiTableCell className={classes.root} style={{ width: header?.getSize() }}>
        <Grid container>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            className={clsx("headerContainer", {
              sortable: header?.column?.getCanSort()
            })}>
            <HeaderName header={header} />
            <MoreVertRoundedIcon
              className="preventHeaderClick"
              fontSize="small"
              onClick={onMoreOptionsClick}
            />
          </Grid>
          <Grid container className="schemaContainer">
            <OverflowTooltip
              style={{ flexGrow: 1, maxWidth: 250, whiteSpace: "nowrap" }}
              value={header?.column?.columnDef?.meta}
            />
          </Grid>
        </Grid>
        <Box
          {...{
            onDoubleClick: () => resizeColumns(),
            onMouseDown: header?.getResizeHandler(),
            onTouchStart: header?.getResizeHandler(),
            className: `reSizer ${header?.column?.getIsResizing() ? "isResizing" : ""}`
          }}
        />
      </MuiTableCell>
    </>
  );
};

export default TableCell;
