import React, { useEffect, useState, useMemo, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Paper,
  Chip,
  makeStyles,
  Button,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { castArray, compact, filter, get, isArray, isEmpty } from "lodash";

import { EnvironmentStatuses } from "src/constants/environments.constants";
import envRelaunchNotification, {
  checkEnvRelaunch,
  envRelaunchMessage
} from "../../../utils/envRelaunchNotification";
import Spinner from "../../../components/Spinner";
import { ToastifyAlert } from "../../../components/ToastifyAlert/ToastifyAlert";
import { RecipeStatuses } from "src/constants";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import useTransformGroup from "src/hooks/api/transforms/useTransformGroup";
import { IS_TEST_LOGS } from "../common/ShowLogsModal/RecipeLogsDrawer";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import api from "src/utils/AxiosClient";
import { pollingTimeForTestLogs } from "./useGetRecipeRunLogs";

export const useStyles = makeStyles({
  logsContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  }
});
const RecipeRunLogs = () => {
  const { scenarioId, groupId, projectId } = useParams<$TSFixMe>();
  const [searchParams] = useSearchParams();

  const isTestLogs = searchParams.get(IS_TEST_LOGS) === "true";

  const classes = useStyles();

  // Project context
  const { project } = useProjectContext() || {};

  const interval = 2000;

  const [isFetching, setIsFetching] = useState(false);
  const [isEnvRelaunched, setIsEnvRelaunched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recipeName, setRecipeName] = useState("");
  const [envStatus, setEnvStatus] = useState("");
  const [recipeStatus, setRecipeStatus] = useState<$TSFixMe>("");
  const [recipeLogs, setRecipeLogs] = useState([]);
  const count = useRef(0);
  const [show, setShow] = useState(false);
  const recipeRunLogsInterval = useRef<$TSFixMe>(null);

  const { data: recipe } = useTransformGroup(groupId, scenarioId, "", {
    onSuccess: (data: $TSFixMe) => (isArray(data) ? data[0] : {})
  });

  useEffect(() => {
    if (projectId) {
      checkEnvRelaunch(projectId);
    }
  }, [projectId]);

  const stopWatching = (timeoutId: $TSFixMe) => {
    timeoutId && clearTimeout(timeoutId);
    recipeRunLogsInterval.current && clearTimeout(recipeRunLogsInterval.current);
  };

  const getRecipeLogs = async ({ timeoutId }: $TSFixMe) => {
    if (groupId && scenarioId) {
      try {
        setIsLoading(true);
        const response: any = await api.fetchResponse(
          async () =>
            await api.DfsRunConfigGroupControllerV2Api.getRunTransformProgress(
              groupId,
              scenarioId,
              undefined,
              undefined,
              undefined,
              10000,
              undefined,
              undefined,
              !!isTestLogs
            )
        );

        // Environment Status code starts >>
        let thisIsEnvRelaunched: boolean = false;
        const thisEnvStatus = response?.envStatus;

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!thisEnvStatus) {
          if (
            thisEnvStatus?.trim()?.toLowerCase() === EnvironmentStatuses.Launching.toLowerCase()
          ) {
            thisIsEnvRelaunched = true;
            setIsEnvRelaunched(true);

            if (thisEnvStatus !== envStatus) {
              const envResponse = await api.fetchResponse(
                async () => await api.EnvControllerApi.findEnvById(response?.envId)
              );
              const shutdownTimeInHrs = envResponse?.[0].shutdownStrategy?.inactivityInHours;
              envRelaunchNotification(shutdownTimeInHrs ?? 0);
            }
          }
        }

        // Must be after the above if condition, as it's previous value being used in the if condition.
        setEnvStatus(response?.envStatus);
        // << Environment Status code ends

        // Recipe Log code starts >>
        !isFetching && setIsFetching(true);

        !recipeName && setRecipeName(response?.displayName);

        if (
          recipeLogs?.length === 0 ||
          (recipeLogs?.length > 0 && response?.status === RecipeStatuses.Running) ||
          // The below condition is to set logs of first succeed status preceded by (after) a running status.
          (recipeLogs?.length > 0 &&
            recipeStatus === RecipeStatuses.Running &&
            response?.status !== RecipeStatuses.Running)
        ) {
          const dbValidationLogs = isTestLogs
            ? []
            : compact(
                castArray(
                  get(
                    filter(
                      response?.results,
                      ({ status, type }) => status === "FAILURE" && type === "RECIPE"
                    ),
                    ["0", "msgList", "0", "msg"]
                  ) ?? ""
                )
              );
          const thisRecipeLogs: $TSFixMe = [
            ...(thisIsEnvRelaunched || isEnvRelaunched ? [envRelaunchMessage] : []),
            ...(response?.status === RecipeStatuses.Running
              ? response?.transformLogs?.[0]?.logs
              : response?.transformLogs?.reduce(
                  (acc: string[], item: $TSFixMe) =>
                    item?.logs?.length > 0
                      ? [...acc, `>>>>> ${item?.name} <<<<<`, ...item?.logs]
                      : acc,
                  []
                ) ?? []),
            ...dbValidationLogs
          ];

          setRecipeLogs(thisRecipeLogs);
        }
        setIsLoading(false);

        // Must be after the above if condition, as it's previous value being used in the if condition.
        setRecipeStatus(() => response?.status);
        // << Recipe Log code ends
      } catch (e) {
        stopWatching(timeoutId);
        setIsLoading(false);
      }
    }
  };

  const isMounted = useRef(true);

  useEffect(() => {
    let timeout = 0;
    let timeoutId: $TSFixMe = null;
    recipeRunLogsInterval.current && clearTimeout(recipeRunLogsInterval.current);

    const _ = () => {
      // Call getRecipeLogs every 2 secs.
      timeoutId = setTimeout(async () => {
        if (!isMounted.current) {
          return;
        }
        recipeRunLogsInterval.current = timeoutId;

        const beforeTimeInMillis = Date.now();
        await getRecipeLogs({ timeoutId });
        const afterTimeInMillis = Date.now();

        count.current = count.current + 1;

        if (recipeStatus === RecipeStatuses.Running || isTestLogs) {
          if (isTestLogs && count.current > (pollingTimeForTestLogs * 1000) / interval) {
            stopWatching(timeoutId);
            setShow(true);
            return;
          }
          const timeoutLeft = interval - (afterTimeInMillis - beforeTimeInMillis);
          timeout = timeoutLeft <= 0 ? 0 : timeoutLeft;

          _();
        } else {
          stopWatching(timeoutId);
        }
      }, timeout);
    };

    groupId && scenarioId && ["", RecipeStatuses.Running].includes(recipeStatus) && _();
  }, [groupId, scenarioId, recipeStatus, isTestLogs]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      recipeRunLogsInterval.current && clearTimeout(recipeRunLogsInterval.current);
    };
  }, []);

  const getStatus = useMemo(() => {
    // @FIXME: The color codes below should be constants & centralized.
    let style = {
      marginLeft: 10,
      backgroundColor: "#ecf5ff",
      borderColor: "#0844cf",
      color: "#0844cf"
    };

    switch (recipeStatus) {
      case RecipeStatuses.Success:
        style = {
          ...style,
          backgroundColor: "#ddfbe5",
          borderColor: "#1fa045",
          color: "#1fa045"
        };
        break;
      case RecipeStatuses.Error:
        style = {
          ...style,
          backgroundColor: "#fff2f1",
          borderColor: "#f74c54",
          color: "#f74c54"
        };
        break;
    }

    if (isTestLogs) {
      return null;
    }

    return <Chip style={style} label={<Typography variant="caption">{recipeStatus}</Typography>} />;
  }, [recipeStatus, isTestLogs]);

  const renderInfo = ({ message, type, size = 12 }: $TSFixMe) => {
    return (
      <Grid
        container
        item
        xs={size}
        style={{
          marginTop: 25,
          display: "inline-block"
        }}>
        <ToastifyAlert type={type} message={message} />
      </Grid>
    );
  };

  const recipeRunInfo =
    recipeStatus === RecipeStatuses.Success
      ? {
          type: "success",
          message: !isTestLogs ? "Recipe run successful" : "Recipe test successful"
        }
      : recipeStatus === RecipeStatuses.Running
        ? { type: "info", message: "Recipe run in progress..." }
        : recipeStatus === RecipeStatuses.UnBuilt
          ? {
              type: "info",
              message: "Logs are not available because the recipe hasn't been executed yet."
            }
          : { type: "error", message: "No logs found!" };

  return (
    <Grid container justifyContent="center">
      {!groupId ? (
        renderInfo({ message: "Invalid inputs.", type: "warning", size: 8 })
      ) : !isFetching ? (
        <Spinner size={24} />
      ) : (
        <>
          <SubTopNavBarWrapper
            subTopNavBarLeftSection={{
              component: (
                <SubTopNavBarBreadcrumbs
                  project={project}
                  scenarioId={scenarioId}
                  recipe={recipe}
                />
              )
            }}
            subTopNavBarRightSection={{
              component: (
                <>
                  {getStatus}{" "}
                  {show && (
                    <Tooltip title="Retrieves the most recent logs">
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress size={16} /> : undefined}
                        onClick={() => getRecipeLogs({ shouldShowFullLogs: true })}>
                        Refresh
                      </Button>
                    </Tooltip>
                  )}
                </>
              )
            }}
          />

          <Grid container className={classes.logsContainer}>
            {recipeLogs && recipeLogs?.length !== 0 ? (
              <Grid container item xs={12}>
                <Paper
                  style={{
                    width: "100%",
                    padding: "20px 25px",
                    backgroundColor: "#1e1e1e",
                    fontFamily: `Menlo, Monaco, "Courier New", monospace`,
                    color: "#d4d4d4",
                    fontSize: 12,
                    overflow: "auto"
                  }}>
                  <ul style={{ listStyle: "none" }}>
                    {recipeLogs.map((log, index) => {
                      return <li key={`log_${index}`}>{log}</li>;
                    })}
                  </ul>
                </Paper>
              </Grid>
            ) : isEmpty(recipeLogs) && isTestLogs ? (
              renderInfo({ type: "info", message: "No logs found!" })
            ) : (
              renderInfo(recipeRunInfo)
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RecipeRunLogs;
