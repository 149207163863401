import { useCallback } from "react";
import useAuthStore from "src/stores/auth.store";
import shallow from "zustand/shallow";

import { Roles } from "src/types/Role.type";

export const useAuthSetAuthResponse = () => {
  const [
    logoutTimer,
    isTokenPermanent,
    setIsUserLoggedIn,
    setToken,
    setIsTokenPermanent,
    setExpiresAt,
    setTenantId,
    setTenantIdPermanent,
    setUserId,
    setTokenPermanent,
    setTenantNameUserEmail,
    setNotebookType,
    setCpuGuarantee,
    setMemGuarantee,
    setRoleName,
    setPrivileges,
    setDataappsTtlInHrs
  ] = useAuthStore(
    useCallback(
      (state) => [
        state.logoutTimer,
        state.isTokenPermanent,
        state.setIsUserLoggedIn,
        state.setToken,
        state.setIsTokenPermanent,
        state.setExpiresAt,
        state.setTenantId,
        state.setTenantIdPermanent,
        state.setUserId,
        state.setTokenPermanent,
        state.setTenantNameUserEmail,
        state.setNotebookType,
        state.setCpuGuarantee,
        state.setMemGuarantee,
        state.setRoleName,
        state.setPrivileges,
        state.setDataappsTtlInHrs
      ],
      []
    ),
    shallow
  );
  const setAuthResponse = ({
    data,
    rememberMe = false
  }: {
    data: $TSFixMe;
    rememberMe?: boolean;
  }) => {
    const {
      token,
      userId,
      userEmail,
      tenantId,
      tenantName,
      expiresAt,
      notebookType,
      cpuGuarantee,
      memGuarantee,
      roleName,
      dataappsTtlInHrs
    } = data;

    setRoleName(roleName);
    setPrivileges([Roles.Admin.name, Roles.Demo.name]?.includes(roleName));
    setToken(token);
    setUserId(userId);
    setTenantId(tenantId);
    setTenantIdPermanent(tenantId);
    setTenantNameUserEmail(tenantName, userEmail);
    setNotebookType(notebookType);
    setExpiresAt(expiresAt, logoutTimer);

    setCpuGuarantee(cpuGuarantee);
    setMemGuarantee(memGuarantee);
    setDataappsTtlInHrs(dataappsTtlInHrs);

    if (rememberMe || isTokenPermanent) {
      setIsTokenPermanent(true);
      setTokenPermanent(token);
    } else {
      setIsTokenPermanent(false);
      localStorage.removeItem("token");
    }

    setIsUserLoggedIn(true);
  };
  return { setAuthResponse };
};
