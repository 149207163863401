/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProjectDashboardDto
 */
export interface ProjectDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDashboardDto
     */
    'industries'?: Array<ProjectDashboardDtoIndustriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDashboardDto
     */
    'useCases'?: Array<ProjectDashboardDtoUseCasesEnum>;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'recipeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'dataSetCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'scenarioCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'viewCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'artifactsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'modelsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'jobCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'predictionJobCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'dataAppCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'envId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'canvasEdgeStyle'?: ProjectDashboardDtoCanvasEdgeStyleEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     * @deprecated
     */
    'createAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     * @deprecated
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'updater'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDashboardDto
     */
    'hasMarkdown'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'singleEntityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardDto
     */
    'defaultScenarioId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDashboardDto
     */
    'isFavorite'?: boolean;
}

export const ProjectDashboardDtoIndustriesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type ProjectDashboardDtoIndustriesEnum = typeof ProjectDashboardDtoIndustriesEnum[keyof typeof ProjectDashboardDtoIndustriesEnum];
export const ProjectDashboardDtoUseCasesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type ProjectDashboardDtoUseCasesEnum = typeof ProjectDashboardDtoUseCasesEnum[keyof typeof ProjectDashboardDtoUseCasesEnum];
export const ProjectDashboardDtoCanvasEdgeStyleEnum = {
    Curved: 'CURVED',
    Squared: 'SQUARED'
} as const;

export type ProjectDashboardDtoCanvasEdgeStyleEnum = typeof ProjectDashboardDtoCanvasEdgeStyleEnum[keyof typeof ProjectDashboardDtoCanvasEdgeStyleEnum];


