import React, { useEffect, useMemo, useCallback, useRef } from "react";

// Packages
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import shallow from "zustand/shallow";
import { includes, isNil, slice, toLower } from "lodash";

// Configs
import { disableSentry, SentryConfig } from "src/app-configs/sentry.config";

// Utils
import Analytics from "src/utils/analytics";
import useLogout from "src/utils/useLogout";
import useSwitchTenant from "src/utils/useSwitchTenant";
import { loginPath, WebPaths } from "src/routing/routes";

// Hooks
import { useGetRole } from "src/hooks/useGetRole";
import useRefreshToken from "./useRefreshToken";

// Stores
import useAuthStore from "src/stores/auth.store";
import useTenantsStore from "src/stores/tenant-management.store";
import { useDrawerStore, useProjectsStore } from "src/store/store";
import useNotificationStore from "src/stores/notification.store";
import {
  sideComponentSetter,
  projectsSetter,
  shouldRefreshProjectsGetter
} from "src/store/store.selectors";

// React query hooks
import {
  useGetDataConnectors,
  UseGetDataConnectorsQueryKeys,
  useGetProjects,
  IUserDetails,
  useGetUserDetails,
  IRole,
  useGetRoles
} from "src/hooks/api";

// Components
import { toastWrapper } from "src/utils/toastWrapper";

// Contexts
import { PrivateRouteContext } from "./PrivateRouteContext";

// Constants
import { PathRexExps } from "src/constants";

// Types
import { Roles } from "src/types";

type Props = {
  children: React.ReactNode;
};

const PrivateRouteContextProvider = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // History-stack - STARTS >>
  // There are some pages like Dag, where previous-path need to be tracked.
  // The support from either react-router-dom or window object is limited here. Hence, a customized approach.
  const historyStack = useRef<string[]>([]);
  useEffect(() => {
    // Keeping track of last 2 paths only
    historyStack.current = slice([...(historyStack?.current || []), location?.pathname], -2);
  }, [location?.pathname]);
  // << ENDS - History-stack

  // Watches for logout occurred in another tab, and logs the current tab out.
  const { logout } = useLogout();

  // Watches for tenant switch occurred in another tab, and switches tenant in the current tab.
  useSwitchTenant();

  const { checkIsRoleYieldsDataAppView } = useGetRole();

  // Stores - STARTS >>
  const [
    expiresAt,
    userId,
    userEmail,
    tenantId,
    roleName,
    setLicenseEnabled,
    setIsNotebookEnabled,
    setAskAiEnabled,
    isUserLoggedIn,
    setRcClientVersion,
    setRcReleaseVersion,
    setIsSsoEnabled,
    token
  ] = useAuthStore(
    useCallback(
      (state) => [
        state.expiresAt,
        state.userId,
        state.userEmail,
        state.tenantId,
        state.roleName,
        state.setLicenseEnabled,
        state.setIsNotebookEnabled,
        state.setAskAiEnabled,
        state.isUserLoggedIn,
        state.setRcClientVersion,
        state.setRcReleaseVersion,
        state.setIsSsoEnabled,
        state.token
      ],
      []
    ),
    shallow
  );

  const [setUserTenants, setRoles, shouldUserTenantsRefetch] = useTenantsStore(
    (state) => [state.setUserTenants, state.setRoles, state.shouldUserTenantsRefetch],
    shallow
  );

  // $FixMe: Scope to be refactored.
  const [notifications, clearDashboardNotifications] = useNotificationStore(
    (state) => [state.notifications, state.clearDashboardNotifications],
    shallow
  );

  useEffect(() => {
    if (notifications.length !== 0) {
      notifications.map((notification: $TSFixMe) => {
        notification.type === "Dashboard" &&
          toastWrapper({
            type: "success",
            content: notification?.message
          });
      });
      clearDashboardNotifications();
    }
  }, [notifications]);
  const setProjectsStore = useProjectsStore(projectsSetter);
  const refreshProjectsStore = useProjectsStore(shouldRefreshProjectsGetter);

  // $ToBeRemoved: The Drawer component will be removed in future.
  const setSideComponentStore = useDrawerStore(sideComponentSetter);
  // << ENDS - Stores

  // Query hooks - STARTS >>
  // Defining useQuery hook for data-connectors, which can further be called across the application when required.
  useGetDataConnectors();

  const { refetch: refetchGetRoles } = useGetRoles({
    onSuccess: (data: IRole[] | any) => setRoles(data),
    onError: () => setRoles([])
  });

  const getUserRoles = async (userRole?: string) => {
    if (!userRole) {
      return;
    }

    if (
      includes([Roles.Admin.name, Roles.Demo.name], userRole) ||
      userRole === Roles["Business User"].name
    ) {
      await refetchGetRoles();
    }
  };

  useGetUserDetails({
    onSuccess: (data: IUserDetails | any) => {
      // $FixMe: Scope to be refactored.
      // Storing details in multiple store-instances should be streamlined.
      setUserTenants(data?.tenants); // tenants-store
      setLicenseEnabled(data?.licenseEnabled); // auth-store
      setIsSsoEnabled(data?.usageSettings?.userSsoEnabled); // auth-store
      setIsNotebookEnabled(!!data?.noteBooksEnabled); // auth-store
      setAskAiEnabled(!!data?.usageSettings?.featureAskAIEnabled); // auth-store
      setRcClientVersion(data?.usageSettings?.rcClientVersion);
      setRcReleaseVersion(data?.usageSettings?.rcReleaseVersion);

      if (includes([false, "false"], data?.usageSettings?.monitoringSentryEnabled)) {
        disableSentry();
      }

      getUserRoles(data?.userRole);
    },
    enabled: !!token
  });

  useEffect(() => {
    if (!isNil(userEmail)) {
      if (includes(toLower(userEmail), toLower(SentryConfig.ForbiddenUserNameKeyword))) {
        disableSentry();
      }
    }
  }, [userEmail]);

  // Using this instance of useGetUserDetails, for just resetting tenants upon shouldUserTenantsRefetch flag change.
  // Ex: Upon tenant-details updated.
  useGetUserDetails({
    onSuccess: (data: IUserDetails | any) => {
      setUserTenants(data?.tenants); // tenants-store
    },
    onError: () => setUserTenants([]),
    enabled: !!shouldUserTenantsRefetch || !!token
  });

  const {
    data,
    isLoading: isFetchingProjects,
    refetch: refetchProjects
  } = useGetProjects({
    enabled: !!token
  });

  useEffect(() => {
    if (data) {
      setProjectsStore(data);
    }
  }, [data]);

  useEffect(() => {
    if (!!isUserLoggedIn) {
      const isDashboardPath = location.pathname === WebPaths.Dashboard;
      const isProjectPath = location.pathname === WebPaths.Projects;
      (isDashboardPath || isProjectPath || !!refreshProjectsStore) && refetchProjects();
    }
  }, [isUserLoggedIn, location.pathname, refreshProjectsStore]);
  // << ENDS - Query hooks

  // Hooks - STARTS >>
  useRefreshToken({ isUserLoggedIn, logout });
  // << ENDS - Hooks

  // Initiating Analytics
  useEffect(() => {
    !!tenantId && !!userId && Analytics.set({ userId, tenantId });
  }, [tenantId, userId]);

  // $ToBeRemoved: The Drawer component will be removed in future.
  useEffect(() => {
    setSideComponentStore({
      sideComponent: null,
      sideComponentProps: null
    });
  }, [location]);

  const isNavBars = useMemo(() => {
    return (
      isUserLoggedIn &&
      !PathRexExps.auth.test(location.pathname) &&
      !PathRexExps.tenant.test(location.pathname) &&
      !PathRexExps.dataAppDeepLink.test(location.pathname)
    );
  }, [isUserLoggedIn, location.pathname]);

  useEffect(() => {
    const checkToken = () => {
      if (expiresAt) {
        if (!isNaN(expiresAt) && expiresAt * 1000 <= Date.now()) {
          navigate(
            {
              pathname: loginPath
            },
            { state: { from: location } }
          );
        }
      }
    };

    window.addEventListener("click", checkToken);

    return () => {
      window.removeEventListener("click", checkToken);
    };
  }, [expiresAt]);

  useEffect(() => {
    if (!!roleName) {
      // Get third-party connectors for roles that do not yield data-app view role.
      if (!checkIsRoleYieldsDataAppView(roleName)) {
        const dataConnectorsData = queryClient.getQueryData([
          UseGetDataConnectorsQueryKeys.Connectors
        ]);

        if (!dataConnectorsData) {
          queryClient.prefetchQuery({ queryKey: [UseGetDataConnectorsQueryKeys.Connectors] });
        }
      }
    }
  }, [roleName]);

  // PrivateRoute context value - STARTS >>
  const value = useMemo(
    () => ({ historyStack, isNavBars, isFetchingProjects }),
    [historyStack, isNavBars, isFetchingProjects]
  );
  // << ENDS - PrivateRoute context value

  return <PrivateRouteContext.Provider value={value}>{children}</PrivateRouteContext.Provider>;
};

export default PrivateRouteContextProvider;
