import React, { useState } from "react";
import _ from "lodash";
import { Tooltip, Box, Typography, withStyles } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";

// Icons
import { Edit } from "@material-ui/icons";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Delete } from "src/icons/Delete";

import {
  environmentsTypes as environmentsTypesConstant,
  EnvironmentStatuses
} from "src/constants/environments.constants";
import envIcon from "src/assets/images/environment-medium.svg";
import { MoreOptions } from "src/components/MoreOptions/MoreOptions";
import { Environment } from "../../Environments";
import { StatusBar } from "../StatusBar/StatusBar";
import styles from "./EnvironmentCards.module.scss";
import Text from "src/components/Widget/Text";
import infoCardsStyles from "src/components/InfoCards/InfoCards.module.scss";
import { OverflowTooltip } from "src/components";
import { dateFormat } from "src/utils/dateFormat";
import { environmentDetails } from "../../utils/environments.helpers";
import { EnvironmentsHelperText } from "../../utils/Environments.constants";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px rgba(0,0,0,0.14), 0px 1px 5px rgba(0,0,0,0.12)"
  }
}))(Tooltip);

export const NO_PROJECTS = "No projects";

interface IProps {
  env: Environment;
  projects: "No projects" | React.JSX.Element[];
  stoppingEnvironments: any;
  environmentsTypes: any[];
  onDeleteEnv: (env: Environment) => void;
  onEditEnv: (id: string) => void;
  onRelaunchEnv: (env: Environment) => void;
  onLogsClick: (env: Environment) => void;
  onUsage: (env: Environment) => void;
  onStopEnv: (env: Environment) => void;
}

const EnvironmentCard: React.FC<IProps> = (props) => {
  const {
    env,
    environmentsTypes,
    projects,
    onStopEnv,
    onEditEnv,
    stoppingEnvironments,
    onRelaunchEnv,
    onDeleteEnv,
    onUsage,
    onLogsClick
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const isLaunching = (env: Environment) => env?.launchStatus === EnvironmentStatuses.Launching;

  const cardOptions = [
    {
      label: "Edit",
      icon: (
        <Typography color="textSecondary">
          <Edit />
        </Typography>
      ),
      click: () => onEditEnv(env?.id)
    },
    {
      label: "Configs",
      icon: <ConfigIcon />,
      click: () => onUsage(env)
    },
    {
      label: "Logs",
      icon: <LogsNewIconV2 fill="#003656" width="24" height="24" />,
      click: () => onLogsClick(env)
    },
    {
      label: "Delete",
      icon: (
        <Typography color="textSecondary">
          <Delete />
        </Typography>
      ),
      click: () => onDeleteEnv(env),
      disabled: isLaunching(env),
      tooltip: isLaunching(env) ? EnvironmentsHelperText.DeleteInfo : ""
    }
  ];

  return (
    <div
      className={styles.card}
      key={env.id}
      style={{
        cursor: isHovered ? "pointer" : undefined,
        background: isHovered ? "#F4F4FF" : undefined,
        border: isHovered ? "1px solid #4646B5" : undefined
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate(`/environments/${env.id}`)}>
      <div className={styles.cardContent}>
        <div className={styles.cardHeader}>
          <Box width="100%" display="flex" className={styles.cardTitleWrap}>
            <img alt="environment-icon" src={envIcon} />
            <Link
              className={styles.cardTitleLink}
              to={`/environments/${env.id}`}
              onClick={(e) => e.stopPropagation()}>
              <Typography variant="h6" color="textPrimary" className={styles.cardTitleText}>
                <OverflowTooltip
                  tooltipProps={{
                    placement: "bottom-start",
                    PopperProps: { style: { marginTop: -12 } }
                  }}
                  value={
                    <Text
                      value={env.name}
                      dataTestId="environmentTitle"
                      style={{ whiteSpace: "pre" }}
                    />
                  }
                />
              </Typography>
            </Link>
          </Box>

          <div className={styles.moreOption}>
            <MoreOptions options={cardOptions} />
          </div>
        </div>
        <div className={styles.cardDescription}>
          <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={env.description} />
        </div>
        <div className={styles.cardItem}>
          <div>Environment Type</div>
          <div>
            {environmentsTypesConstant[environmentDetails({ environmentsTypes, env })?.name || ""]}
          </div>
        </div>
        <div className={styles.cardItem}>
          <div>Projects</div>
          {projects === NO_PROJECTS ? (
            NO_PROJECTS
          ) : (
            <LightTooltip
              placement="bottom-start"
              interactive
              PopperProps={{ style: { marginTop: -12 } }}
              title={projects}>
              <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {projects}
              </Box>
            </LightTooltip>
          )}
        </div>
        <div className={styles.cardItem}>
          <div>Updated on</div>
          <div>{dateFormat(env.updated)}</div>
        </div>
        {env?.updater && (
          <div className={infoCardsStyles.text}>
            <div className={infoCardsStyles.userMenuItemIndicator}>
              {env?.updater?.charAt(0)?.toUpperCase()}
            </div>
            <OverflowTooltip
              style={{ whiteSpace: "nowrap" }}
              value={
                <Typography color="textSecondary" variant="caption">
                  {env?.updater}
                </Typography>
              }
            />
          </div>
        )}
      </div>
      <StatusBar
        env={env}
        onRelaunchEnv={onRelaunchEnv}
        stoppingEnvironments={stoppingEnvironments}
        onStopEnv={onStopEnv}
      />
    </div>
  );
};

export default EnvironmentCard;
