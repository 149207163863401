import React, { useMemo } from "react";
import _, { capitalize, find, isEmpty, join, map, toNumber } from "lodash";
import { Grid, Typography, makeStyles, Card } from "@material-ui/core";
import { Link } from "react-router-dom";

import { dateFormat } from "src/utils/dateFormat";
import { MoreOptions, OverflowTooltip } from "src/components";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import {
  getJobRunStatusBadge,
  getJobStatusTemplate
} from "../private/ProjectsModule/pages/Jobs/utils/Jobs.helpers";
import { useJobsListHelper } from "./useJobsListHelper";
import {
  JobDeletePromptDetails,
  jobFrequency,
  JobRunStatuses
} from "../private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import { JobIcon } from "src/icons/NewUX/JobIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import {
  lastRunEntryId,
  openLogsModal
} from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { RecipeTypesPathMapping } from "../private/ProjectsModule/utils/Projects.constants";
import { CardTitle } from "src/components/Card/CardTitle";

const useStyles = makeStyles({
  gridItem: {
    width: "80%"
  },
  jobIcon: {
    marginRight: "12px"
  },
  container: {
    fontSize: "0.875rem",
    fontWeight: 400
  },
  containerLight: {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.54)"
  },
  card: {
    background: ({ isHovered }: any) => (isHovered ? "#F4F4FF" : "#fff"),
    position: "relative",
    width: "100%",
    padding: "8px 16px",
    boxShadow: "none"
  },
  moreOptions: {
    position: "absolute",
    background: "#fff",
    top: "16px",
    right: "32px",
    width: "24px",
    height: "30px",
    borderRadius: "4px",
    zIndex: 1
  }
});

interface IFailedRecipeObj {
  failedRecipeId?: string;
  recipePath?: string;
}

export const JobsList = ({ data }: any) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const classes = useStyles({ isHovered });

  const {
    dto: { id: jobId, projectId, name: jobName, status },
    lastRunEntry: { created, updated },
    lastRunEntries
  } = data;
  const {
    projectName,
    cardOptions,
    cancelDeleteJob,
    confirmDeleteJob,
    showConfirmScreen,
    isDeleting
  } = useJobsListHelper({ data });

  const jobFrequencyTemplate = useMemo(() => {
    const { days, frequency, hr, min } = data?.dto?.scheduleInfo?.cronConfig || {};

    const frequencyName = find(jobFrequency, { id: frequency })?.displayName || "";

    const hours = toNumber(hr);
    const minutes = toNumber(min);

    const timeString = `${hr} Hr${hours === 1 ? "" : "s"} ${min} Min${minutes === 1 ? "" : "s"}`;

    switch (frequency) {
      case "daily":
        return `${frequencyName || capitalize(frequency)} at ${timeString} UTC`;
      case "weekly":
        const dayString = !isEmpty(days) ? `on ${join(map(days, capitalize), ", ")}` : "";
        return `${frequencyName || capitalize(frequency)} ${dayString} at ${timeString} UTC`;
      default:
        if (!!frequencyName && !!data?.dto?.schedule) {
          return `${frequencyName}: ${data?.dto?.schedule} UTC`;
        } else if (!!frequencyName && !data?.dto?.schedule) {
          return `${frequencyName}: -`;
        } else if (!frequencyName && !!data?.dto?.schedule) {
          return `${find(jobFrequency, { id: "cron" })?.displayName}: ${data?.dto?.schedule} UTC`;
        } else {
          return "-";
        }
    }
  }, [data?.dto?.schedule, data?.dto?.scheduleInfo?.cronConfig]);

  const navigateTo = (entry: any) => {
    switch (entry?.status) {
      case JobRunStatuses.Failure:
      case JobRunStatuses.RecipeTimedOut: {
        const failedRecipeObject: IFailedRecipeObj = {};

        _.forEach(entry?.recipeRunInfo, (val, key) => {
          if (val?.status === "FAILURE") {
            const type = _.get(entry, ["allRecipeDetails", key, "recipeType"]);
            const recipePath = type ? _.get(RecipeTypesPathMapping, type) : "";
            _.setWith(
              failedRecipeObject,
              "failedRecipeId",
              _.get(entry?.recipeNameToId, key),
              Object
            );
            _.setWith(failedRecipeObject, "recipePath", recipePath, Object);
          }
        });

        if (failedRecipeObject?.recipePath && failedRecipeObject?.failedRecipeId) {
          return `/projects/${projectId}/jobs/${jobId}/scenario/${entry?.scenarioId}/job-runs/${entry?.id}/recipes/${failedRecipeObject.recipePath}/${failedRecipeObject.failedRecipeId}?${openLogsModal}=true`;
        }
        return `/projects/${projectId}/jobs/${jobId}/job-runs/${entry?.id}/job-canvas`;
      }
      case JobRunStatuses.Running:
      case JobRunStatuses.TimedOut: {
        return `/projects/${projectId}/jobs/${jobId}/job-runs?${lastRunEntryId}=${entry?.id}&${openLogsModal}=true`;
      }
      default: {
        return `/projects/${projectId}/jobs/${jobId}/job-runs/${entry?.id}/job-canvas`;
      }
    }
  };

  return (
    <>
      {showConfirmScreen && (
        <NewThemeWrapper>
          <Modal
            open={true}
            variant={ModalVariants.Delete}
            title="Delete Job"
            content={[JobDeletePromptDetails.messageLine1, JobDeletePromptDetails.messageLine2]}
            onClose={cancelDeleteJob}
            onSubmit={confirmDeleteJob}
            isCancelDisabled={isDeleting}
            isSubmitDisabled={isDeleting}
            isSubmitting={isDeleting}
          />
        </NewThemeWrapper>
      )}
      <Card
        className={classes.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {isHovered && (
          <Grid className={classes.moreOptions}>
            <MoreOptions
              options={cardOptions}
              btnProps={{
                style: {
                  color: "#4646B5"
                }
              }}
            />
          </Grid>
        )}
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            item
            direction="row"
            xs={3}
            wrap="nowrap"
            alignItems="center"
            style={{ gap: "12px" }}>
            <Grid data-testid="jobIcon" item style={{ flexShrink: 0 }}>
              <JobIcon />
            </Grid>
            <Grid item container direction="column">
              <Grid item className={classes.gridItem}>
                <CardTitle
                  variant="body1"
                  dataTestId="jobName"
                  title={jobName}
                  highlightOnHover
                  link={`projects/${projectId}/jobs/${jobId}`}
                />
                <OverflowTooltip
                  style={{ whiteSpace: "nowrap" }}
                  value={`Last Run On: ${dateFormat(updated || created)}`}
                  tooltipContainerProps={{ className: classes.containerLight }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobProjects"
                component="div"
                color="textSecondary"
                variant="body2">
                Project
              </Typography>
              <CardTitle
                dataTestId="jobProjectName"
                highlightOnHover
                variant="body1"
                title={projectName}
                link={`projects/${projectId}`}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={3}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobFrequency"
                component="div"
                color="textSecondary"
                variant="body2">
                Frequency
              </Typography>
              <OverflowTooltip
                style={{ whiteSpace: "nowrap" }}
                value={jobFrequencyTemplate}
                tooltipContainerProps={{ className: classes.container }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobStatus"
                component="div"
                color="textSecondary"
                variant="body2">
                Job Status
              </Typography>
              {getJobStatusTemplate(status, true)}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Typography
              data-testid="jobLastFiveRunStatus"
              component="span"
              color="textSecondary"
              variant="body2">
              Last 5 Run Status
            </Typography>
            {lastRunEntries?.length > 0 && (
              <Grid container>
                {lastRunEntries
                  ?.slice(0, 5)
                  ?.sort(
                    (a: $TSFixMe, b: $TSFixMe) =>
                      (a.updated || a.created) - (b.updated || b.created)
                  )
                  ?.map((entry: $TSFixMe, index: number) => {
                    const tooltipMoreInfo = !!entry?.endTime ? (
                      <>
                        <br />
                        On: {dateFormat(entry?.endTime)}
                      </>
                    ) : null;

                    return (
                      <Link
                        key={`lastRunStatus_${jobName}_${index}`}
                        id={`lastRunStatus_${jobName}_${index}`}
                        to={navigateTo(entry)}>
                        {getJobRunStatusBadge(entry?.status, tooltipMoreInfo)}
                      </Link>
                    );
                  })}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
