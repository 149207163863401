import React from "react";

interface IProps {
  fill?: string;
  width?: string;
  height?: string;
  color?: string;
  viewBox?: string;
}

const DataAppBrandingIcon: React.FC<IProps> = ({
  width = 20,
  height = 20,
  fill = "none",
  color = "black",
  viewBox = "0 0 20 20"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M15.8334 1.6665H4.16672C2.78661 1.66803 1.66824 2.7864 1.66672 4.1665V11.7235V15.8332C1.66824 17.2133 2.78661 18.3316 4.16672 18.3332H15.8334C15.9853 18.333 16.1352 18.3149 16.283 18.2874C16.3381 18.2773 16.3906 18.2621 16.4444 18.2485C16.5327 18.2259 16.6195 18.1996 16.7048 18.1675C16.7669 18.1442 16.8274 18.1199 16.8871 18.0919C16.957 18.059 17.0239 18.0208 17.0908 17.9813C17.1534 17.9445 17.2167 17.9099 17.2757 17.868C17.2806 17.8645 17.2861 17.8622 17.2909 17.8587C17.2971 17.8542 17.3024 17.8487 17.3084 17.8439C17.3198 17.8347 17.3341 17.8301 17.3445 17.8197C17.3501 17.8142 17.3518 17.8067 17.3569 17.801C17.9463 17.3437 18.3325 16.6367 18.3334 15.8332V13.3918V4.1665C18.3319 2.7864 17.2135 1.66803 15.8334 1.6665ZM16.3125 17.421C16.157 17.4692 15.9963 17.4997 15.8334 17.4998H4.16672C3.24671 17.4987 2.50122 16.7532 2.50005 15.8332V11.896L5.63563 8.76042C6.20565 8.19238 7.12779 8.19238 7.6978 8.76042L10.5352 11.5975C10.5368 11.5992 10.5372 11.6013 10.5388 11.603L16.3463 17.4105C16.3351 17.4141 16.3238 17.4175 16.3125 17.421ZM17.5001 15.8332C17.4995 16.2634 17.3317 16.6517 17.0639 16.9473L11.4235 11.3075L12.3031 10.4279C12.8791 9.87614 13.7876 9.87614 14.3637 10.4279L17.5001 13.5643V15.8332ZM17.5001 12.3859L14.9529 9.8387C14.0581 8.94515 12.6087 8.94515 11.7139 9.8387L10.8343 10.7183L8.287 8.17122C7.39161 7.27767 5.94182 7.27767 5.04644 8.17122L2.50005 10.7176V4.1665C2.50122 3.2465 3.24671 2.50101 4.16672 2.49984H15.8334C16.7534 2.50101 17.4989 3.2465 17.5001 4.1665V12.3859ZM11.2501 4.99984C10.5597 4.99984 10.0001 5.55948 10.0001 6.24984C10.0001 6.9402 10.5597 7.49984 11.2501 7.49984C11.9401 7.49907 12.4993 6.93989 12.5001 6.24984C12.5001 5.55948 11.9404 4.99984 11.2501 4.99984ZM11.2501 6.6665C11.0199 6.6665 10.8334 6.47994 10.8334 6.24984C10.8334 6.01973 11.0199 5.83317 11.2501 5.83317C11.48 5.83363 11.6663 6.01989 11.6667 6.24984C11.6667 6.47994 11.4802 6.6665 11.2501 6.6665Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default DataAppBrandingIcon;
