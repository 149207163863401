import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import { Box, Grid, ListItemIcon, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { matchPath, useLocation } from "react-router-dom";

import Button from "components/Buttons/DefaultButton";
import VideoLibraryIcon from "src/icons/NewUX/VideoLibraryIcon";
import { OverflowTooltip } from "src/components";
import { WebPaths } from "src/routing/routes";

export const videos = [
  {
    title: "How to Create a Project",
    link: "https://www.youtube.com/embed/-C71_OOGUaY?si=jyCrUBlWIwXJgfwE",
    context: [WebPaths.Dashboard, WebPaths.Dag, WebPaths.Projects]
  },
  {
    title: "How to Create a Job in projects",
    link: "https://www.youtube.com/embed/WaogcBCdZnk?si=FBcK7VaFeHy4ECh3",
    context: [
      WebPaths.Dashboard,
      WebPaths.Dag,
      WebPaths.JobRoutes,
      `${WebPaths.JobRoutes}${WebPaths.JobId}`,
      `${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`,
      `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`,
      `${WebPaths.JobRoutes}${WebPaths.JobRuns}`,
      `${WebPaths.JobRoutes}${WebPaths.CreateJob}`,
      `${WebPaths.JobRoutes}${WebPaths.CreateJob}`
    ]
  },
  {
    title: "How to Create AI snippets",
    link: "https://www.youtube.com/embed/Lk-x-b9fLz0?si=0swwGkA9bjLmWJvo",
    context: [
      WebPaths.Dag,
      `${WebPaths.Dag}${WebPaths.Canvas}`,
      `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`
    ]
  },
  {
    title: "How to Import Datasets From Google Drive (a Fivetran Connector)",
    link: "https://www.youtube.com/embed/WLsIM-63wkk?si=C_gNiX4lGvbb2dDh",
    context: [
      WebPaths.DataConnector,
      WebPaths.DataConnectors,
      `${WebPaths.Dag}${WebPaths.Canvas}`,
      WebPaths.Dataset
    ]
  },
  {
    title: "Creating a Data Connector for Google Cloud Platform",
    link: "https://www.youtube.com/embed/pOW-RCzBdZ8?si=RcY7IztME0xN20sT",
    context: [WebPaths.DataConnectors, WebPaths.Dataset]
  },
  {
    title: "How to Set up an Environment for Your Project",
    link: "https://www.youtube.com/embed/g0oNVcAT1dY?si=hKN9Ey2gXiCWsM1N",
    context: [WebPaths.Environments, WebPaths.EnvironmentConfig]
  },
  {
    title: "How to Create an AskAI DataApp",
    link: "https://www.youtube.com/embed/KPC-CRIzvTw?si=S_S6Hd4M3RGBSUMD",
    context: [WebPaths.DataAppDashboard, WebPaths.ProjectDataApp]
  },
  {
    title: "How to Create a DataApp in Your Project",
    link: "https://www.youtube.com/embed/bfCiTl_-Fb8?si=DH0-ix4FPIOpHkkw",
    context: [WebPaths.DataAppDashboard, WebPaths.ProjectDataApp]
  },
  {
    title: "How to Add an AI-Assisted Recipe",
    link: "https://www.youtube.com/embed/OicKdu5-Dyk?si=ggxGAZSC11JBZqeB",
    context: [
      `${WebPaths.Dag}${WebPaths.Canvas}`,
      `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`,
      WebPaths.CodeRecipeContainer,
      WebPaths.JobCodeRecipe
    ]
  },
  {
    title: "How to Add a Rapid Model Recipe",
    link: "https://www.youtube.com/embed/gDQ0hIawU8Y?si=iITGYWmNgPz6fN4R",
    context: [
      `${WebPaths.Dag}${WebPaths.Canvas}`,
      `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`,
      WebPaths.AutoMLRecipeContainer,
      WebPaths.JobAutoMLRecipe
    ]
  },
  {
    title: "How to Create a Prediction Job",
    link: "https://www.youtube.com/embed/5mCTa7NAT9w?si=EGVqieXYLov9Mhky",
    context: [WebPaths.PredictionJob, WebPaths.EditPredictionJob]
  },
  {
    title: "Navigating Through the Canvas Workspace",
    link: "https://www.youtube.com/embed/xZh4P1y9P0M?si=00a-E3JSOn1GIeGL",
    context: [
      WebPaths.Dag,
      `${WebPaths.Dag}${WebPaths.Canvas}`,
      `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`
    ]
  },
  {
    title: "How to Import a Dataset from Your Local System",
    link: "https://www.youtube.com/embed/DG-u6cPVAJk?si=3iNI17_T-nqD5S_-",
    context: [`${WebPaths.Dag}${WebPaths.Canvas}`, WebPaths.Dataset]
  },
  {
    title: "Create a custom DataApp using Project Canvas and Custom Datasets as input",
    link: "https://www.youtube.com/embed/D1lqvobO6X8?si=enrg_bHNfIy6aHmv",
    context: [WebPaths.DataAppDashboard, WebPaths.ProjectDataApp]
  },
  {
    title: "How to Build a Custom DataApp Using Documents and PDFs as Input",
    link: "https://www.youtube.com/embed/g_t5sujsvTE?si=0fC9Yt4I_oihbfEC",
    context: [WebPaths.DataAppDashboard, WebPaths.ProjectDataApp]
  },
  {
    title: "How to create a Model DataApp",
    link: "https://www.youtube.com/embed/SD3WaG8KFBE?si=cALyGXxug55rL48j",
    context: [WebPaths.DataAppDashboard, WebPaths.ProjectDataApp]
  },
  {
    title: "How to create a Prediction Service",
    link: "https://www.youtube.com/embed/ux74FuI2GyY?si=p95wKBAMrnzdkwWQ",
    context: [WebPaths.ArtifactsAndModels, `${WebPaths.Dag}${WebPaths.Canvas}`]
  },
  {
    title: "How to create custom DataApp using SQL source as an input type",
    link: "https://www.youtube.com/embed/giKT67wnz_Y?si=wNOLM6ol4GiTWAoG",
    context: [WebPaths.DataAppDashboard, WebPaths.ProjectDataApp]
  },
  {
    title: "How to import files from GCS data connector",
    link: "https://www.youtube.com/embed/kSj2qFRVjvo?si=QqGEqOaDtBx3Jv9z",
    context: [`${WebPaths.Dag}${WebPaths.Canvas}`, WebPaths.Dataset]
  }
];

const useStyles = makeStyles(() => ({
  videos: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "6px"
  },
  menuItem: {
    borderBottom: "1px solid #cccccc",
    color: "#003656",
    fontSize: "0.875rem",

    "&:hover": {
      background: "rgba(230, 245, 255, .6)"
    },

    "&:last-of-type": {
      borderBottom: "none !important"
    },
    width: "inherit"
  }
}));

interface IProps {
  onClose: () => void;
  onSelectVideo: (video: IVideo) => void;
}

export interface IVideo {
  title: string;
  link: string;
  context: string[];
}

const VideoList: React.FC<IProps> = (props) => {
  const { onClose, onSelectVideo } = props;

  const [showAll, setShowAll] = useState(false);

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    setShowAll(false);
  }, [location]);

  const filteredVideos = useMemo(() => {
    if (showAll) {
      return videos;
    }
    const pathName = window.location.pathname;

    const filtered = _.filter(videos, ({ context }) =>
      _.some(context, (c) => matchPath({ path: c, end: true }, pathName))
    );

    if (filtered.length === 0) {
      return videos;
    }

    return filtered;
  }, [location, showAll]);

  const handleVideoClick = (video: IVideo) => {
    onSelectVideo(video);
  };

  const handleShowAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setShowAll(true);
  };

  return (
    <Box m="0px 10px" maxHeight="300px" overflow="auto">
      <MenuItem
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          position: "sticky",
          top: 0,
          background: "#fff",
          zIndex: 1
        }}
        data-testid="help-menu-videos"
        onClick={onClose}>
        <Grid container direction="row" alignItems="center">
          <ChevronLeftIcon fontSize="small" />
          <div className={classes.videos}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <VideoLibraryIcon />
            </ListItemIcon>
            <Typography variant="body2">How to Videos</Typography>
          </div>
        </Grid>
        {!showAll && filteredVideos.length !== videos.length && (
          <Button size="small" variant="outlined" onClick={handleShowAll}>
            Show All
          </Button>
        )}
      </MenuItem>
      {_.map(filteredVideos, (video, index) => (
        <MenuItem
          key={index}
          className={clsx([classes.menuItem])}
          onClick={() => handleVideoClick(video)}>
          <OverflowTooltip value={video.title} />
        </MenuItem>
      ))}
    </Box>
  );
};

export default VideoList;
