import React, { useMemo } from "react";
import { useParams } from "react-router";

import { Spinner } from "src/components";
import { useGetRecipe } from "src/hooks/api";
import ApiConnectorRecipeContainer from "./ApiConnectorRecipeContainer";
import { CodeRecipeContextProvider } from "./ApiConnectorRecipeContext/ApiConnectorRecipeContextProvider";

const ApiConnectorRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams();
  const { isLoading, data } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId,
    options: { cacheTime: undefined, refetchOnMount: true }
  });

  const { entityIds, artifactsIds, modelsIds } = useMemo(() => {
    return {
      entityIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "ENTITY")
        ?.map((parent) => parent.id),
      artifactsIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "ARTIFACT")
        ?.map((parent) => parent.id),
      modelsIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "MODEL")
        ?.map((parent) => parent.id)
    };
  }, [data?.recipe?.parents]);

  if (!groupId) {
    return (
      <CodeRecipeContextProvider entityIds={entityIds}>
        <ApiConnectorRecipeContainer />
      </CodeRecipeContextProvider>
    );
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <CodeRecipeContextProvider
      templates={data?.templates}
      recipe={data?.recipe}
      entityIds={entityIds}
      modelsIds={modelsIds}
      artifactsIds={artifactsIds}>
      <ApiConnectorRecipeContainer />
    </CodeRecipeContextProvider>
  );
};

export default ApiConnectorRecipeDataContainer;
