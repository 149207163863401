import React, { ReactNode, useState } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { MoreVert } from "@material-ui/icons";

import OverflowTooltip from "../../../OverflowTooltip";

const useStyles = makeStyles({
  link: {
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  },
  cellMenuContainer: {
    color: "#003656",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .hiddenDiv": {
      visibility: "hidden"
    },

    "&:hover .hiddenDiv": {
      visibility: "visible",
      display: "inline-flex !important"
    }
  },

  cellOptions: {
    visibility: "hidden",
    padding: "0",
    background: "none",
    minWidth: "5px",
    color: "black",
    marginRight: "-8px",
    flexShrink: 0
  },
  menuItem: {
    padding: "9px"
  },
  menu: {
    "& ul": {
      paddingTop: "0",
      paddingBottom: "0",
      "& > *": {
        color: "#003656 !important"
      },
      "& span[class~='MuiTypography-root']": {
        fontSize: "14px",
        textTransform: "uppercase"
      }
    }
  },
  listItemIcon: {
    color: "#003656 !important",
    minWidth: "28px",
    "& svg": {
      height: "19px"
    }
  }
});

interface Props {
  url?: string;
  // eslint-disable-next-line no-undef
  linkText: string | JSX.Element;
  menuButtons: {
    id?: string;
    label: string;
    action: () => void;
    icon: ReactNode;
    disabled?: boolean;
    tooltip?: string;
  }[];
  style?: React.CSSProperties;
  onClick?: () => void;
  title?: string;
  extra?: React.JSX.Element;
}

export const LinkCellWithMenu = ({
  url,
  linkText,
  style,
  menuButtons,
  title,
  extra,
  onClick
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLMenuItem = (item: $TSFixMe, index: number) => (
    <MenuItem
      test-id={item?.id || `dropdownMenu${index}`}
      className={classes.menuItem}
      key={item?.id || `dropdownMenu${index}`}
      onClick={(event: $TSFixMe) => {
        item.action(event);
        handleClose();
      }}
      disabled={item.disabled}
      style={item.disabled ? { pointerEvents: "none" } : {}}>
      {item.icon && <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>}
      <ListItemText>{item.label}</ListItemText>
    </MenuItem>
  );

  return (
    <Box className={classes.cellMenuContainer}>
      <OverflowTooltip
        title={title}
        value={
          url ? (
            <Link className={classes.link} to={url}>
              {linkText}
            </Link>
          ) : _.isFunction(onClick) ? (
            <div role="presentation" style={style} className={classes.link} onClick={onClick}>
              {linkText}
            </div>
          ) : (
            linkText
          )
        }
      />
      <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
        {extra}
        {/* cellOptionsIcon class is needed to handle visibility from Table styles */}
        <Button className={`${classes.cellOptions} cellOptionsIcon`} onClick={handleClick}>
          <MoreVert id="cellMenuIcon" fontSize="small" />
        </Button>
        <Menu
          id="cell-menu"
          className={classes.menu}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {menuButtons.map((item, index) => {
            return item.tooltip ? (
              <Tooltip key={item.label} arrow title={item.tooltip}>
                <span>{getLMenuItem(item, index)}</span>
              </Tooltip>
            ) : (
              getLMenuItem(item, index)
            );
          })}
        </Menu>
      </div>
    </Box>
  );
};
