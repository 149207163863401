import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_EDA = "query-key-eda";

export interface IData {
  edaJson: IEdaJson | null;
  url: string;
  isTruncated: boolean;
}

const POLLING_INTERVAL = 5;
const ABORT_TIMEOUT = 3 * 60;

interface IReturn {
  isError: boolean;
  edaJson: Record<string, any> | null;
}

interface IProps extends UseQueryOptions {
  entityId?: string;
  scenarioId?: string;
  jobRunId?: string;
  triggerOnError?: boolean;
  async?: boolean;
}

const getJson = async (jsonUrl: string): Promise<IReturn> => {
  return await fetch(jsonUrl, { signal: AbortSignal.timeout(ABORT_TIMEOUT * 1000) })
    .then((res) => {
      if (res.status === 200) {
        return res.text();
      } else {
        throw new Error("404");
      }
    })
    .then((text) => {
      const sanitizedData = text
        .replace(/:\s*NaN/g, ": null")
        .replace(/:\s*Infinity/g, `: "Infinity"`);
      return { isError: false, edaJson: JSON.parse(sanitizedData) };
    })
    .catch((e) => {
      return { isError: e?.message === "404", edaJson: null };
    });
};

export const useGetEntityEda = ({
  entityId,
  scenarioId,
  jobRunId,
  triggerOnError,
  async,
  ...options
}: IProps): UseQueryResult<IData> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_EDA, entityId, scenarioId, jobRunId, async, triggerOnError],
    queryFn: async () => {
      if (!entityId || !scenarioId) {
        throw "entityid or scenario id is absent";
      }
      const results = await postAPIWithRethrow(
        `/v2/entities/${entityId}/eda${async ? `?async=${async}` : ""}${
          triggerOnError ? `&triggerOnError=${triggerOnError}` : ""
        }`,
        {
          scenarioId,
          includeHtml: false,
          ...(jobRunId ? { projectRunEntryId: jobRunId } : {})
        },
        {},
        true
      );

      const { edaInfo, jsonUrl, url } = results;

      if (edaInfo?.status === "SUCCESS") {
        const { isError, edaJson } = await getJson(jsonUrl);

        const response = await fetch(url);
        if (response.status !== 200) {
          handleResponse({
            errorMessage: "Something went wrong while fetching the eda details"
          });
          throw "Unable to fetch url";
        } else {
          if (isError) {
            return { edaJson: null, url, isTruncated: false };
          } else {
            return { edaJson, url, isTruncated: edaInfo?.truncatedResults };
          }
        }
      }

      if (edaInfo?.status === "STARTED") {
        let count = 1;
        return new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            try {
              const repeatedResults = await postAPIWithRethrow(
                `/v2/entities/${entityId}/eda${async ? `?async=${async}` : ""}`,
                {
                  scenarioId,
                  includeHtml: false,
                  ...(jobRunId ? { projectRunEntryId: jobRunId } : {})
                },
                {},
                true
              );

              count = count + 1;
              if (
                repeatedResults?.edaInfo?.status === "SUCCESS" ||
                count > ABORT_TIMEOUT / POLLING_INTERVAL
              ) {
                clearInterval(interval);
                const { isError, edaJson } = await getJson(repeatedResults.jsonUrl);
                if (edaJson) {
                  resolve({
                    url,
                    edaJson,
                    isTruncated: repeatedResults?.edaInfo?.truncatedResults
                  });
                }
                if (isError) {
                  const response = await fetch(repeatedResults?.url);
                  if (response.status !== 200) {
                    reject("Unable to fetch url");
                    handleResponse({
                      errorMessage: "Something went wrong while fetching the eda details"
                    });
                  } else {
                    resolve({ edaJson: null, url, isTruncated: false });
                  }
                }
              }
            } catch {
              clearInterval(interval);
              reject("Unable to fetch url");
            }
          }, POLLING_INTERVAL * 1000);
        });
      }
    },
    refetchOnMount: true,
    ...options
  });
};

export { QUERY_KEY_EDA };
