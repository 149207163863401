/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProjectDto
 */
export interface ProjectDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'image'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     * @deprecated
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     * @deprecated
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'updater'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDto
     */
    'industries'?: Array<ProjectDtoIndustriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDto
     */
    'useCases'?: Array<ProjectDtoUseCasesEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'envId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'canvasEdgeStyle'?: ProjectDtoCanvasEdgeStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'solutionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'launchStatus'?: ProjectDtoLaunchStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    'defaultEnvFlag'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'podStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'runningJobCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDto
     */
    'runningPredictionJobCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'askAIContext'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDto
     */
    'isFavorite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectDto
     */
    'display_name'?: string;
}

export const ProjectDtoIndustriesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type ProjectDtoIndustriesEnum = typeof ProjectDtoIndustriesEnum[keyof typeof ProjectDtoIndustriesEnum];
export const ProjectDtoUseCasesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type ProjectDtoUseCasesEnum = typeof ProjectDtoUseCasesEnum[keyof typeof ProjectDtoUseCasesEnum];
export const ProjectDtoCanvasEdgeStyleEnum = {
    Curved: 'CURVED',
    Squared: 'SQUARED'
} as const;

export type ProjectDtoCanvasEdgeStyleEnum = typeof ProjectDtoCanvasEdgeStyleEnum[keyof typeof ProjectDtoCanvasEdgeStyleEnum];
export const ProjectDtoLaunchStatusEnum = {
    Success: 'SUCCESS',
    Launching: 'LAUNCHING',
    Shutdown: 'SHUTDOWN',
    Failed: 'FAILED',
    Unknown: 'UNKNOWN'
} as const;

export type ProjectDtoLaunchStatusEnum = typeof ProjectDtoLaunchStatusEnum[keyof typeof ProjectDtoLaunchStatusEnum];


