/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { AdditionalChart } from './additional-chart';
// May contain unused imports in some cases
// @ts-ignore
import type { ChartGroup } from './chart-group';
// May contain unused imports in some cases
// @ts-ignore
import type { DataappAskAIConfig } from './dataapp-ask-aiconfig';
// May contain unused imports in some cases
// @ts-ignore
import type { PredictionSvcMetadata } from './prediction-svc-metadata';

/**
 * 
 * @export
 * @interface DataAppDto
 */
export interface DataAppDto {
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'appTemplateType'?: DataAppDtoAppTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'appType'?: DataAppDtoAppTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'dlId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'accessUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'ttlInHrs'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'updater'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'createdOn'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'updatedOn'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'appTemplateId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DataAppDto
     */
    'params'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DataAppDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'cores'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'memInMbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'diskInGbs'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'envType'?: DataAppDtoEnvTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DataAppDto
     */
    'inactivityTtlInHrs'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'stoppedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'groupDisplayName'?: string;
    /**
     * 
     * @type {Array<AdditionalChart>}
     * @memberof DataAppDto
     */
    'additionalCharts'?: Array<AdditionalChart>;
    /**
     * 
     * @type {Array<ChartGroup>}
     * @memberof DataAppDto
     */
    'chartGroups'?: Array<ChartGroup>;
    /**
     * 
     * @type {PredictionSvcMetadata}
     * @memberof DataAppDto
     */
    'predictionSvcMetadata'?: PredictionSvcMetadata;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'launchStatus'?: DataAppDtoLaunchStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DataAppDto
     */
    'newAskAIFlow'?: boolean;
    /**
     * 
     * @type {DataappAskAIConfig}
     * @memberof DataAppDto
     */
    'askAIConfig'?: DataappAskAIConfig;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'dataAppType'?: DataAppDtoDataAppTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAppDto
     */
    'brandLogo'?: string;
}

export const DataAppDtoAppTemplateTypeEnum = {
    Streamlit: 'STREAMLIT',
    Reactjs: 'REACTJS'
} as const;

export type DataAppDtoAppTemplateTypeEnum = typeof DataAppDtoAppTemplateTypeEnum[keyof typeof DataAppDtoAppTemplateTypeEnum];
export const DataAppDtoAppTypeEnum = {
    Streamlit: 'STREAMLIT',
    Reactjs: 'REACTJS'
} as const;

export type DataAppDtoAppTypeEnum = typeof DataAppDtoAppTypeEnum[keyof typeof DataAppDtoAppTypeEnum];
export const DataAppDtoEnvTypeEnum = {
    ExtraSmall: 'EXTRA_SMALL',
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    CpuLarge: 'CPU_LARGE',
    MaxLarge: 'MAX_LARGE',
    ExtraMaxLarge: 'EXTRA_MAX_LARGE',
    Custom: 'CUSTOM'
} as const;

export type DataAppDtoEnvTypeEnum = typeof DataAppDtoEnvTypeEnum[keyof typeof DataAppDtoEnvTypeEnum];
export const DataAppDtoLaunchStatusEnum = {
    Unknown: 'UNKNOWN',
    Stopping: 'STOPPING',
    Stopped: 'STOPPED',
    Launching: 'LAUNCHING',
    Failure: 'FAILURE',
    Running: 'RUNNING',
    Deleting: 'DELETING'
} as const;

export type DataAppDtoLaunchStatusEnum = typeof DataAppDtoLaunchStatusEnum[keyof typeof DataAppDtoLaunchStatusEnum];
export const DataAppDtoDataAppTypeEnum = {
    RapidModel: 'RAPID_MODEL',
    Askai: 'ASKAI',
    Custom: 'CUSTOM'
} as const;

export type DataAppDtoDataAppTypeEnum = typeof DataAppDtoDataAppTypeEnum[keyof typeof DataAppDtoDataAppTypeEnum];


