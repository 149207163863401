import React, { useMemo, useState } from "react";
import { Grid } from "@material-ui/core";

import { LogsContent } from "./LogsContent";
import { useDrawerStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";
import { ShowLogsDrawerTitle, StatusType } from "./ShowLogsDrawerTitle";
import { useStyles } from "./RecipeLogsDrawer";

export const StandardLogs = ({
  logs,
  status,
  url
}: {
  logs: string[];
  status: StatusType;
  url: string;
}) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const closeLogs = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const onSearchValueChange = (event: $TSFixMe) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (logs || [])?.filter((eachField: $TSFixMe) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, logs]);

  return (
    <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
      <ShowLogsDrawerTitle
        exportDisabledMessage=""
        exportDisabled={false}
        title={""}
        onClose={closeLogs}
        {...(url && { url })}
        {...(status ? { status: { color: status, text: status } } : {})}
        searchValue={searchValue}
        onSearch={onSearchValueChange}
      />
      <LogsContent logs={filteredLogs} />
    </Grid>
  );
};
