import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { Grid, makeStyles, IconButton } from "@material-ui/core";
import { delay, isEmpty } from "lodash";

import AskAIInputDatasets from "./AskAIInputDatasets/AskAIInputDatasets";
import AskAIResponses from "./AskAIResponses/AskAIResponses";
import AskAIResponsesNewFlow from "./AskAIResponses/AskAIResponsesNewFlow";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { AskAILoaderItem } from "./AskAILoaderItem";
import { AskAIResponse } from "src/types";
import { useCodeRecipeContext } from "../../CodeRecipeContext/useCodeRecipeContext";
import { OUTPUT_TYPE } from "../../CodeRecipeContext/CodeRecipeContextProvider";
import { extractAskAIDatasets } from "./askai.helper";
import { getAPIWithRethrow } from "src/utils/apiService";

const useStyles = makeStyles({
  askAiConvoGrid: {
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
    flexWrap: "nowrap",
    overflow: "auto"
  },
  askAiResponses: {
    flexWrap: "nowrap",
    maxWidth: "95%"
  },

  inputDatasetsContainer: {
    flexWrap: "nowrap",
    gap: "16px",
    width: "95%",
    paddingBottom: "8px",
    paddingLeft: "16px"
  },

  floatBtns: {
    position: "absolute",
    bottom: "32px",
    right: "32px",
    zIndex: 99,
    width: "auto",
    gap: "8px",
    justifyContent: "flex-end"
  },
  floatBtn: {
    color: "#303077",
    border: "2px solid #303077"
  },
  askAiResponsesWrap: {
    flexWrap: "nowrap",
    position: "relative",
    justifyContent: "flex-start",
    overflow: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});

export const AskAIConversationGrid = ({
  isFetchingSuggestions,
  userInput,
  onUserInputChange
}: {
  isFetchingSuggestions: boolean;
  userInput: string;
  onUserInputChange: (userInput: string) => void;
}) => {
  const [hoverId, setHoverId] = useState(null);
  const [hoverInputs] = useState<string[]>([]);
  const [hoverCoordinates] = useState<{ x: number; y: number } | null>(null);
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
  const [isScrollToBottomVisible, setIsScrollToBottomVisible] = useState(false);
  const classes = useStyles({ hoverCoordinates, hasHover: !!hoverId });

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageStartRef = useRef<HTMLDivElement | null>(null);
  const askAIContainerRef = React.useRef<HTMLDivElement | null>(null);

  const {
    responses,
    isAutoGenerateInProgress,
    isAutoGeneratedSuccess,
    inputDatasets,
    entityFeaturesMap,
    autoGenerateQueryInputNames,
    autoGenerateQueryUserInput,
    newAskAIFlow,
    recipeId,
    inputNames,
    outputType,
    setIsRetryInProgress,
    fetchSuggestionsApiInfo,
    setOutputType,
    pinnedNames,
    setInputNames,
    setResponses,
    onAutoGenerateCode
  } = useCodeRecipeContext();

  const autoGenerateCode = (text: string) => {
    setOutputType(OUTPUT_TYPE.AUTO_INFER);

    const { intermediateDatasets, entityIds } = extractAskAIDatasets({
      inputDatasets,
      inputNames,
      responses
    });

    const payload = {
      userInput: text?.trim(),
      entityIds,
      outputType,
      recipeId,
      intermediateDatasets,
      inputNames
    };

    const onSuccess = (response: $TSFixMe) => {
      if (newAskAIFlow) {
        const outputName = response.outputEntityResponseList?.[0]?.name;
        if (isEmpty(pinnedNames) && response.outputType === OUTPUT_TYPE.DATASET && outputName) {
          setInputNames([outputName]);
        }
        return;
      }
      const output = response?.history?.outputType ?? outputType;
      const updatedOutputNames =
        output === OUTPUT_TYPE.CHART
          ? response?.pipelineTestResult?.entityViewData?.map((entity: any) => entity.name)
          : Object.keys(response?.pipelineTestResult?.dataMap);
      setResponses((responses: any) => [
        ...responses,
        {
          ...response,
          ...response?.history,
          userInput: userInput?.trim(),
          queryInputs: inputNames.map((name) => ({ name })),
          outputType: output,
          outputNames: updatedOutputNames,
          isExpanded: true,
          answer: response?.pipelineTestResult?.answer
        }
      ]);
      if (
        isEmpty(pinnedNames) &&
        output === OUTPUT_TYPE.DATASET &&
        response.history?.queryOutputs?.[0]?.name
      ) {
        setInputNames([response.history.queryOutputs[0].name]);
      }
    };

    const onError = async (error: any) => {
      if (newAskAIFlow) {
        return;
      }

      const commonResponsesParams = {
        userInput: userInput?.trim(),
        queryInputs: inputNames.map((name) => ({ name })),
        outputType,
        outputStatus: "FAILED"
      };
      const errorMsg = error.response?.data?.msg || error.response?.data; /* 
      const errorStatus = error.response.status;
      if (errorStatus >= 500 && errorStatus < 600) {
        setResponses([
          ...responses,
          {
            execErr: "Oops, something went wrong. please try again",
            ...commonResponsesParams,
            userInput: query?.trimEnd()
          }
        ]);
        return;
      } */
      if (errorMsg?.includes("codeHistoryId=")) {
        const match = errorMsg?.match(/codeHistoryId=(.*)/);
        const codeHistoryId = match ? match[1] : null;
        const response = await getAPIWithRethrow(
          `/v2/dfs-run-config-groups/${recipeId}/code-history/${codeHistoryId}`
        );
        if (!response?.erroneousCode) {
          setResponses((responses: any) => [
            ...responses,
            {
              execErr: response.execErr.replace(/codeHistoryId=.*$/, ""),
              ...commonResponsesParams
            }
          ]);
          return;
        }
        setResponses((responses: any) => [
          ...responses,
          {
            execErr: response.execErr.replace(/codeHistoryId=.*$/, ""),
            erroneousCode: response.erroneousCode,
            errDetails: response.errDetails,
            ...commonResponsesParams
          }
        ]);
      } else {
        setResponses((responses: any) => [
          ...responses,
          {
            execErr: errorMsg,
            ...commonResponsesParams
          }
        ]);
      }
    };
    onUserInputChange("");
    onAutoGenerateCode({ payload, onSuccess, onError });
  };

  const { fetchSuggestionsQueryInputNames, fetchSuggestionsUserInput } = fetchSuggestionsApiInfo;

  useEffect(() => {
    delay(() => scrollToBottom(), 1000);
  }, [isAutoGeneratedSuccess, isAutoGenerateInProgress, isFetchingSuggestions]);

  const handleScroll = (event: any) => {
    const TOLERANCE = screen.height * 0.5;
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + TOLERANCE;
    if (scrollTop > TOLERANCE) {
      setIsScrollToTopVisible(true);
    } else {
      setIsScrollToTopVisible(false);
    }
    setIsScrollToTopVisible(scrollTop > TOLERANCE);
    setIsScrollToBottomVisible(!isAtBottom);
  };

  const scrollToTop = () => {
    messageStartRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      onScroll={handleScroll}
      className={clsx(["askAiConvoGrid", classes.askAiConvoGrid])}>
      <Grid
        container
        direction="column"
        className={classes.askAiResponsesWrap}
        ref={askAIContainerRef}>
        <div id="messageStartRef" ref={messageStartRef}></div>
        <Grid container direction="column" className={classes.inputDatasetsContainer}>
          <AskAIInputDatasets
            inputDatasets={inputDatasets}
            entityFeaturesMap={entityFeaturesMap}
            isAutoGenerateInProgress={isAutoGenerateInProgress}
            hoverInputs={hoverInputs}
          />
        </Grid>
        <Grid container direction="column" className={classes.askAiResponses}>
          {newAskAIFlow ? (
            <AskAIResponsesNewFlow
              autoGenerateCode={autoGenerateCode}
              responses={responses as AIChatResponseDto[]}
              hoverId={hoverId}
              setHoverId={setHoverId}
              targetId={recipeId}
              isGenerating={isAutoGenerateInProgress || isFetchingSuggestions}
              setIsRetryInProgress={setIsRetryInProgress}
            />
          ) : (
            <AskAIResponses
              autoGenerateCode={autoGenerateCode}
              responses={responses as AskAIResponse[]}
              hoverId={hoverId}
              setHoverId={setHoverId}
              isGenerating={isAutoGenerateInProgress || isFetchingSuggestions}
            />
          )}
          {(isAutoGenerateInProgress || isFetchingSuggestions) && (
            <Grid container direction="column">
              <AskAILoaderItem
                isFetchingSuggestions={isFetchingSuggestions}
                inputNames={
                  isFetchingSuggestions
                    ? fetchSuggestionsQueryInputNames
                    : autoGenerateQueryInputNames
                }
                userInput={
                  isFetchingSuggestions
                    ? fetchSuggestionsUserInput || userInput
                    : autoGenerateQueryUserInput || userInput
                }
                testId="generate-output-loader"
                style={{ paddingBottom: "calc(10vh)" }}
                tags={isFetchingSuggestions ? ["Prompt Suggestions"] : []}
              />
            </Grid>
          )}
        </Grid>
        <div id="messagesEndRef" ref={messagesEndRef}></div>
      </Grid>
      <Grid container direction="row" wrap="nowrap" className={classes.floatBtns}>
        {isScrollToTopVisible && (
          <IconButton
            size="small"
            onClick={scrollToTop}
            className={classes.floatBtn}
            data-testid="arrowUpward">
            <ArrowUpward fontSize="medium" />
          </IconButton>
        )}
        {isScrollToBottomVisible && (
          <IconButton
            size="small"
            onClick={scrollToBottom}
            className={classes.floatBtn}
            data-testid="arrowDownward">
            <ArrowDownward fontSize="medium" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};
