import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { Condition } from "openapi/Models";

interface IVariable {
  groupId: string;
  displayName: string;
  condition?: Condition;
}

const useUpdateRecipeName = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ groupId, displayName, condition }) => {
      return await api.fetchResponse(() =>
        api.DfsRunConfigGroupControllerV2Api.updateGroup(groupId, { displayName, condition })
      );
    }
  });
};

export default useUpdateRecipeName;
