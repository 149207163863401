import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(2)
  },
  link: {
    color: "#003656",
    cursor: "pointer",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  },
  sourceImage: {
    display: "inline-block",
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  },
  sampleDataSchema: {
    padding: theme.spacing(0.5),
    fontSize: "small",
    fontStyle: "italic",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#7c7c7c"
  },

  disabledLink: {
    textDecoration: "none",
    color: theme.palette.action.disabled,
    pointerEvents: "none"
  }
}));

export default useStyles;
