import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Grid, makeStyles } from "@material-ui/core";

import Drawer from "components/Drawer/CustomDrawer";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { LogsContent } from "pages/Projects/common/ShowLogsModal/LogsContent";
import {
  getStatusEquivalence,
  ShowLogsDrawerTitle
} from "pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";
import useEnvironmentLogs from "hooks/api/environments/useEnvironmentLogs";
import { TEMP_MSG, useStyles } from "pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";
import { StatusBar, statusValues } from "../StatusBar/StatusBar";
import { Environment } from "../../Environments";
import useExportEnvLogs from "src/pages/Projects/common/useExportEnvLogs";

const useLogsStyles = makeStyles({
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

interface IProps {
  open: boolean;
  environment: Environment;
  onClose: () => void;
}

const EnvironmentLogsDrawer: React.FC<IProps> = (props) => {
  const { environment, open, onClose } = props || {};

  const exportEnvLogs = useExportEnvLogs();

  const classes = useStyles();
  const { drawer, drawerPaper } = useLogsStyles();
  const [searchValue, setSearchValue] = useState("");

  const isLaunchingOrSucceed = useMemo(
    () =>
      [EnvironmentStatuses.Launching, EnvironmentStatuses.Active].includes(
        environment?.launchStatus as EnvironmentStatuses
      ),
    [environment?.launchStatus]
  );

  const { data, isLoading, isFetching, isFetched, error } = useEnvironmentLogs(
    environment?.id,
    isLaunchingOrSucceed ? "live" : "offline",
    {
      refetchInterval: isLaunchingOrSucceed ? 2000 : false,
      enabled: open
    }
  );

  const errorMessage = error
    ? error?.response?.data?.msg || error?.message || "Something went wrong!"
    : undefined;

  const filteredLogs = useMemo(() => {
    return _.filter(data, (eachField: string) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, data]);

  const onSearchValueChange = (event: $TSFixMe) => {
    setSearchValue(event.target.value);
  };

  const exportLogs = async () => {
    await exportEnvLogs.mutateAsync({ envId: environment?.id, name: environment.name });
  };

  const title = (
    <ShowLogsDrawerTitle
      exportDisabledMessage=""
      exportDisabled={false}
      title={environment.name}
      url={`/environments/${environment.id}/logs`}
      exportLogs={exportLogs}
      exportLogLoading={exportEnvLogs.isLoading}
      status={{
        color: getStatusEquivalence(environment?.launchStatus as any),
        text:
          statusValues[environment?.launchStatus?.trim()?.toLowerCase()]?.text ||
          environment?.launchStatus
      }}
      statusComponent={<StatusBar env={environment} stoppingEnvironments={[]} />}
      searchValue={searchValue}
      onSearch={onSearchValueChange}
      onClose={onClose}
    />
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: drawerPaper
      }}
      className={drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {(isLoading || isFetching || !isFetched) && filteredLogs?.length === 0 ? (
          <LogsContent logs={TEMP_MSG} />
        ) : (
          <LogsContent logs={filteredLogs} {...(errorMessage ? { info: errorMessage } : {})} />
        )}
      </Grid>
    </Drawer>
  );
};

export default EnvironmentLogsDrawer;
