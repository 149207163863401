import React, { useState, useMemo, useCallback, useEffect } from "react";
import { concat, filter, includes, isEmpty, isEqual, map } from "lodash";
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import shallow from "zustand/shallow";

import { AutoMLRecipeContext } from "./AutoMLRecipeContext";
import {
  useGetEntityFeatures,
  useGetProblemType,
  useGetScenario,
  useUpdateDataAppMutation
} from "src/hooks/api";
import { useAddRecipeToQueue, UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";
import { useForm } from "src/utils/useForm";
import { getAPIWithRethrow, handleResponse } from "src/utils/apiService";
import {
  useGetDfsTemplateByName,
  useSaveRunConfigsMutation,
  useSaveStandardRecipeMutation
} from "src/hooks/api";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { areArraysEqual } from "src/utils/arrayUtils";
import { useRecipeRun } from "../../common/useRecipeRun";
import {
  EntityTypeEnum,
  Recipe,
  Scenario,
  Entity,
  EntityFeaturesResponse,
  DatasetCustomColumnsProps
} from "src/types";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { useCanvasStore } from "src/store/store";

import { DataAppType } from "pages/DataApps/DataApps.type";
import { RecipeStatuses } from "src/constants";
import { useGetAutoMLProblemTypes } from "../hooks/useGetAutoMLProblemTypes";
import {
  useEntityData,
  useEntityDataCustomColumns
} from "src/hooks/api/projects/useEntityDataAndStats";
import { WebPaths } from "src/routing/routes";
import { isRecipeRunInQueue as isRecipeRunInQueueHelper } from "src/pages/private/ProjectsModule/utils";
import useGetAllEntityColumns from "../hooks/useGetAllEntityColumns";
import { UpdateDataAppRequestDto } from "openapi/Models";
import useUpdateRecipeName from "src/hooks/api/transforms/useUpdateRecipeName";

export const AutoMLRecipeContextProvider = ({
  recipe: initialRecipe,
  children
}: {
  recipe?: Recipe | undefined;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const [
    isRecipesRunningAcrossScenariosStore,
    isPendingRecipeRunsInQueueStore,
    pendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state: $TSFixMe) => [
      state.isRecipesRunningAcrossScenarios,
      state.isPendingRecipeRunsInQueue,
      state.pendingRecipeRunsInQueue
    ],
    shallow
  );
  // << ENDS - Stores

  const [recipe, setRecipe] = useState<Recipe | null>(initialRecipe || null);
  const [isRecipeRunSuccessful, setIsRecipeRunSuccessful] = useState<boolean | null>(null);
  const [recipeDisplayName, setRecipeDisplayName] = useState<string | null>(
    initialRecipe?.displayName || initialRecipe?.name || null
  );
  const [scenarioData, setScenarioData] = useState<null | Scenario>(null);
  const [selectedTransform, setSelectedTransform] = React.useState<$TSFixMe>(
    recipe?.runConfigs?.[0] || {}
  );
  const [selectedInputDatasets, setSelectedInputDatasets] = useState<Array<Entity>>([]);
  const [inputDatasets, setInputDatasets] = React.useState(selectedInputDatasets);
  const [actualProblemType, setActualProblemType] = useState<string | null>(
    initialRecipe?.metadata?.problemType || ""
  );
  const [problemType, setProblemType] = useState<string | null>(
    initialRecipe?.metadata?.problemType === "binary_classification_gpt"
      ? "binary_classification"
      : initialRecipe?.metadata?.problemType || ""
  );
  const [isExperimental, setIsExperimental] = useState<boolean>(
    initialRecipe?.metadata?.problemType === "binary_classification_gpt"
  );

  const [runToastId, setRunToastId] = useState<any>(null);
  const [entityFeaturesMap, setEntityFeaturesMap] = useState<{
    [id: string]: EntityFeaturesResponse;
  } | null>(null);

  const { values, handleInputChange, errors, setValues } = useForm(selectedTransform?.variables);

  const { isLoading: isScenarioLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });

  useEffect(() => {
    setRecipe(initialRecipe || null);
    setRecipeDisplayName(initialRecipe?.displayName || initialRecipe?.name || null);
    setActualProblemType(initialRecipe?.metadata?.problemType || "");
    setProblemType(
      initialRecipe?.metadata?.problemType === "binary_classification_gpt"
        ? "binary_classification"
        : initialRecipe?.metadata?.problemType || ""
    );
    setIsExperimental(initialRecipe?.metadata?.problemType === "binary_classification_gpt");
    setValues(initialRecipe?.runConfigs?.[0]?.variables);
    setSelectedTransform(initialRecipe?.runConfigs?.[0] || {});
  }, [initialRecipe]);

  const { isLoading: isFetchingProblemTypes, data: allProblemTypes } = useGetAutoMLProblemTypes();

  const [currentEntityData, setCurrentEntityData] = useState<$TSFixMe>({});

  const allEntityColumnsResult = useGetAllEntityColumns(
    inputDatasets?.[0]?.id,
    scenarioId,
    jobRunId,
    { enabled: !!inputDatasets?.[0]?.id, refetchOnMount: true }
  );

  const {
    isLoading: isEntityLoading,
    mutateAsync: datasetDataMutation,
    reset: resetDatasetDataMutation
  } = useEntityData({
    onSuccess: (datasetDataResponse) => {
      setCurrentEntityData(() => datasetDataResponse?.data);
    }
  });

  React.useEffect(() => {
    const _ = async () => {
      await resetDatasetDataMutation();
      await datasetDataMutation({
        entityId: inputDatasets?.[0]?.id,
        scenarioId,
        jobRunId,
        payload: {
          rowsStart: 0,
          rowsEnd: 100,
          cols: []
        }
      });
    };

    !!inputDatasets?.[0]?.id && _();
  }, [inputDatasets?.[0]?.id, scenarioId, jobRunId]);

  const {
    mutateAsync: datasetDataCustomColumnsMutation,
    reset: resetDatasetDataCustomColumnsMutation
  } = useEntityDataCustomColumns({
    onSuccess: (datasetDataResponse) => {
      setCurrentEntityData(() => ({
        ...currentEntityData,
        columns: concat(currentEntityData?.columns, datasetDataResponse?.data?.columns),
        rows: map(currentEntityData?.rows, (row, index) => {
          if (datasetDataResponse?.data?.rows?.[index]) {
            row.cells = concat(row?.cells, datasetDataResponse?.data?.rows?.[index]?.cells);
          }
          return row;
        })
      }));
    }
  });

  const getDatasetCustomColumnsData = async ({ columnNames }: DatasetCustomColumnsProps) => {
    const newColumns = filter(columnNames, (col) => !includes(currentEntityData?.columns, col));

    if (isEmpty(newColumns)) {
      return;
    }

    await resetDatasetDataCustomColumnsMutation();

    const payload = {
      entityId: inputDatasets?.[0]?.id,
      scenarioId,
      jobRunId,
      payload: {
        rowsStart: 0,
        rowsEnd: 500,
        cols: newColumns
      }
    };

    await datasetDataCustomColumnsMutation(payload);
  };

  const saveRecipeMutation = useSaveStandardRecipeMutation();
  const saveRecipeNameMutation = useSaveStandardRecipeMutation();
  const updateTransformGroupName = useUpdateRecipeName();
  const saveRecipeRunTimeoutMutation = useSaveStandardRecipeMutation();
  const saveRunConfigsMutation = useSaveRunConfigsMutation();

  const numericColumns = useMemo(() => {
    return filter(allEntityColumnsResult.data, (column) =>
      ["FLOAT", "LONG"].includes(column.fieldSchema?.rcDataType ?? "")
    );
  }, [allEntityColumnsResult.data]);

  const targetCol = (values as any)?.["targetCol"];

  const columnEntityTypeMap = useMemo(() => {
    if (!entityFeaturesMap) return {};

    const firstEntityFeatures = Object.values(entityFeaturesMap)?.[0];
    if (!Array.isArray(firstEntityFeatures)) return {};

    const map: Record<string, string> = {};
    for (let i = 0; i < firstEntityFeatures.length; i++) {
      const feature = firstEntityFeatures[i];
      map[feature.name] = feature.fieldSchema.rcDataType;
    }

    return map;
  }, [entityFeaturesMap]);

  const isNumericTarget = useMemo(() => {
    if (!targetCol) return false;

    const targetType = columnEntityTypeMap[targetCol];
    return targetType === "FLOAT" || targetType === "LONG";
  }, [columnEntityTypeMap, targetCol]);

  const areEntitiesSame = useMemo(() => {
    if (!recipe?.id) {
      return false;
    }
    const recipeDatasetIds = recipe?.parents
      ?.filter((parent) => parent.type === EntityTypeEnum.ENTITY)
      ?.map((entity) => entity.id);
    const selectedDatasetIds = inputDatasets.map((ds) => ds.id);
    const areEntitiesSame = areArraysEqual(recipeDatasetIds, selectedDatasetIds);

    return areEntitiesSame;
  }, [inputDatasets, recipe?.id, recipe?.parents]);

  const isRecipeUnChanged = useMemo(() => {
    if (!recipe?.id || !areEntitiesSame) {
      return false;
    }

    const recipeTransformIds = recipe?.runConfigs?.map((config) => config.id);
    const currentTransformIds = selectedTransform ? [selectedTransform?.id] : [];
    const recipeTemplateVariables = recipe?.runConfigs?.[0]?.variables;
    const currentTemplateVariables = selectedTransform?.variables;

    const areTransformsSame =
      areArraysEqual(recipeTransformIds, currentTransformIds) &&
      isEqual(recipeTemplateVariables, currentTemplateVariables);
    const isProblemTypeSame = recipe ? recipe?.metadata?.problemType === actualProblemType : false;
    return areEntitiesSame && areTransformsSame && isProblemTypeSame;
  }, [
    inputDatasets,
    recipe?.id,
    recipe?.parents,
    recipe?.runConfigs,
    selectedTransform,
    recipe?.metadata?.problemType,
    problemType
  ]);

  const isSaveInProgress = saveRecipeMutation.isLoading || saveRunConfigsMutation.isLoading;

  const { isFetched: isFetchedProblemType, data: suggestedProblemType } = useGetProblemType({
    entityId: inputDatasets?.[0]?.id,
    targetCol: (values as any)?.["targetCol"],
    problemType,
    enabled: recipe
      ? inputDatasets?.length === 0 || !selectedTransform
        ? false
        : !isRecipeUnChanged &&
          recipe?.runConfigs?.[0]?.variables?.["targetCol"] !== (values as any)?.["targetCol"]
      : true
  });

  const suggestedTemplateName = suggestedProblemType
    ? allProblemTypes?.find((problemType) => problemType.name === suggestedProblemType)
        ?.templateName || ""
    : "";

  React.useEffect(() => {
    if (
      suggestedProblemType &&
      isFetchedProblemType &&
      suggestedProblemType !== "UNKNOWN" &&
      suggestedProblemType !== "UNRECOGNIZED"
    ) {
      const updatedProblemType = allProblemTypes?.find(
        (problemType) => problemType.name === suggestedProblemType
      );
      updatedProblemType && setProblemType(updatedProblemType.templateName);
    } else if (
      suggestedProblemType &&
      isFetchedProblemType &&
      (suggestedProblemType === "UNKNOWN" || suggestedProblemType === "UNRECOGNIZED")
    ) {
      setProblemType("");
    }
  }, [isFetchedProblemType, suggestedProblemType]);

  const { isLoading: isFetchingTemplate, data: templateData } = useGetDfsTemplateByName({
    templateName: actualProblemType,
    enabled: !!actualProblemType
  });
  const updateDataAppMutation = useUpdateDataAppMutation();

  React.useEffect(() => {
    !isFetchingTemplate &&
      setValues((values) =>
        templateData?.inputs?.reduce((acc: $TSFixMe, input: $TSFixMe) => {
          acc[input.name] =
            input.name === "inputDataset1" && inputDatasets?.[0]?.name
              ? inputDatasets[0]?.name
              : input.name === "targetCol" && (values as any)?.["targetCol"]
                ? (values as any)?.["targetCol"]
                : (initialRecipe?.metadata?.problemType === "binary_classification_gpt"
                      ? "binary_classification"
                      : initialRecipe?.metadata?.problemType || "") === templateData?.name
                  ? selectedTransform?.variables?.[input.name]
                  : input?.display?.default_value || input?.metadata?.default_value;
          return acc;
        }, {})
      );
  }, [templateData, inputDatasets]);

  const onRunSuccess = () => {
    toast.dismiss(runToastId);
    setIsRecipeRunSuccessful(true);
    toastWrapper({
      type: ToastTypes.Success,
      content: `Run is successful`,
      actions: [
        {
          label: "View Canvas",
          action: () => {
            if (projectId && scenarioId) {
              navigate(
                generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
                  projectId,
                  scenarioId
                }),
                { replace: true }
              );
            }
          }
        }
      ]
    });
  };
  const onRunError = () => {
    toast.dismiss(runToastId);
    setIsRecipeRunSuccessful(false);
  };

  const { isRunInProgress, onRecipeRun } = useRecipeRun(
    recipe?.status === RecipeStatuses.Running,
    recipe?.id,
    onRunSuccess,
    onRunError
  );

  const {
    isLoading: isAddingRecipeToQueue,
    mutateAsync: addRecipeToQueueMutation,
    reset: resetAddRecipeToQueueMutation
  } = useAddRecipeToQueue();

  useEffect(() => {
    if (isRunInProgress) {
      const toastId = toast(`Recipe Run is in progress`, {
        type: ToastTypes.Info,
        className: "toastify-info"
      });
      setRunToastId(toastId);
    }
  }, [isRunInProgress]);

  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });

  const getUpdatedTransformPayload = () => {
    const transformId = selectedTransform.id || uuidv4();
    return {
      ...selectedTransform,
      inputValues: Object.keys(selectedTransform?.variables)?.reduce((acc, item) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[item] = selectedTransform?.variables[item]?.replace(", ", ",");
        return acc;
      }, {}),
      id: transformId,
      templateId: templateData?.id || selectedTransform.templateId,
      projectId,
      name: selectedTransform.name || templateData?.name,
      groupId: recipe?.id
    };
  };

  const onUpdateRecipe = (updatedRecipe: Recipe) => {
    !recipe &&
      projectId &&
      scenarioId &&
      navigate(
        generatePath(WebPaths.AutoMLRecipeContainer, {
          projectId,
          scenarioId,
          groupId: updatedRecipe?.id
        }),
        {
          replace: true
        }
      );
    setRecipe(updatedRecipe);
    setRecipeDisplayName(updatedRecipe.displayName || updatedRecipe.name);
    setSelectedInputDatasets(inputDatasets);
  };

  const handleSave = () => {
    const transformData = getUpdatedTransformPayload();
    const updatedName = recipeDisplayName || recipe?.name;
    updatedName &&
      saveRecipeMutation.mutate(
        {
          recipe,
          recipeName: updatedName,
          selectedInputDatasets: inputDatasets,
          projectId: projectId!,
          payload: {
            metadata: { problemType: actualProblemType },
            recipeType: "AUTO_ML"
          }
        },
        {
          onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
            saveRunConfigsMutation.mutate(
              {
                recipe: transformGroupResponse,
                transformData
              },
              {
                onSuccess: async ({
                  transformGroupResponse: updatedRecipe
                }: {
                  transformGroupResponse: Recipe;
                }) => {
                  const runConfig = updatedRecipe.runConfigs?.[0];

                  onUpdateRecipe(updatedRecipe);
                  setSelectedTransform(runConfig);
                  handleResponse({
                    successMessage: `Rapid Model Recipe saved successfully.`
                  });

                  // If datasets change, check if dataApps exists and update
                  if (areEntitiesSame) {
                    return;
                  }

                  const dataApps = await getAPIWithRethrow(
                    `/dataapps/by-group/${transformGroupResponse.id}`
                  );
                  if (dataApps?.length === 0) {
                    return;
                  }

                  dataApps?.forEach((dataApp: DataAppType) => {
                    updateDataAppMutation.mutate({
                      id: dataApp.id,
                      payload: {
                        ...(dataApp as UpdateDataAppRequestDto),
                        metadata: runConfig.variables
                      }
                    });
                  });
                }
              }
            );
          },
          onError: () => {
            const updatedTransform = {
              ...selectedTransform,
              variables: recipe?.runConfigs?.[0] || {}
            };
            setInputDatasets(selectedInputDatasets);
            setProblemType(recipe?.metadata?.problemType || "");
            setSelectedTransform(updatedTransform);
            setValues(updatedTransform?.variables);
          }
        }
      );
  };

  const addRecipeToQueue = async () => {
    await resetAddRecipeToQueueMutation();
    await addRecipeToQueueMutation(
      { scenarioId, id: recipe?.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);

          toastWrapper({
            type: ToastTypes.Info,
            content: `Recipe ${recipeDisplayName || recipe?.name} added to queue successfully!`
          });
        },
        onError: () => {
          toastWrapper({
            type: ToastTypes.Error,
            content: `Error while adding Recipe ${recipeDisplayName || recipe?.name} to queue!`
          });
        }
      }
    );
  };

  const handleRun = async () => {
    toast.dismiss();

    if (!scenarioId || !recipe?.id) {
      return;
    }

    !!projectId && checkEnvRelaunch(projectId);

    if (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore) {
      addRecipeToQueue();
    } else {
      onRecipeRun({ recipe, onSuccess: onRunSuccess, onError: onRunError });
    }
  };

  const handleUpdateRecipeRunTimeout = ({ timeout: updatedTimeout }: { timeout: number }) => {
    saveRecipeRunTimeoutMutation.mutate(
      {
        recipe,
        selectedInputDatasets,
        projectId: projectId!,
        payload: {
          timeout: updatedTimeout,
          recipeType: "AUTO_ML"
        }
      },
      {
        onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
          onUpdateRecipe(transformGroupResponse);
          handleResponse({ successMessage: "Recipe Run Timeout updated successfully." });
        }
      }
    );
  };

  const handleUpdateRecipeName = ({
    recipeName: updatedRecipeName,
    onError
  }: {
    recipeName: string;
    onError: () => void;
  }) => {
    if (recipe?.id)
      updateTransformGroupName.mutate(
        {
          groupId: recipe?.id,
          displayName: updatedRecipeName,
          condition: recipe?.condition
        },
        {
          onSuccess: (response) => {
            onUpdateRecipe(response);
            handleResponse({ successMessage: "Recipe Name updated successfully." });
          },
          onError
        }
      );
    else {
      saveRecipeNameMutation.mutate(
        {
          recipe,
          selectedInputDatasets,
          projectId: projectId!,
          recipeName: updatedRecipeName,
          payload: {
            displayName: updatedRecipeName,
            recipeType: "AUTO_ML"
          }
        },
        {
          onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
            onUpdateRecipe(transformGroupResponse);
            handleResponse({ successMessage: "Recipe Name updated successfully." });
          },
          onError
        }
      );
    }
  };
  const isRecipeTimeoutUpdating = saveRecipeRunTimeoutMutation.isLoading;

  const checkForTemplateErrors = useCallback(() => {
    if (!templateData) {
      return true;
    }
    const { inputs = [] } = templateData;
    const newErrors = {};
    const hasErrors = inputs.reduce((acc: $TSFixMe, input: $TSFixMe) => {
      const isRequired = (input.metadata || input.display).is_required;
      if (isRequired) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newErrors[input.name] = !values?.[input.name];
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return values?.[input.name] ? acc : true;
      } else {
        return acc;
      }
    }, false);
    return hasErrors;
  }, [templateData, values]);
  const hasTemplateErrors = checkForTemplateErrors();

  const { isFetching: isGetEntityFeaturesLoading } = useGetEntityFeatures({
    datasetsToFetch: inputDatasets,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (entityFeatures: EntityFeaturesResponse) => {
      const newFeatures = inputDatasets?.reduce(
        (acc, dataset, index) => ({
          ...acc,
          [dataset?.name]: entityFeatures[index]
        }),
        {}
      );
      setEntityFeaturesMap(newFeatures);
    }
  });

  const isNotValidRecipe =
    inputDatasets?.length === 0 ||
    !problemType ||
    !templateData ||
    hasTemplateErrors ||
    isFetchingTemplate;
  const isSaveDisabled =
    isSaveInProgress ||
    isNotValidRecipe ||
    isRecipeUnChanged ||
    isRunInProgress ||
    isRecipeTimeoutUpdating ||
    saveRecipeNameMutation.isLoading;

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: recipe?.id,
        scenarioId: scenarioId
      }),
    [pendingRecipeRunsInQueueStore, recipe?.id, scenarioId]
  );

  const isRunDisabled = useMemo(
    () =>
      isNotValidRecipe ||
      !recipe?.id ||
      isRunInProgress ||
      isRecipeRunInQueue ||
      isAddingRecipeToQueue ||
      isSaveInProgress ||
      !isRecipeUnChanged,
    [
      isNotValidRecipe,
      recipe?.id,
      isRunInProgress,
      isRecipeRunInQueue,
      isAddingRecipeToQueue,
      isSaveInProgress,
      isRecipeUnChanged
    ]
  );

  const saveDisabledMessage =
    inputDatasets?.length === 0 || isFetchingTemplate || !templateData
      ? "Add all mandatory inputs to enable save"
      : "";

  const isAddRecipeToQueue = useMemo(
    () =>
      !isRunInProgress &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [isRunInProgress, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  const runDisabledMessage = useMemo(
    () =>
      !isRecipeUnChanged || !recipe?.id
        ? "Click save to enable run"
        : isRunInProgress
          ? "Recipe run is in progress"
          : isRecipeRunInQueue
            ? "Recipe is already in queue!"
            : isAddingRecipeToQueue
              ? "Recipe is being added to queue"
              : !!isAddRecipeToQueue
                ? "Add to Queue"
                : "Run Recipe",
    [
      isRecipeUnChanged,
      recipe?.id,
      isRunInProgress,
      isRecipeRunInQueue,
      isAddingRecipeToQueue,
      isAddRecipeToQueue
    ]
  );

  const contextValue = {
    jobData,
    jobRunData,
    recipe,
    scenarioData,
    setScenarioData,
    isScenarioLoading,
    recipeDisplayName,
    setSelectedInputDatasets,
    setRecipeDisplayName,
    selectedInputDatasets,
    inputDatasets,
    setInputDatasets,
    setSelectedTransform,
    problemType,
    setProblemType,
    values,
    handleInputChange,
    errors,
    handleSave,
    templateData,
    isSaveDisabled,
    isSaveInProgress,
    isAddRecipeToQueue,
    isAddingRecipeToQueue,
    isRunDisabled,
    isRunInProgress,
    handleRun,
    handleUpdateRecipeRunTimeout,
    isRecipeTimeoutUpdating,
    handleUpdateRecipeName,
    saveDisabledMessage,
    runDisabledMessage,
    isRecipeRunSuccessful,
    entityFeaturesMap,
    setEntityFeaturesMap,
    isGetEntityFeaturesLoading,
    isEntityLoading,
    currentEntityData,
    getDatasetCustomColumnsData,
    setIsRecipeRunSuccessful,
    allProblemTypes,
    isFetchingProblemTypes,
    isExperimental,
    setIsExperimental,
    setActualProblemType,
    suggestedTemplateName,
    setValues,
    numericColumns,
    isNumericTarget,
    allColumns: allEntityColumnsResult.data ?? [],
    columnsLoading: allEntityColumnsResult.isFetching
  };
  return (
    <AutoMLRecipeContext.Provider value={contextValue}>{children}</AutoMLRecipeContext.Provider>
  );
};
