import React, { useEffect, useState } from "react";
import { trim } from "lodash";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { WelcomeMessage } from "./WelcomeMessage";
import { EmailInput } from "./EmailInput";

// Styles
import { useStyles } from "./SignIn.styles";
import { PatternRexExps } from "src/utils";
import { PublicScreenLabels } from "src/constants";

import { RCLogo } from "./RCLogo";
import { SignInButton } from "./SignInButton";
import { useResolveSSOAuthMutation } from "src/hooks/api/auth/useResolveSSOAuthMutation";
import EmailPasswordSignIn from "./EmailPasswordSignIn";
import { SignInError } from "./SignInError";
import { getErrorMessage } from "src/utils/apiService";
import { useQuery } from "src/hooks";

const SSOSupportedSignInFlow = () => {
  const classes = useStyles();
  const queryParameters = useQuery();
  const [isRegularLoginFlow, setIsRegularLoginFlow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const invitationRequestDetails = queryParameters.get("invitation_details");

  const resolveSSOAuthMutation = useResolveSSOAuthMutation();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm({ defaultValues: { email: "" }, mode: "onChange" });

  const handleSignIn = (data: any) => {
    setIsLoading(true);
    resolveSSOAuthMutation.mutate(
      {
        email: data.email,
        ...(invitationRequestDetails ? { invitationRequestDetails } : {})
      },
      {
        onSuccess: (response) => {
          if (response.status === 203 && response.headers["location"]) {
            window.location.href = response.headers["location"];
            return;
          }
          setIsRegularLoginFlow(true);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    invitationRequestDetails &&
      resolveSSOAuthMutation.mutate(
        {
          ...(invitationRequestDetails ? { invitationRequestDetails } : {})
        },
        {
          onSuccess: (response) => {
            if (response.status === 203 && response.headers["location"]) {
              window.location.href = response.headers["location"];
              return;
            }
          },
          onSettled: () => {
            setIsLoading(false);
          }
        }
      );
  }, [invitationRequestDetails]);

  if (isRegularLoginFlow) {
    const email = getValues("email");
    return (
      <EmailPasswordSignIn
        email={email}
        disableEmailField
        showPrev
        onPrev={() => setIsRegularLoginFlow(false)}
      />
    );
  }

  return (
    <Grid container className={classes.page}>
      <Paper variant="outlined" className={classes.root}>
        <Grid container direction="column" className={classes.container}>
          <RCLogo />
          <Grid item className={classes.titleContainer}>
            <Typography variant="h5" component="div" align="center" data-testid="signInTitle">
              {PublicScreenLabels.SignIn}
            </Typography>
          </Grid>
          <WelcomeMessage isSignUpFlow={false} />
          <Grid
            container
            component="form"
            className={classes.formContainer}
            onSubmit={handleSubmit(handleSignIn)}>
            <EmailInput
              disabled={isLoading}
              {...register("email", {
                required: true,
                pattern: PatternRexExps.TrimmedEmail,
                onChange: (event) => {
                  setValue("email", trim(event?.target?.value));
                }
              })}
              error={errors?.email}
            />

            <SignInButton disabled={isLoading} loading={isLoading} text="Next" />
            <SignInError
              error={getErrorMessage(resolveSSOAuthMutation.error)}
              isVisible={!!resolveSSOAuthMutation.error}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SSOSupportedSignInFlow;
