import React from "react";
import _, { toLower } from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import EnvironmentCard, { NO_PROJECTS } from "./EnvironmentCard";
import Text from "src/components/Widget/Text";
import { Environment } from "../../Environments";
import { EnvironmentsConfig } from "../../utils/Environments.constants";

const useStyles = makeStyles({
  link: {
    display: "inline-block",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

type Props = {
  environmentsTypes: $TSFixMe[];
  list: Environment[];
  stoppingEnvironments: $TSFixMe;
  logs: (env: Environment) => void;
  usage: (env: Environment) => void;
  onDeleteEnv: (env: Environment) => void;
  onEditEnv: (id: string) => void;
  onRelaunchEnv: (env: Environment) => void;
  onStopEnv: (env: Environment) => void;
};

export const EnvironmentCards = ({
  environmentsTypes,
  list = [],
  onDeleteEnv,
  onEditEnv,
  onRelaunchEnv,
  stoppingEnvironments,
  onStopEnv,
  logs,
  usage
}: Props) => {
  const { link } = useStyles();

  const availableWindowSize = window.innerWidth * (1 - EnvironmentsConfig.ExemptingWidth);
  const statusOrder = ["launching", "success", "shutdown", "failed"];

  return (
    <Grid container justifyContent="flex-start" style={{ gap: 24, width: availableWindowSize }}>
      {list
        ?.sort((a, b) => b.updated - a.updated)
        .sort((a, b) => {
          return (
            statusOrder.indexOf(toLower(a.launchStatus)) -
            statusOrder.indexOf(toLower(b.launchStatus))
          );
        })
        .map((env: Environment) => {
          const projects =
            env.projects.length > 0
              ? _.map(env.projectDtos, (project, i) => (
                  <span key={project.id}>
                    {i > 0 && <span>{", "}</span>}
                    <Link
                      className={link}
                      to={`/projects/${project.id}`}
                      onClick={(e) => e.stopPropagation()}>
                      <Text value={project.name} />
                    </Link>
                  </span>
                ))
              : NO_PROJECTS;

          return (
            <EnvironmentCard
              key={env.id}
              env={env}
              environmentsTypes={environmentsTypes}
              projects={projects}
              onStopEnv={onStopEnv}
              onEditEnv={onEditEnv}
              stoppingEnvironments={stoppingEnvironments}
              onRelaunchEnv={onRelaunchEnv}
              onDeleteEnv={onDeleteEnv}
              onUsage={usage}
              onLogsClick={logs}
            />
          );
        })}
    </Grid>
  );
};
