import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  makeStyles,
  Radio,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import clsx from "clsx";
import { orderBy, toLower } from "lodash";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
    border: "1px solid #cbcbcb",
    borderRadius: 4,
    "& span[class~='MuiCheckbox-root']": {
      padding: "7px"
    }
  },
  header: {
    width: "100%",
    backgroundColor: "#fff"
  },
  valueContainer: {
    padding: "0 14px",
    borderTop: "1px solid #e1e1e1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& label[class~='MuiFormControlLabel-root']": {
      marginBottom: "0"
    }
  },
  search: {
    color: "#7c7c7c",
    height: 50,
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class~='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class~='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  },
  selectAllContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 14px",
    "& label": {
      marginBottom: "0"
    }
  },
  labelWordBreak: {
    wordBreak: "break-all"
  }
});

interface MultiSelectionProps {
  isMultiSelection?: boolean;
  searchPlaceholder: string;
  data: $TSFixMe;
  searchedValues: $TSFixMe;
  selectedValues: $TSFixMe;
  selectedValuesInSet?: $TSFixMe;
  // eslint-disable-next-line no-unused-vars
  onValueSearch: (value: string) => void;
  onValueSelection: $TSFixMeFunction;
  isLabelWordBreak?: boolean;
}

const Selection = ({
  isMultiSelection = true,
  searchPlaceholder = "Search",
  data,
  searchedValues,
  selectedValues,
  selectedValuesInSet,
  onValueSearch,
  onValueSelection,
  isLabelWordBreak = false
}: MultiSelectionProps) => {
  const classes = useStyles();

  const [searchedValue, setSearchedValue] = useState("");
  const [selectSelectAll, setSelectSelectAll] = useState(true);

  const searchedData = useMemo(() => {
    const regExp = new RegExp(searchedValue, "gi");

    const filteredData =
      data?.filter((thisEntry: $TSFixMe) => thisEntry?.name?.match(regExp)) || [];

    return filteredData;
  }, [searchedValue, data]);

  useEffect(() => {
    const selectedValuesIds = selectedValues?.map((thisEntry: $TSFixMe) => thisEntry?.id) || [];
    const filteredDataIds =
      searchedData?.filter((thisEntry: $TSFixMe) => selectedValuesIds.includes(thisEntry?.id)) ||
      [];

    if (selectedValues?.length > 0 && searchedData?.length === filteredDataIds?.length) {
      setSelectSelectAll(true);
    } else {
      setSelectSelectAll(false);
    }
  }, [selectedValues, searchedData?.length]);

  const handleValueSelection = (e: $TSFixMe) => {
    // if (searchedValues?.length === 1 && !e.target.checked) return;
    const checked = e?.target?.checked;
    const targetValue = e?.currentTarget?.value;

    if (targetValue === "selectAll") setSelectSelectAll(checked);
    onValueSelection(e);
  };

  const handleValueSearch = (value: string) => {
    onValueSearch(value);
    setSearchedValue(value);
  };

  return (
    <div>
      <FormControl className={classes.formControl} component="fieldset">
        <div className={classes.header}>
          <TextField
            onChange={(e: $TSFixMe) => handleValueSearch(e?.target?.value)}
            InputProps={{
              onKeyDown: (e: $TSFixMe) => e?.stopPropagation(),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              type: "search"
            }}
            placeholder={searchPlaceholder}
            className={classes.search}
            id="column-search-input"
            name="column-search-input"
            variant="outlined"
          />
        </div>
        <FormGroup>
          {isMultiSelection && (
            <div className={classes.selectAllContainer}>
              <FormControlLabel
                disabled={selectSelectAll}
                value="selectAll"
                onChange={handleValueSelection}
                control={<Checkbox size="small" checked={selectSelectAll} color="primary" />}
                label={<Typography variant="body2">Select All</Typography>}
                labelPlacement="end"
              />
              <Typography color="textSecondary" variant="caption">
                {`(${searchedValues?.length || 0} of 
                  ${searchedData?.length || 0})`}
              </Typography>
            </div>
          )}
          <>
            {orderBy(searchedData, (item) => toLower(item.name))?.map(
              (thisEntry: $TSFixMe, index: number) => (
                <div key={`column_${index}`} className={classes.valueContainer}>
                  <FormControlLabel
                    value={thisEntry?.id}
                    onChange={handleValueSelection}
                    control={
                      isMultiSelection ? (
                        <Checkbox
                          size="small"
                          checked={
                            selectedValues?.filter(
                              (thisSelectedValue: $TSFixMe) =>
                                thisSelectedValue?.id === thisEntry?.id
                            )?.length > 0
                          }
                          color="primary"
                        />
                      ) : (
                        <Radio
                          size="small"
                          checked={
                            selectedValues?.filter(
                              (thisSelectedValue: $TSFixMe) =>
                                thisSelectedValue?.id === thisEntry?.id
                            )?.length > 0
                          }
                          disabled={
                            (selectedValues || [])?.filter(
                              (thisSelectedValue: $TSFixMe) =>
                                thisSelectedValue?.id === thisEntry?.id
                            )?.length === 0 &&
                            (selectedValuesInSet || [])?.filter(
                              (thisValueInSet: $TSFixMe) => thisEntry?.id === thisValueInSet
                            )?.length > 0
                          }
                          color="primary"
                        />
                      )
                    }
                    label={
                      <Typography
                        variant="body2"
                        className={clsx({ [classes.labelWordBreak]: isLabelWordBreak })}>
                        {thisEntry?.name}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </div>
              )
            )}
          </>
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default Selection;
