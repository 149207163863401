import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  TextField,
  Box,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  ListSubheader,
  FormControl,
  Checkbox,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  Typography,
  Grid,
  makeStyles,
  withStyles,
  Tooltip as MuiTooltip
} from "@material-ui/core";
import { ErrorOutline, ArrowDropDown, ArrowDropUp, Info, InfoOutlined } from "@material-ui/icons";
import { groupBy, includes, orderBy, toLower } from "lodash";

import SelectSearch from "components/Inputs/SelectSearch";
import { OverflowTooltip } from "src/components";
import { addEllipsis } from "src/helpers/helpers";
import { entitiesFeaturesGetter } from "src/store/store.selectors";
import { useRunConfigStore } from "src/store/store";
import { FixedSizeList } from "react-window";

export const useStyles = makeStyles({
  inputBox: {
    display: "flex",
    alignItems: "center"
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "&: label": {
      whiteSpace: "nowrap"
    }
  },
  transformSelect: {
    width: "100%"
  },
  transformInput: {
    width: "100%"
  },
  checkbox: {
    padding: 0
  },
  nestedPopupWrap: {
    flexWrap: "nowrap",
    gap: "8px"
  },
  infoIcon: {
    color: "#4646b5"
  },
  templateWrap: {
    flexDirection: "column",
    flexWrap: "nowrap",
    gap: "8px"
  },
  boldTitle: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 600
  },
  subTitle: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "rgba(0,0,0,0.5)"
  },
  tooltipWrap: {
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "16px"
  },
  selectPaper: {
    maxHeight: 250,
    maxWidth: 340
  }
});

const HtmlTooltip = withStyles({
  tooltip: {
    width: "300px",
    backgroundColor: "#fff",
    border: "1px solid #4646b5",
    padding: "12px",
    filter: "drop-shadow(0px 4px 4px rgba(86, 86, 86, 0.35))"
  }
})(MuiTooltip);

const ToolTip = ({ title, description }: { title: string; description: string }) => {
  const classes = useStyles();
  return (
    <HtmlTooltip
      placement="bottom-end"
      title={
        <Grid container direction="row" className={classes.nestedPopupWrap}>
          <Grid item>
            <Info className={classes.infoIcon} test-id="selectedTransformPopupInfoIcon" />
          </Grid>
          <Grid item container direction="column" className={classes.templateWrap}>
            <Typography
              color="primary"
              className={classes.boldTitle}
              test-id="selectedTransformPopupTitle">
              {title}
            </Typography>
            <Typography className={classes.subTitle} test-id="selectedTransformPopupDescription">
              {description}
            </Typography>
          </Grid>
        </Grid>
      }>
      <InfoOutlined fontSize="small" style={{ color: "#4646b5" }} />
    </HtmlTooltip>
  );
};

const SingleDataset = ({
  index,
  errors,
  values,
  handleInputChange,
  input,
  inputOptions,
  outputOptions,
  disabled
}: $TSFixMe) => {
  const classes = useStyles();
  const inputInfo = input?.metadata ? "metadata" : "display";
  const currentInput = input[inputInfo] || input;
  return (
    <Grid container direction="row" className={classes.tooltipWrap}>
      <Grid
        container
        item
        key={`transform-input-dataset-${index}`}
        className={classes.inputBox}
        test-id="selectedTransformInputDatasetContainer">
        <FormControl
          margin="dense"
          required={currentInput?.is_required}
          variant="outlined"
          fullWidth
          error={errors?.[input.name]}>
          <InputLabel
            htmlFor={`transform-input-dataset-${index}`}
            test-id="selectedTransformInputDatasetLabel">
            {currentInput?.input_name}
          </InputLabel>
          <Select
            margin="dense"
            fullWidth
            label={currentInput?.input_name}
            value={values[input.name]}
            onChange={handleInputChange}
            inputProps={{
              name: input.name,
              id: `transform-input-dataset-${index}`,
              "test-id": "selectedTransformInputDatasetField"
            }}
            disabled={disabled || (inputOptions.length === 0 && outputOptions.length === 0)}>
            {!!inputOptions.length && (
              <ListSubheader test-id={"selectedTransformInputDatasetInputTitle"}>
                Input Datasets
              </ListSubheader>
            )}
            {inputOptions?.length > 0 &&
              inputOptions.map((inputOption: $TSFixMe, index: number) => (
                <MenuItem
                  key={`inputOption_${index}`}
                  test-id={"selectedTransformInputDatasetInputOption"}
                  value={inputOption?.display_name || inputOption?.displayName}
                  dense>
                  {inputOption?.display_name || inputOption?.displayName}
                </MenuItem>
              ))}
            {!!outputOptions.length && (
              <ListSubheader test-id={"selectedTransformInputDatasetOutputTitle"}>
                Output Datasets
              </ListSubheader>
            )}
            {outputOptions.map((value: $TSFixMe) => (
              <MenuItem
                key={value}
                value={value}
                dense
                test-id={"selectedTransformInputDatasetOutputOption"}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <ToolTip title={currentInput?.input_name} description={currentInput?.tooltip}></ToolTip>
    </Grid>
  );
};
const MultipleDatasets = ({
  index,
  errors,
  values,
  handleInputChange,
  input,
  inputOptions,
  outputOptions,
  disabled
}: $TSFixMe) => {
  const classes = useStyles();
  const inputInfo = input?.metadata ? "metadata" : "display";
  const currentInput = input[inputInfo] || input;

  return (
    <Grid container direction="row" className={classes.tooltipWrap}>
      <Grid
        container
        key={`transform-input-dataset-${index}`}
        className={classes.inputBox}
        test-id="selectedTransformInputDatasetsContainer">
        <FormControl
          required={currentInput?.is_required}
          variant="outlined"
          fullWidth
          margin="dense"
          error={errors?.[input.name]}>
          <InputLabel
            id={`transform-input-dataset-${index}`}
            test-id="selectedTransformInputDatasetsLabel">
            {currentInput?.input_name}
          </InputLabel>
          <Select
            labelId={`transform-input-dataset-${index}`}
            label={currentInput?.input_name}
            multiple
            inputProps={{
              "test-id": "selectedTransformInputDatasetsField"
            }}
            value={values[input.name]?.split(",") || []}
            onChange={(e) => {
              // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
              if (e.target.value.includes("check-all-input-datasets-values")) {
                const allOptions = [
                  ...inputOptions.map((item: $TSFixMe) => item.name),
                  ...outputOptions
                ];
                const value =
                  values[input.name]?.split(",").length === allOptions.length
                    ? null
                    : allOptions.join(",");
                handleInputChange({
                  target: { value, name: input.name }
                });
              } else {
                handleInputChange({
                  target: {
                    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                    value: e.target.value?.length ? e.target.value?.join(",") : null,
                    name: input.name
                  }
                });
              }
            }}
            renderValue={(selected) => (selected as $TSFixMe).join(", ")}
            disabled={disabled || (inputOptions.length === 0 && outputOptions.length === 0)}>
            <MenuItem value="checkAllInputDatasetsValues" dense>
              <ListItemIcon>
                <Checkbox
                  checked={
                    values[input.name]?.split(",")?.length ===
                    inputOptions?.length + outputOptions?.length
                  }
                  test-id="selectedTransformInputDatasetsAllInputsCheckbox"
                  className={classes.checkbox}
                  disableRipple
                  size="small"
                />
              </ListItemIcon>
              <ListItemText primary="Select All" />
            </MenuItem>
            {!!inputOptions.length && <ListSubheader>Available Datasets</ListSubheader>}
            {orderBy(inputOptions, (item) => toLower(item.display_name || item.displayName)).map(
              ({ name, display_name, displayName }: $TSFixMe) => (
                <MenuItem key={name} value={display_name || displayName} dense>
                  <ListItemIcon>
                    <Checkbox
                      className={classes.checkbox}
                      test-id="selectedTransformInputDatasetsInputOption"
                      disableRipple
                      size="small"
                      checked={!!values[input.name]?.split(",")?.includes(name)}
                    />
                  </ListItemIcon>
                  <ListItemText primary={name} test-id="selectedTransformInputDatasetsInputLabel">
                    {display_name || displayName}
                  </ListItemText>
                </MenuItem>
              )
            )}
            {outputOptions.map((name: $TSFixMe) => (
              <MenuItem key={name} value={name} dense>
                <ListItemIcon>
                  <Checkbox
                    className={classes.checkbox}
                    test-id="selectedTransformOutputDatasetsInputOption"
                    disableRipple
                    size="small"
                    checked={!!values[input.name]?.split(",")?.includes(name)}
                  />
                </ListItemIcon>
                <ListItemText primary={name} test-id="selectedTransformOutputDatasetsInputLabel">
                  {name}
                </ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <ToolTip title={currentInput?.input_name} description={currentInput?.tooltip} />
    </Grid>
  );
};

export const InputDatasets = ({
  isJobPath = false,
  inputs = [],
  inputOptions = [],
  outputOptions = [],
  handleInputChange,
  values,
  disabled,
  errors = {}
}: $TSFixMe) => {
  const classes = useStyles();
  return (
    <Box className={classes.inputsContainer}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'input' implicitly has an 'any' type. */}
      {inputs.map((input, index) => {
        const inputInfo = input?.metadata ? "metadata" : "display";
        return input[inputInfo]?.multiple ? (
          <MultipleDatasets
            key={index}
            values={values}
            disabled={!!isJobPath || disabled}
            errors={errors}
            input={input}
            index={index}
            inputOptions={orderBy(inputOptions, (item) =>
              toLower(item.display_name || item.displayName)
            )}
            outputOptions={outputOptions}
            handleInputChange={handleInputChange}
          />
        ) : (
          <SingleDataset
            key={index}
            values={values}
            disabled={!!isJobPath || disabled}
            errors={errors}
            input={input}
            index={index}
            inputOptions={orderBy(inputOptions, (item) =>
              toLower(item.display_name || item.displayName)
            )}
            outputOptions={outputOptions}
            handleInputChange={handleInputChange}
          />
        );
      })}
    </Box>
  );
};

export const OutputDatasets = ({
  isJobPath = false,
  outputs = [],
  values,
  handleInputChange,
  disabled,
  showHelpText = true,
  errors = {}
}: $TSFixMe) => {
  const classes = useStyles();
  return (
    <Box className={classes.inputsContainer}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'output' implicitly has an 'any' type. */}
      {outputs.map((output, index) => {
        const outputInfo = output?.metadata ? "metadata" : "display";
        const currentOutput = output[outputInfo] || output;
        return (
          <Grid
            key={`transform-output-dataset-${index}-${Object.keys(values)?.length}`}
            container
            direction="row"
            className={classes.tooltipWrap}>
            <Grid
              container
              className={classes.inputBox}
              test-id="selectedTransformOutputDatasetsContainer">
              <TextField
                margin="dense"
                id={`transform-output-dataset-${index}`}
                name={output.name}
                fullWidth
                label={currentOutput?.input_name}
                onChange={handleInputChange}
                size="small"
                variant="outlined"
                value={values[output.name]}
                disabled={!!isJobPath || disabled}
                error={errors?.[output.name]}
                required={currentOutput?.is_required}
                inputProps={{
                  maxLength: 64,
                  "test-id": "selectedTransformOutputDatasetsField"
                }}
              />
            </Grid>
            {showHelpText && (
              <ToolTip title={currentOutput?.input_name} description={currentOutput?.tooltip} />
            )}
          </Grid>
        );
      })}
    </Box>
  );
};

export const Parameters = ({
  isJobPath = false,
  input = {},
  handleInputChange,
  selectedEntities,
  values,
  outputDatasets = [],
  errors = {},
  columnOptions,
  showHelpText = true,
  disabled
}: $TSFixMe) => {
  const classes = useStyles();
  const inputInfo = input?.metadata ? "metadata" : "display";
  const currentInput = input[inputInfo];
  const datasetInputNames = currentInput.datasets || currentInput.dataset || currentInput.Dataset;
  const datatypes = currentInput.datatypes || currentInput.datatype;
  const constantOptions = currentInput?.constant_options;
  const inputRef = useRef();

  const entitiesFeatures = useRunConfigStore(entitiesFeaturesGetter);

  const [value, setValue] = useState("");
  const [fields, setFields] = useState([]);
  const [inputFields, setInputFields] = useState({});
  const [searchTerm] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInvalidTemplate, setIsInvalidTemplate] = useState(false);

  const areFieldsAvailable = useCallback(
    (inputName: $TSFixMe, outputDatasets: $TSFixMe) => {
      try {
        const datasets = values[inputName]?.split(",") || [];
        return datasets.reduce(
          (acc: $TSFixMe, val: $TSFixMe) =>
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            (inputFields?.[val] || outputDatasets.includes(val)) && acc,
          true
        );
      } catch (e) {
        console.error(e);
        setIsInvalidTemplate(true);
      }
    },
    [inputFields, values]
  );

  useEffect(() => {
    try {
      const getFieldsFromData = (inputValue: $TSFixMe) => {
        const datasets = inputValue?.split(",");
        datasets?.forEach((datasetName: $TSFixMe) => {
          const datasetFeatures = entitiesFeatures[datasetName];
          datasetFeatures &&
            setInputFields((prevFields) => ({
              ...prevFields,
              ...{
                [datasetName]: datasetFeatures.reduce((acc: $TSFixMe, col: $TSFixMe) => {
                  let newAcc = acc;
                  if (
                    datatypes?.includes(col?.fieldSchema?.rcDataType) ||
                    datatypes?.includes("ALL")
                  ) {
                    newAcc = acc.concat({
                      value: col.name,
                      type: datasetName
                    });
                  }
                  return newAcc;
                }, [])
              }
            }));
        });
      };
      if (datasetInputNames?.[0] === "ALL") {
        selectedEntities?.forEach((item: $TSFixMe) => {
          // @ts-expect-error TS(2550) FIXME: Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
          if (Object.values(values).includes(item.name) && !inputFields?.[item.name]) {
            getFieldsFromData(item.name);
          }
        });
      } else {
        datasetInputNames?.forEach((inputName: $TSFixMe) => {
          if (values[inputName] && !areFieldsAvailable(inputName, outputDatasets)) {
            getFieldsFromData(values[inputName]);
          }
        });
      }
    } catch (e) {
      console.error(e);
      setIsInvalidTemplate(true);
    }
  }, [
    areFieldsAvailable,
    datasetInputNames,
    datatypes,
    entitiesFeatures,
    inputFields,
    outputDatasets,
    selectedEntities,
    values
  ]);

  useEffect(() => {
    try {
      let newFields: $TSFixMe = [];
      currentInput?.options?.forEach((item: $TSFixMe) => {
        if (item === "FIELDS") {
          let newInputFields;
          if (datasetInputNames?.[0] === "ALL") {
            // // @ts-expect-error TS(2550) FIXME: Property 'values' does not exist on type 'ObjectCo... Remove this comment to see the full error message
            newInputFields = Object.values(inputFields).reduce(
              (acc: $TSFixMe, fields: $TSFixMe) => {
                const concatenatedFields = acc.concat(fields);
                return concatenatedFields;
              },
              []
            );
          } else {
            const dataset = datasetInputNames?.reduce(
              (acc: $TSFixMe, name: $TSFixMe) => (values[name] ? values[name] : acc),
              ""
            );
            newInputFields = dataset?.split(",").reduce((acc: $TSFixMe, item: $TSFixMe) => {
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              return [...acc, ...(inputFields[item] || [])];
            }, []);
          }
          newFields = newFields.concat(newInputFields);
        } else if (item === "CONSTANT") {
          const constants = (constantOptions || []).map((constant: $TSFixMe) => ({
            type: "Constant",
            value: constant
          }));
          newFields = newFields.concat(constants);
        }
      });
      setFields(newFields);
    } catch (e) {
      console.error(e);
      setIsInvalidTemplate(true);
    }
  }, [constantOptions, currentInput?.options, datasetInputNames, inputFields, values]);

  const filteredFields = useMemo(
    () =>
      fields?.filter((field: $TSFixMe) =>
        field?.value?.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [],
    [fields, searchTerm]
  );
  const groupedFields = useMemo(
    () => groupBy(filteredFields, (item) => (item as $TSFixMe).type) || {},
    [filteredFields]
  );

  const renderRow = useCallback(
    () =>
      ({ style, index }: { index: number; style: React.CSSProperties }): any => {
        let item;

        const onClick = (value: any) => () => {
          setIsMenuOpen(false);
          handleInputChange({
            target: { value, name: input.name }
          });
        };

        if (columnOptions && columnOptions.length) {
          const option = columnOptions[index];
          if (!value || includes(toLower(option), toLower(value))) {
            item = (
              <MenuItem
                key={option}
                value={option}
                dense
                test-id="selectedTransformColumnName"
                onClick={onClick(option)}>
                <OverflowTooltip value={option} />
              </MenuItem>
            );
          }
        } else if (filteredFields && filteredFields.length) {
          const field: any = filteredFields[index];
          if (!value || includes(toLower(field.value), toLower(value))) {
            item = (
              <MenuItem
                onClick={onClick(field.value)}
                key={field.value}
                value={field.value}
                dense
                test-id="selectedTransformParameterLabel">
                {field.value}
              </MenuItem>
            );
          }
        }
        return <div style={style}>{item}</div>;
      },
    [columnOptions, filteredFields, value, handleInputChange]
  );

  const height = Math.min(250, (columnOptions ?? filteredFields)?.length * 32);

  const selectVal = currentInput?.multiple
    ? values[input.name]?.split(", ") || []
    : values[input.name];

  return isInvalidTemplate ? (
    <Typography variant="body2" color="error">
      <ErrorOutline /> This template is not valid.
    </Typography>
  ) : constantOptions || columnOptions ? (
    <Grid
      container
      direction="row"
      className={classes.tooltipWrap}
      key={`transform-parameter-${input.name}`}>
      <Grid container className={classes.inputBox} test-id="selectedTransformParameterContainer">
        <FormControl
          variant="outlined"
          fullWidth
          margin="dense"
          error={errors?.[input.name]}
          required={currentInput?.is_required}
          disabled={!!isJobPath || disabled}>
          <InputLabel
            htmlFor={`transform-parameter-${input.name}`}
            id={`transform-parameter-${input.name}-label`}
            shrink={!!selectVal}
            test-id="selectedTransformParameterInputLabel">
            {currentInput?.input_name}
          </InputLabel>
          <Select
            fullWidth
            labelId={`transform-parameter-${input.name}-label`}
            inputProps={{
              name: `transform-parameter-${input.name}`,
              id: `transform-parameter-${input.name}`,
              "test-id": "selectedTransformParameterField"
            }}
            input={<OutlinedInput label={currentInput?.input_name} notched={!!selectVal} />}
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            onOpen={() => setIsMenuOpen(true)}
            label={currentInput?.input_name}
            value={selectVal ?? ""}
            renderValue={(renderVal: any) => <>{renderVal}</>}
            MenuProps={{
              classes: {
                paper: classes.selectPaper
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            multiple={currentInput?.multiple}
            onChange={
              currentInput.multiple
                ? (e) => {
                    handleInputChange({
                      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                      target: { value: e.target.value?.join(", "), name: input.name }
                    });
                  }
                : handleInputChange
            }
            disabled={isJobPath || disabled}>
            <SelectSearch value={value} onChange={setValue} />
            <FixedSizeList
              height={height}
              itemCount={columnOptions?.length ?? filteredFields?.length}
              itemSize={32}
              width={"auto"}>
              {renderRow()}
            </FixedSizeList>
          </Select>
        </FormControl>
      </Grid>
      {showHelpText && (
        <ToolTip title={currentInput?.input_name} description={currentInput?.tooltip} />
      )}
    </Grid>
  ) : (
    <Grid container direction="row" className={classes.tooltipWrap}>
      <Grid container className={classes.inputBox} test-id="selectedTransformParametersContainer">
        <FormControl
          variant="outlined"
          fullWidth
          margin="dense"
          error={errors?.[input.name]}
          required={currentInput?.is_required}
          disabled={!!isJobPath || disabled}>
          <InputLabel
            htmlFor={`parameters-input-${currentInput?.input_name}`}
            test-id="selectedTransformParametersInputLabel">
            {currentInput?.input_name}
          </InputLabel>
          <OutlinedInput
            margin="dense"
            fullWidth
            label={currentInput?.input_name}
            id={`parameters-input-${currentInput?.input_name}`}
            type="text"
            value={values[input.name] || ""}
            onChange={handleInputChange}
            inputRef={inputRef}
            inputProps={{
              name: input.name,
              "test-id": "selectedTransformParametersField",
              onClick: () => setIsMenuOpen(!isMenuOpen)
            }}
            {...(!!Object.keys(groupedFields)?.length && {
              endAdornment: (
                <InputAdornment
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  position="end"
                  style={{
                    cursor: "pointer",
                    marginLeft: 10,
                    right: 7,
                    color: "rgba(0, 0, 0, 0.54)",
                    position: "absolute"
                  }}>
                  {!values.isMenuOpen ? <ArrowDropDown /> : <ArrowDropUp />}
                </InputAdornment>
              )
            })}
            disabled={isJobPath || disabled}
          />
          <Menu
            open={isMenuOpen && !!Object.keys(groupedFields)?.length}
            onClose={() => setIsMenuOpen(false)}
            anchorEl={inputRef?.current}
            transformOrigin={{
              vertical: -52,
              horizontal: "left"
            }}
            PaperProps={{
              style: {
                // @ts-expect-error TS(2322) FIXME: Type '{ "& li[class^=\"MuiMenuItem-root\"]": { pad... Remove this comment to see the full error message
                [`& li[class^="MuiMenuItem-root"]`]: {
                  paddingTop: 0,
                  paddingBottom: 0
                },
                minWidth: (inputRef?.current as $TSFixMe)?.offsetWidth
              }
            }}>
            {currentInput?.multiple && (
              <MenuItem
                value="all"
                test-id="selectedTransformAllParametersMenuitem"
                dense
                onClick={() => {
                  // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'never'.
                  const allOptions = filteredFields.map((item) => item?.value);
                  const value =
                    values[input.name]?.split(", ").length === allOptions.length
                      ? null
                      : allOptions.join(", ");
                  handleInputChange({
                    target: { value, name: input.name }
                  });
                }}>
                <ListItemIcon>
                  <Checkbox
                    className={classes.checkbox}
                    test-id="selectedTransformAllParametersCheckbox"
                    disableRipple
                    size="small"
                    checked={values[input.name]?.split(", ")?.length === filteredFields?.length}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Select All"
                  test-id="selectedTransformAllParametersCheckboxText"
                />
              </MenuItem>
            )}
            {Object.keys(groupedFields).map((key) => (
              <div key={key}>
                <ListSubheader style={{ background: "white", zIndex: 1 }}>{key}</ListSubheader>
                {groupedFields[key].map(({ value }) =>
                  currentInput?.multiple ? (
                    <MenuItem
                      dense
                      key={value}
                      value={value}
                      test-id="selectedTransformParametersMenuitem"
                      onClick={() => {
                        const currentValues = values[input.name];
                        if (!currentValues || !currentValues?.includes(value)) {
                          const updatedValue = currentValues
                            ? currentValues.concat(", ", value)
                            : value;
                          handleInputChange({ target: { value: updatedValue, name: input.name } });
                        } else if (currentValues && currentValues.includes(value)) {
                          const updatedValues = currentValues
                            .split(", ")
                            .filter((currentVal: string | number) => currentVal !== value);
                          const updatedValueStr = updatedValues.join(", ");
                          handleInputChange({
                            target: { value: updatedValueStr, name: input.name }
                          });
                        }
                      }}>
                      <ListItemIcon>
                        <Checkbox
                          className={classes.checkbox}
                          test-id="selectedTransformParametersCheckbox"
                          disableRipple
                          size="small"
                          checked={!!values[input.name]?.split(", ")?.includes(value)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={value}
                        test-id="selectedTransformAllParametersCheckboxText">
                        {value}
                      </ListItemText>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      dense
                      key={value}
                      value={value}
                      onClick={() => {
                        setIsMenuOpen(false);
                        handleInputChange({ target: { value, name: input.name } });
                      }}>
                      <ListItemText
                        test-id="selectedTransformAllParametersCheckboxText"
                        primary={value}>
                        {value}
                      </ListItemText>
                    </MenuItem>
                  )
                )}
              </div>
            ))}
          </Menu>
        </FormControl>
      </Grid>
      {showHelpText && (
        <ToolTip title={currentInput?.input_name} description={currentInput?.tooltip} />
      )}
    </Grid>
  );
};

export const OutputCharts = ({
  isJobPath = false,
  inputs = [],
  values,
  handleInputChange,
  showHelpText = true,
  errors = {},
  disabled
}: $TSFixMe) => {
  const classes = useStyles();
  return (
    <Box className={classes.inputsContainer}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'input' implicitly has an 'any' type. */}
      {inputs.map((input, index) => (
        <Grid
          container
          direction="row"
          key={`transform-output-charts-${index}-${Object.keys(values)?.length}`}
          className={classes.tooltipWrap}>
          <Grid className={classes.inputBox} test-id="selectedTransformOutputChartsContainer">
            <TextField
              margin="dense"
              id={`transform-output-charts-${index}`}
              name={input.name}
              fullWidth
              label={addEllipsis(input.display?.input_name || input.metadata?.input_name)}
              value={values[input.name]}
              onChange={handleInputChange}
              size="small"
              variant="outlined"
              error={errors?.[input.name]}
              inputProps={{
                "test-id": "selectedTransformOutputChartsField"
              }}
              required={input.display?.is_required || input.metadata?.is_required}
              disabled={!!isJobPath || disabled}
            />
          </Grid>
          {showHelpText && (
            <ToolTip
              title={input.display?.input_name || input.metadata?.input_name}
              description={input.display?.tooltip || input.metadata?.tooltip || ""}
            />
          )}
        </Grid>
      ))}
    </Box>
  );
};
