import React from "react";
import useStyles from "./Source.styles";

import useDownloadDataFiles from "src/hooks/api/entities/useDownloadDataFiles";
import { CircularProgress, Link, Tooltip } from "@material-ui/core";
import clsx from "clsx";

interface IProps {
  item: any;
}

const FileName: React.FC<IProps> = ({ item }) => {
  const classes = useStyles();
  const downloadDataFile = useDownloadDataFiles();
  const handleDownload = (id: string, name: string) => {
    downloadDataFile.mutate({ id, name });
  };
  return (
    <Tooltip title="click to download">
      <Link
        className={clsx(classes.link, downloadDataFile.isLoading ? classes.disabledLink : null)}
        component={"button"}
        underline={"none"}
        disabled={downloadDataFile.isLoading}
        onClick={() => handleDownload(item.id, item.name)}>
        {item.name}
        {downloadDataFile.isLoading && (
          <span style={{ padding: "0 10px" }}>
            <CircularProgress size={14} />
          </span>
        )}
      </Link>
    </Tooltip>
  );
};

export default FileName;
