import React, { useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Img1 from "src/assets/images/dashaboardCards/preview_image1.png";
import Img2 from "src/assets/images/dashaboardCards/preview_image2.png";
import Img3 from "src/assets/images/dashaboardCards/preview_image3.png";
import Img4 from "src/assets/images/dashaboardCards/preview_image4.png";
import Img5 from "src/assets/images/dashaboardCards/preview_image5.png";
import Img6 from "src/assets/images/dashaboardCards/preview_image6.png";
import Img7 from "src/assets/images/dashaboardCards/preview_image7.png";
import Img8 from "src/assets/images/dashaboardCards/preview_image8.png";
import Img9 from "src/assets/images/dashaboardCards/preview_image9.png";
import Img10 from "src/assets/images/dashaboardCards/preview_image10.png";
import Img11 from "src/assets/images/dashaboardCards/preview_image11.png";
import Img12 from "src/assets/images/dashaboardCards/preview_image12.png";
import Img13 from "src/assets/images/dashaboardCards/preview_image13.png";
import Img14 from "src/assets/images/dashaboardCards/preview_image14.png";
import Img15 from "src/assets/images/dashaboardCards/preview_image15.png";
import Img16 from "src/assets/images/dashaboardCards/preview_image16.png";
import Img17 from "src/assets/images/dashaboardCards/preview_image17.png";
import Img18 from "src/assets/images/dashaboardCards/preview_image18.png";
import Img19 from "src/assets/images/dashaboardCards/preview_image19.png";
import Img20 from "src/assets/images/dashaboardCards/preview_image20.png";
import Img21 from "src/assets/images/dashaboardCards/preview_image21.png";
import Img22 from "src/assets/images/dashaboardCards/preview_image22.png";
import Img23 from "src/assets/images/dashaboardCards/preview_image23.png";
import Img24 from "src/assets/images/dashaboardCards/preview_image24.png";
import Img25 from "src/assets/images/dashaboardCards/preview_image25.png";
import Img26 from "src/assets/images/dashaboardCards/preview_image26.png";
import Img27 from "src/assets/images/dashaboardCards/preview_image27.png";
import Img28 from "src/assets/images/dashaboardCards/preview_image28.png";
import Img29 from "src/assets/images/dashaboardCards/preview_image29.png";
import imageSelectionIcon from "src/assets/images/checkmark-square.png";
import Modal from "src/components/custom/Modal/Modal";
//import styles from "./PreviewImageSelector.module.scss";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "src/components";
import { CustomImage } from "./CustomImage/CustomImage";
import { DropImageBox } from "./CustomImage/DropImageBox";
import { PixelCrop } from "react-image-crop";

const useStyles = makeStyles({
  title: {
    color: "#123553",
    marginBottom: "12px"
  },
  imageCount: {
    color: "#7C7C7C",
    fontSize: "14px",
    paddingBottom: "8px",
    lineHeight: "32px"
  },
  customImageBox: {
    height: "100%"
  },
  tabContainer: {
    height: "400px"
  },
  imagesWrap: {
    background: "#EEEDEB",
    gap: "8px"
  },
  customImgGrid: {
    height: "120px",
    width: "244px",
    borderRadius: "12px",
    "& img": {
      borderRadius: "12px"
    }
  },
  imagesBox: {
    flexWrap: "nowrap",
    gap: "16px",
    padding: "16px 0px"
  },
  chooseImageBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
    gap: "12px",
    width: ({ previewType }: any) =>
      previewType === "project" ? "400px" : previewType === "mini-project" ? "240px" : "164px",
    height: ({ previewType }: any) =>
      previewType === "project" ? "200px" : previewType === "mini-project" ? "120px" : "204px",
    background: "#f5f7f9",
    border: "1px dashed #415f80",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    "&::after": {
      content: '"Choose Image"',
      display: "block",
      position: "absolute",
      top: "calc(50% - 14px)",
      left: "calc(50% - 58px)",
      padding: "4px 12px",
      width: "116px",
      height: "28px",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      color: "#ffffff",
      backgroundColor: "#003656",
      boxShadow:
        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    "&:hover": {
      "&::after": {
        backgroundColor: "rgb(0, 37, 60)",
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
      }
    },
    "&$chooseImageBoxSelected": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      "&::after": {
        display: "none"
      },
      "&:hover": {
        "&::after": {
          display: "block",
          content: '"CHANGE"',
          backgroundColor: "rgba(0, 54, 86, 0.92)",
          border: "2px solid #f1957d",
          boxShadow: "none",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "-1px",
          left: "-1px",
          paddingTop: "25%",
          fontSize: "20px",
          fontWeight: 500
        }
      }
    }
  },
  chooseImageBoxSelected: {}, // Extra class for specificity
  chooseImageBoxMargin: {
    marginTop: "14px"
  },
  imageBox: {
    width: ({ previewType }: any) =>
      previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
    height: ({ previewType }: any) =>
      previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    "&:hover::after": {
      content: '"CHOOSE"',
      position: "absolute",
      width: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
      height: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
      left: "0",
      top: "0",
      backgroundColor: "rgba(0, 54, 86, 0.64)",
      borderRadius: "4px",
      paddingTop: "50%",
      color: "white"
    }
  },
  selectedImage: {
    borderColor: "#f1957d",
    pointerEvents: "none",
    "&::after": {
      content: `url(${imageSelectionIcon})`,
      paddingTop: "50%",
      position: "absolute",
      backgroundColor: "rgba(0, 54, 86, 0.32)",
      width: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
      height: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
      borderRadius: "4px",
      border: "1px solid #f1957d"
    }
  }
});

const defaultImages = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29
];

type Props = {
  onChange?: $TSFixMe;
  prefix?: string;
  defaultImage?: string;
  noText?: boolean;
  images?: any[];
  isNewTheme?: boolean;

  previewType?: "project" | "mini-project" | "default";
};

const PreviewImageSelector = ({
  images = defaultImages,
  onChange,
  prefix = "Project",
  defaultImage,
  noText = false,
  previewType = "default"
}: Props) => {
  const styles = useStyles({ previewType });
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("default");
  const [selectedImage, setSelectedImage] = useState<string>();
  const [imageSubmitted, setImageSubmitted] = useState<string>();

  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const getCroppedImage = () => {
    if (!imgRef.current || !completedCrop) {
      throw new Error("Image or crop not defined");
    }
    const image = imgRef.current;
    const crop = completedCrop;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Unable to get canvas context");
    }
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg");
  };

  const [imgSrc, setImgSrc] = useState<string>("");
  const onFilesUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setValue("default");
    setImgSrc("");
  };

  const handleSubmit = () => {
    if (value === "default") {
      onChange(selectedImage);
      setImageSubmitted(selectedImage);
    } else {
      const base64Image = getCroppedImage();
      onChange(base64Image);
      setImageSubmitted(base64Image);
    }
    handleClose();
  };

  return (
    <Box display="flex" flexDirection="column">
      {!noText && (
        <>
          <Typography variant="h6" className={styles.title}>
            {prefix} Preview Image
          </Typography>
          <Typography variant="body2" style={{ color: "#396083" }}>
            Choose an image for this {prefix.toLowerCase()}. It will appear as a preview on the
            dashboard and {prefix.toLowerCase()}s list.
          </Typography>
        </>
      )}
      <Button
        id="preview-image-selector-button"
        className={clsx(styles.chooseImageBox, {
          [styles.chooseImageBoxSelected]: imageSubmitted || defaultImage,
          [styles.chooseImageBoxMargin]: !noText
        })}
        onClick={() => setOpen(true)}
        style={
          imageSubmitted || defaultImage
            ? {
                backgroundImage: `url(${
                  defaultImage ? `data:image/jpeg;base64,${defaultImage}` : imageSubmitted
                })`
              }
            : {}
        }
      />
      {open ? (
        <Modal
          open={open}
          size="md"
          title="Choose Preview Image"
          submitLabel="Choose"
          background="#EEEDEB"
          PaperProps={{
            style: {
              background: "#EEEDEB"
            }
          }}
          isSubmitDisabled={
            value === "default" ? !selectedImage : !imgRef.current || !completedCrop
          }
          onClose={handleClose}
          onSubmit={handleSubmit}>
          <Grid container direction="column" className={styles.imagesWrap}>
            <Tabs value={value} onChange={setValue}>
              <Tab label="Default" value={"default"} />
              <Tab label="Custom" value={"custom"} />
            </Tabs>

            <Grid item className={styles.tabContainer}>
              {value === "default" && (
                <Grid item>
                  <Grid container spacing={2}>
                    {images.map((image: $TSFixMe, index: number) => (
                      <Grid key={index} item xs style={{ flexGrow: 0 }}>
                        <Button
                          onClick={() =>
                            setSelectedImage(selectedImage === image ? undefined : image)
                          }
                          className={`${styles.imageBox} ${
                            selectedImage === image ? styles.selectedImage : ""
                          }`}
                          style={{ backgroundImage: `url(${image})` }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {value === "custom" && (
                <Grid container className={styles.customImageBox}>
                  {defaultImage && !imgSrc ? (
                    <Grid container direction="column">
                      <Grid container direction="row" className={styles.imagesBox}>
                        <Grid item className={styles.customImgGrid}>
                          <DropImageBox onFilesUpload={onFilesUpload} />
                        </Grid>
                        <Grid item className={styles.imageBox}>
                          <img
                            src={`data:image/jpeg;base64,${defaultImage}`}
                            height="100%"
                            width="100%"
                            alt="project"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <CustomImage
                      setCompletedCrop={setCompletedCrop}
                      imgRef={imgRef}
                      imgSrc={imgSrc}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PreviewImageSelector;
