import { useCallback, useEffect, useState } from "react";

// Packages
import { ColumnDefResolved } from "@tanstack/react-table";
import { every, map, max, truncate } from "lodash";

// Utils
import { getTextWidth } from "src/utils/helpers";

// Constants
import { CommonConstants } from "../utils/Table.constants";

// Types
import { TData } from "../Table.types";

type Props = {
  columns: ColumnDefResolved<TData, any>[];
  resetColumns: (newColumns: ColumnDefResolved<TData, any>[]) => void;
};

const useActions = (props: Props) => {
  const { columns, resetColumns } = props || {};

  // Resize columns - STARTS >>
  const [isAllColumnsResized, setIsAllColumnsResized] = useState(false);

  const checkIsAllColumnsResized = useCallback(() => {
    const elements = document.querySelectorAll(`.${CommonConstants.columnClassName}`);
    return every(Array.from(elements), (element) => element.scrollWidth <= element.clientWidth);
  }, []);

  const updateIsAllColumnsResizedState = useCallback(() => {
    const resized = checkIsAllColumnsResized();
    if (resized !== isAllColumnsResized) {
      setIsAllColumnsResized(resized);
    }

    // Continue the animation frame loop
    requestAnimationFrame(updateIsAllColumnsResizedState);
  }, [checkIsAllColumnsResized, isAllColumnsResized]);

  useEffect(() => {
    // Start checking columns on initial render
    const requestAnimationFrameId = requestAnimationFrame(updateIsAllColumnsResizedState);
    // Cleanup animation frames if component unmounts
    return () => cancelAnimationFrame(requestAnimationFrameId);
  }, [updateIsAllColumnsResizedState]);

  const resizeColumns = () => {
    // Calculate widths and set size for each column
    const newColumns = map(columns, (column) => {
      const headerText = truncate(column?.header as string, {
        length: 200, // Set the max length to 200 characters
        omission: "" // Remove the default "..." that lodash adds at the end
      });

      const width = getTextWidth(headerText) + 100; // Width calculation using canvas-context

      return {
        ...column,
        size: max([width, 150]) // Set the size
      };
    });

    resetColumns(newColumns);
  };
  // << ENDS - Resize columns

  return { resizeColumns, isAllColumnsResized };
};

export default useActions;
