/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { DataFileDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { FeatureStatsEntityDto } from '../Models';
// @ts-ignore
import type { SignedUrlResult } from '../Models';
// @ts-ignore
import type { UpdateDataFileDto } from '../Models';
/**
 * DataFileControllerApi - axios parameter creator
 * @export
 */
export const DataFileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataFiles: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDataFiles', 'id', id)
            const localVarPath = `/v2/data-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutputNotebook1: async (fileName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('downloadOutputNotebook1', 'fileName', fileName)
            const localVarPath = `/v2/data-files/download-output-notebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['file_name'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDataFile: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-files/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStats2: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findStats2', 'id', id)
            const localVarPath = `/v2/data-files/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStats1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateStats1', 'id', id)
            const localVarPath = `/v2/data-files/{id}/stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GetDataFilesParentTypeEnum} [parentType] 
         * @param {GetDataFilesSubTypeEnum} [subType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataFiles: async (id?: string, parentType?: GetDataFilesParentTypeEnum, subType?: GetDataFilesSubTypeEnum, parentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (parentType !== undefined) {
                localVarQueryParameter['parentType'] = parentType;
            }

            if (subType !== undefined) {
                localVarQueryParameter['subType'] = subType;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GetDataFilesDownloadLinksParentTypeEnum} [parentType] 
         * @param {GetDataFilesDownloadLinksSubTypeEnum} [subType] 
         * @param {string} [parentId] 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataFilesDownloadLinks: async (id?: string, parentType?: GetDataFilesDownloadLinksParentTypeEnum, subType?: GetDataFilesDownloadLinksSubTypeEnum, parentId?: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/data-files/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (parentType !== undefined) {
                localVarQueryParameter['parentType'] = parentType;
            }

            if (subType !== undefined) {
                localVarQueryParameter['subType'] = subType;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {ProcessDataFile1ParentTypeEnum} parentType 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDataFile1: async (file: File, parentType: ProcessDataFile1ParentTypeEnum, parentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('processDataFile1', 'file', file)
            // verify required parameter 'parentType' is not null or undefined
            assertParamExists('processDataFile1', 'parentType', parentType)
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('processDataFile1', 'parentId', parentId)
            const localVarPath = `/v2/data-files/{parentType}/{parentId}`
                .replace(`{${"parentType"}}`, encodeURIComponent(String(parentType)))
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} files 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMultipleDataFiles: async (files: Array<File>, parentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('processMultipleDataFiles', 'files', files)
            // verify required parameter 'parentId' is not null or undefined
            assertParamExists('processMultipleDataFiles', 'parentId', parentId)
            const localVarPath = `/v2/data-files/multiple/{parentId}`
                .replace(`{${"parentId"}}`, encodeURIComponent(String(parentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                localVarQueryParameter['files'] = files;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDataFileDto} updateDataFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataFile: async (updateDataFileDto: UpdateDataFileDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDataFileDto' is not null or undefined
            assertParamExists('updateDataFile', 'updateDataFileDto', updateDataFileDto)
            const localVarPath = `/v2/data-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDataFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNotebookFile: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadNotebookFile', 'file', file)
            const localVarPath = `/v2/data-files/notebook-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNotebookFileForProject: async (file: File, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadNotebookFileForProject', 'file', file)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadNotebookFileForProject', 'projectId', projectId)
            const localVarPath = `/v2/data-files/notebook-files/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNotebooks: async (file: File, templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadNotebooks', 'file', file)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('uploadNotebooks', 'templateId', templateId)
            const localVarPath = `/v2/data-files/notebooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {string} moduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStreamlitModule: async (file: File, moduleName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadStreamlitModule', 'file', file)
            // verify required parameter 'moduleName' is not null or undefined
            assertParamExists('uploadStreamlitModule', 'moduleName', moduleName)
            const localVarPath = `/v2/data-files/streamlit-module/{moduleName}`
                .replace(`{${"moduleName"}}`, encodeURIComponent(String(moduleName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataFileControllerApi - functional programming interface
 * @export
 */
export const DataFileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataFileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataFiles(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataFiles(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.deleteDataFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOutputNotebook1(fileName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOutputNotebook1(fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.downloadOutputNotebook1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDataFile(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportDataFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.exportDataFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findStats2(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureStatsEntityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findStats2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.findStats2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateStats1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateStats1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.generateStats1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GetDataFilesParentTypeEnum} [parentType] 
         * @param {GetDataFilesSubTypeEnum} [subType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataFiles(id?: string, parentType?: GetDataFilesParentTypeEnum, subType?: GetDataFilesSubTypeEnum, parentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataFiles(id, parentType, subType, parentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.getDataFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GetDataFilesDownloadLinksParentTypeEnum} [parentType] 
         * @param {GetDataFilesDownloadLinksSubTypeEnum} [subType] 
         * @param {string} [parentId] 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataFilesDownloadLinks(id?: string, parentType?: GetDataFilesDownloadLinksParentTypeEnum, subType?: GetDataFilesDownloadLinksSubTypeEnum, parentId?: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataFilesDownloadLinks(id, parentType, subType, parentId, scenarioId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.getDataFilesDownloadLinks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {ProcessDataFile1ParentTypeEnum} parentType 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processDataFile1(file: File, parentType: ProcessDataFile1ParentTypeEnum, parentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processDataFile1(file, parentType, parentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.processDataFile1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<File>} files 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMultipleDataFiles(files: Array<File>, parentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processMultipleDataFiles(files, parentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.processMultipleDataFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDataFileDto} updateDataFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataFile(updateDataFileDto: UpdateDataFileDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataFile(updateDataFileDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.updateDataFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNotebookFile(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNotebookFile(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.uploadNotebookFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNotebookFileForProject(file: File, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNotebookFileForProject(file, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.uploadNotebookFileForProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNotebooks(file: File, templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadNotebooks(file, templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.uploadNotebooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {File} file 
         * @param {string} moduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStreamlitModule(file: File, moduleName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStreamlitModule(file, moduleName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DataFileControllerApi.uploadStreamlitModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DataFileControllerApi - factory interface
 * @export
 */
export const DataFileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataFileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataFiles(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDataFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutputNotebook1(fileName: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadOutputNotebook1(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDataFile(id?: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.exportDataFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findStats2(id: string, options?: any): AxiosPromise<Array<FeatureStatsEntityDto>> {
            return localVarFp.findStats2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStats1(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.generateStats1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GetDataFilesParentTypeEnum} [parentType] 
         * @param {GetDataFilesSubTypeEnum} [subType] 
         * @param {string} [parentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataFiles(id?: string, parentType?: GetDataFilesParentTypeEnum, subType?: GetDataFilesSubTypeEnum, parentId?: string, options?: any): AxiosPromise<Array<DataFileDto>> {
            return localVarFp.getDataFiles(id, parentType, subType, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GetDataFilesDownloadLinksParentTypeEnum} [parentType] 
         * @param {GetDataFilesDownloadLinksSubTypeEnum} [subType] 
         * @param {string} [parentId] 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataFilesDownloadLinks(id?: string, parentType?: GetDataFilesDownloadLinksParentTypeEnum, subType?: GetDataFilesDownloadLinksSubTypeEnum, parentId?: string, scenarioId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDataFilesDownloadLinks(id, parentType, subType, parentId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {ProcessDataFile1ParentTypeEnum} parentType 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDataFile1(file: File, parentType: ProcessDataFile1ParentTypeEnum, parentId: string, options?: any): AxiosPromise<DataFileDto> {
            return localVarFp.processDataFile1(file, parentType, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<File>} files 
         * @param {string} parentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMultipleDataFiles(files: Array<File>, parentId: string, options?: any): AxiosPromise<DataFileDto> {
            return localVarFp.processMultipleDataFiles(files, parentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDataFileDto} updateDataFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataFile(updateDataFileDto: UpdateDataFileDto, options?: any): AxiosPromise<DataFileDto> {
            return localVarFp.updateDataFile(updateDataFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNotebookFile(file: File, options?: any): AxiosPromise<any> {
            return localVarFp.uploadNotebookFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNotebookFileForProject(file: File, projectId: string, options?: any): AxiosPromise<any> {
            return localVarFp.uploadNotebookFileForProject(file, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNotebooks(file: File, templateId: string, options?: any): AxiosPromise<any> {
            return localVarFp.uploadNotebooks(file, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {string} moduleName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStreamlitModule(file: File, moduleName: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadStreamlitModule(file, moduleName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataFileControllerApi - interface
 * @export
 * @interface DataFileControllerApi
 */
export interface DataFileControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    deleteDataFiles(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    downloadOutputNotebook1(fileName: string, options?: RawAxiosRequestConfig): AxiosPromise<File>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    exportDataFile(id?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    findStats2(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureStatsEntityDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    generateStats1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [id] 
     * @param {GetDataFilesParentTypeEnum} [parentType] 
     * @param {GetDataFilesSubTypeEnum} [subType] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    getDataFiles(id?: string, parentType?: GetDataFilesParentTypeEnum, subType?: GetDataFilesSubTypeEnum, parentId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DataFileDto>>;

    /**
     * 
     * @param {string} [id] 
     * @param {GetDataFilesDownloadLinksParentTypeEnum} [parentType] 
     * @param {GetDataFilesDownloadLinksSubTypeEnum} [subType] 
     * @param {string} [parentId] 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    getDataFilesDownloadLinks(id?: string, parentType?: GetDataFilesDownloadLinksParentTypeEnum, subType?: GetDataFilesDownloadLinksSubTypeEnum, parentId?: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {File} file 
     * @param {ProcessDataFile1ParentTypeEnum} parentType 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    processDataFile1(file: File, parentType: ProcessDataFile1ParentTypeEnum, parentId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataFileDto>;

    /**
     * 
     * @param {Array<File>} files 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    processMultipleDataFiles(files: Array<File>, parentId: string, options?: RawAxiosRequestConfig): AxiosPromise<DataFileDto>;

    /**
     * 
     * @param {UpdateDataFileDto} updateDataFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    updateDataFile(updateDataFileDto: UpdateDataFileDto, options?: RawAxiosRequestConfig): AxiosPromise<DataFileDto>;

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    uploadNotebookFile(file: File, options?: RawAxiosRequestConfig): AxiosPromise<any>;

    /**
     * 
     * @param {File} file 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    uploadNotebookFileForProject(file: File, projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<any>;

    /**
     * 
     * @param {File} file 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    uploadNotebooks(file: File, templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<any>;

    /**
     * 
     * @param {File} file 
     * @param {string} moduleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApiInterface
     */
    uploadStreamlitModule(file: File, moduleName: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * DataFileControllerApi - object-oriented interface
 * @export
 * @class DataFileControllerApi
 * @extends {BaseAPI}
 */
export class DataFileControllerApi extends BaseAPI implements DataFileControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public deleteDataFiles(id: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).deleteDataFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public downloadOutputNotebook1(fileName: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).downloadOutputNotebook1(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public exportDataFile(id?: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).exportDataFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public findStats2(id: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).findStats2(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public generateStats1(id: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).generateStats1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {GetDataFilesParentTypeEnum} [parentType] 
     * @param {GetDataFilesSubTypeEnum} [subType] 
     * @param {string} [parentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public getDataFiles(id?: string, parentType?: GetDataFilesParentTypeEnum, subType?: GetDataFilesSubTypeEnum, parentId?: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).getDataFiles(id, parentType, subType, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {GetDataFilesDownloadLinksParentTypeEnum} [parentType] 
     * @param {GetDataFilesDownloadLinksSubTypeEnum} [subType] 
     * @param {string} [parentId] 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public getDataFilesDownloadLinks(id?: string, parentType?: GetDataFilesDownloadLinksParentTypeEnum, subType?: GetDataFilesDownloadLinksSubTypeEnum, parentId?: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).getDataFilesDownloadLinks(id, parentType, subType, parentId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {ProcessDataFile1ParentTypeEnum} parentType 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public processDataFile1(file: File, parentType: ProcessDataFile1ParentTypeEnum, parentId: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).processDataFile1(file, parentType, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<File>} files 
     * @param {string} parentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public processMultipleDataFiles(files: Array<File>, parentId: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).processMultipleDataFiles(files, parentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDataFileDto} updateDataFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public updateDataFile(updateDataFileDto: UpdateDataFileDto, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).updateDataFile(updateDataFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public uploadNotebookFile(file: File, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).uploadNotebookFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public uploadNotebookFileForProject(file: File, projectId: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).uploadNotebookFileForProject(file, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public uploadNotebooks(file: File, templateId: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).uploadNotebooks(file, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {string} moduleName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataFileControllerApi
     */
    public uploadStreamlitModule(file: File, moduleName: string, options?: RawAxiosRequestConfig) {
        return DataFileControllerApiFp(this.configuration).uploadStreamlitModule(file, moduleName, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetDataFilesParentTypeEnum = {
    Entity: 'ENTITY',
    ScenarioStep: 'SCENARIO_STEP'
} as const;
export type GetDataFilesParentTypeEnum = typeof GetDataFilesParentTypeEnum[keyof typeof GetDataFilesParentTypeEnum];
/**
 * @export
 */
export const GetDataFilesSubTypeEnum = {
    User: 'USER',
    Merged: 'MERGED',
    Ingress: 'INGRESS'
} as const;
export type GetDataFilesSubTypeEnum = typeof GetDataFilesSubTypeEnum[keyof typeof GetDataFilesSubTypeEnum];
/**
 * @export
 */
export const GetDataFilesDownloadLinksParentTypeEnum = {
    Entity: 'ENTITY',
    ScenarioStep: 'SCENARIO_STEP'
} as const;
export type GetDataFilesDownloadLinksParentTypeEnum = typeof GetDataFilesDownloadLinksParentTypeEnum[keyof typeof GetDataFilesDownloadLinksParentTypeEnum];
/**
 * @export
 */
export const GetDataFilesDownloadLinksSubTypeEnum = {
    User: 'USER',
    Merged: 'MERGED',
    Ingress: 'INGRESS'
} as const;
export type GetDataFilesDownloadLinksSubTypeEnum = typeof GetDataFilesDownloadLinksSubTypeEnum[keyof typeof GetDataFilesDownloadLinksSubTypeEnum];
/**
 * @export
 */
export const ProcessDataFile1ParentTypeEnum = {
    Entity: 'ENTITY',
    ScenarioStep: 'SCENARIO_STEP'
} as const;
export type ProcessDataFile1ParentTypeEnum = typeof ProcessDataFile1ParentTypeEnum[keyof typeof ProcessDataFile1ParentTypeEnum];
