import React, { useEffect, useMemo } from "react";

// Packages
import { Column, ColumnDefTemplate, HeaderContext, Table } from "@tanstack/react-table";
import { delay, filter, map } from "lodash";

// Contexts
import { TableContext } from "./TableContext";

// Types
import { StoreUserPreferences, TData } from "../Table.types";

type Props = {
  table: Table<TData>;
  columnOrder: string[];
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  resizeColumns: () => void;
  storeUserPreferences?: ({}: StoreUserPreferences) => void;
  children: React.ReactNode;
};

const TableContextProvider = (props: Props) => {
  const { table, columnOrder, toggleColumns, resizeColumns, storeUserPreferences, children } =
    props || {};
  const hideColumn = (columnName: string) => {
    toggleColumns(
      // @ts-ignore
      map(
        filter(
          table?.getVisibleFlatColumns(),
          (column: Column<any, unknown>) => column?.columnDef?.header !== columnName
        ),
        (column: Column<any, unknown>) => column?.columnDef?.header
      )
    );
  };

  useEffect(() => {
    table?.setColumnOrder(columnOrder);
  }, [columnOrder]);

  useEffect(() => {
    const _ = () => {
      storeUserPreferences?.({
        columnVisibility: table?.getState()?.columnVisibility || {}
      });
    };

    delay(_, 1000);
  }, [table?.getState()?.columnVisibility]);

  const value = useMemo(
    () => ({
      table,

      hideColumn,
      resizeColumns,

      storeUserPreferences
    }),
    [table, hideColumn, resizeColumns, storeUserPreferences]
  );

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export default TableContextProvider;
