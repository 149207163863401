import React, { useMemo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";

import AIGuideResponse from "src/pages/Projects/AIGuide/common/AIGuideResponse";

import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import {
  AIChatRequestDtoOutputTypeEnum,
  InputIdDtoTypeEnum,
  OutputEntity,
  OutputEntityDto
} from "openapi/Models";
import { STOPPED_QUERY_ID } from "../GenerateCodeBar/GenerateCodeQueryInput";
import { checkCanDeleteAIGuideMessage, getAllDescendants } from "../askai.helper";

const useStyles = makeStyles({
  responsesWrap: {
    gap: "8px",
    flexWrap: "nowrap",
    overflowY: "auto"
  },
  promptSuggestions: {
    flexWrap: "nowrap"
  }
});

const AskAIResponsesNewFlow = ({
  responses,
  isGenerating,
  targetId,
  setIsRetryInProgress,
  autoGenerateCode
}: {
  responses: AIChatResponseDto[];
  hoverId: any;
  setHoverId: (hoverId: any) => void;
  isGenerating: boolean;
  targetId: string | undefined;
  setIsRetryInProgress: (isRetryInProgress: boolean) => void;
  autoGenerateCode: (text: string) => void;
}) => {
  const classes = useStyles();

  const nonTextRecipeResponses = useMemo(
    () =>
      _.filter(
        responses,
        (response: AIChatResponseDto) =>
          !!response.transformId &&
          (response.outputType === AIChatRequestDtoOutputTypeEnum.Chart ||
            response.outputType === AIChatRequestDtoOutputTypeEnum.Dataset ||
            response.outputType === AIChatRequestDtoOutputTypeEnum.Model)
      ),
    [responses]
  );

  const existingOutputNames = useMemo(
    () =>
      _.flatMap(nonTextRecipeResponses, (response) => {
        return _.map(
          response.outputEntityList || response.outputEntityResponseList,
          (entity: OutputEntity | OutputEntityDto) => entity.name!
        );
      }),
    [nonTextRecipeResponses]
  );

  const descendantsMap = useMemo(() => {
    return _.mapValues(_.keyBy(responses, "askAIMessageId"), (message) => {
      const descendants = getAllDescendants(responses, message);
      return _.map(descendants, "askAIMessageId") as string[];
    });
  }, [responses]);

  const canDeleteMessagesMap = useMemo(() => {
    return _.mapValues(_.keyBy(responses, "askAIMessageId"), (message) =>
      checkCanDeleteAIGuideMessage(responses, message)
    );
  }, [responses]);

  return responses?.length !== 0 ? (
    <Grid container direction="column" className={classes.responsesWrap}>
      {responses.map((response: AIChatResponseDto, index) => {
        const { askAIMessageId, transformId, targetInputs } = response;
        const isLastMessage = index === responses?.length - 1;
        return (
          <Grid container key={index}>
            <AIGuideResponse
              message={response}
              autoGenerateCode={autoGenerateCode}
              canRetry={
                isLastMessage &&
                !isGenerating &&
                askAIMessageId !== STOPPED_QUERY_ID &&
                !transformId
              }
              hideFeedback={askAIMessageId === STOPPED_QUERY_ID}
              targetId={targetId}
              existingOutputNames={existingOutputNames}
              hasMarkDown={false}
              descendants={descendantsMap[askAIMessageId!] || []}
              inputIds={_.map(targetInputs || [], (targetInput) => {
                const { intermediateDataset, askAIInputId, entityName } = targetInput;
                if (intermediateDataset) {
                  return {
                    id: intermediateDataset.chatId,
                    name: intermediateDataset.entityName,
                    type: InputIdDtoTypeEnum.AskaiMessageId
                  };
                }
                return {
                  id: askAIInputId,
                  name: entityName,
                  type: InputIdDtoTypeEnum.InputId
                };
              })}
              canDelete={canDeleteMessagesMap[askAIMessageId!]}
              isGenerating={isGenerating}
              showActions
              setIsRetryInProgress={setIsRetryInProgress}
            />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <React.Fragment />
  );
};

export default React.memo(AskAIResponsesNewFlow);
