import React, { useState } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import AccessTimeIcon from "@material-ui/icons/AccessTime";

import { PencilUnderlined } from "src/icons/PencilUnderlined";
import { Delete } from "src/icons/Delete";

import { dateFormat } from "src/utils/dateFormat";

import { Table } from "src/components";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";

import { getJobStatusTemplate, getJobRunStatusBadge } from "../../utils/Jobs.helpers";

import JobRunLogsDrawer from "../JobRunLogs/JobRunLogsDrawer";

import { JobRunStatuses, JobRunTriggerTypes } from "../../utils/Jobs.constants";

import { useStyles } from "./JobsTable.styles";
import { WebPaths } from "src/routing/routes";
import { ProjectRunEntryDtoStatusEnum } from "openapi/Models";
import { includes } from "lodash";

interface IData {
  jobRunId: string;
  jobId: string;
  name: string;
}

const JobsTable = (props: $TSFixMe) => {
  const {
    projectId,
    jobs,
    onViewOutputOpen,
    onJobRunParametersViewOpen,
    onEditJob,
    onDeleteJob,
    prefetchJobQueries,
    hideJobRunCanvas = false,
    hideJobRunParameters = false
  } = props || {};

  const classes = useStyles();
  const [logsData, setLogsData] = useState<IData | null>(null);
  const [isJobRunning, setIsJobRunning] = useState(false);
  const navigate = useNavigate();

  const handleLogsClick = (jobId: string, jobRunId: string, name: string, status: string) => {
    setLogsData({ jobId, jobRunId, name });

    setIsJobRunning(
      includes(
        [
          ProjectRunEntryDtoStatusEnum.ComputeVariables,
          ProjectRunEntryDtoStatusEnum.Created,
          ProjectRunEntryDtoStatusEnum.EntityLoading,
          ProjectRunEntryDtoStatusEnum.RecipeRunning,
          ProjectRunEntryDtoStatusEnum.Started
        ],
        status
      )
    );
  };

  const columns = [
    {
      id: "Job Name",
      accessor: "name",
      Header: "Job Name",
      maxWidth: 180,
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { id, name } = row.original?.dto || {};

        return (
          <LinkCellWithMenu
            linkText={name}
            onClick={() => {
              navigate(
                generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
                  projectId,
                  jobId: id
                })
              );
              prefetchJobQueries({ jobId: id });
            }}
            menuButtons={[
              {
                label: "EDIT",
                icon: <PencilUnderlined />,
                action: () => projectId && id && onEditJob(id)
              },
              {
                label: "RUN HISTORY",
                icon: <AccessTimeIcon />,
                action: () =>
                  navigate(
                    generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, {
                      projectId,
                      jobId: id
                    })
                  )
              },
              {
                label: "DELETE",
                icon: <Delete />,
                action: () => id && onDeleteJob(id)
              }
            ]}
          />
        );
      }
    },
    {
      id: "Status",
      accessor: "status",
      Header: "Status",
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { status } = row.original?.dto || {};

        return <>{status ? getJobStatusTemplate(status) : ""}</>;
      }
    },
    {
      id: "Last Run by",
      accessor: "lastRunBy",
      Header: "Last Run by",
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { trigger, updater, creator } = row.original?.lastRunEntry || {};

        return trigger === JobRunTriggerTypes.Scheduler ? "Scheduler" : updater || creator || "N/A";
      }
    },
    {
      id: "Last Run",
      accessor: "lastRun",
      Header: "Last Run",
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { updated, created } = row.original?.lastRunEntry || {};

        return <span>{(dateFormat(updated) || dateFormat(created)) ?? ""}</span>;
      }
    },

    {
      id: "Last 5 Run Status",
      accessor: "lastRunEntries",
      Header: "Last 5 Run Status",
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { id: jobId } = row?.original?.dto || {};
        const { lastRunEntries } = row?.original || {};

        return (lastRunEntries || [])?.length === 0 ? (
          "N/A"
        ) : (
          <Grid container>
            {lastRunEntries
              ?.slice(0, 5)
              ?.sort(
                (a: $TSFixMe, b: $TSFixMe) => (a.updated || a.created) - (b.updated || b.created)
              )
              ?.map((eachLastEntry: $TSFixMe, index: number) => {
                // Need the below to troubleshoot for the dates being sent to the badges.
                // return (
                //   <>
                //     <br />
                //     {(dateFormat(eachLastEntry?.updated) || dateFormat(eachLastEntry?.created)) ??
                //       ""}
                //   </>
                // );

                const tooltipMoreInfo = !!eachLastEntry?.endTime ? (
                  <>
                    <br />
                    On: {dateFormat(eachLastEntry?.endTime)}
                  </>
                ) : null;

                const jobRunStatusBadge = getJobRunStatusBadge(
                  eachLastEntry?.status,
                  tooltipMoreInfo
                );

                return !!jobRunStatusBadge && !!projectId && !!jobId && !!eachLastEntry?.id ? (
                  <IconButton
                    size="small"
                    key={`lastRunStatus_${index}`}
                    className={classes.jobRunStatusBadgeButton}
                    onClick={() =>
                      navigate(
                        generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
                          projectId,
                          jobId,
                          jobRunId: eachLastEntry?.id
                        })
                      )
                    }>
                    {jobRunStatusBadge}
                  </IconButton>
                ) : (
                  <></>
                );
              })}
          </Grid>
        );
      }
    },
    {
      id: "Last Run Output",
      accessor: "lastRunOutput",
      Header: "Last Run Output",
      isSortable: false,
      Cell: ({ row }: $TSFixMe) => {
        const { id, status } = row.original?.lastRunEntry || {};

        return !!id ? (
          [
            JobRunStatuses.Success,
            JobRunStatuses.SuccessWithWarn,
            JobRunStatuses.Failure,
            JobRunStatuses.TimedOut,
            JobRunStatuses.RecipeTimedOut
          ].includes(status) ? (
            <button
              className={classes.link}
              onClick={() => onViewOutputOpen(row.original?.dto, row.original?.lastRunEntry)}>
              Output
            </button>
          ) : (
            <Tooltip title="The run is currently in progress. Please check the output once the execution is complete.">
              <span className={classes.linkDisabled}>View</span>
            </Tooltip>
          )
        ) : (
          "N/A"
        );
      }
    },
    {
      id: "Last Run Log",
      accessor: "lastRunLog",
      Header: "Last Run Log",
      isSortable: false,
      Cell: ({ row }: $TSFixMe) => {
        const { id, status } = row.original?.lastRunEntry || {};

        return !!id ? (
          ![JobRunStatuses.Created].includes(status) ? (
            <button
              className={classes.link}
              onClick={() =>
                handleLogsClick(row.original?.dto?.id, id, row.original?.dto?.name, status)
              }>
              View
            </button>
          ) : (
            <Tooltip title="Kindly wait for the run to start before checking the logs.">
              <span className={classes.linkDisabled}>View</span>
            </Tooltip>
          )
        ) : (
          "N/A"
        );
      }
    },
    ...(!hideJobRunCanvas
      ? [
          {
            id: "Last Run Canvas",
            accessor: "lastRunCanvas",
            Header: "Last Run Canvas",
            isSortable: false,
            Cell: ({ row }: $TSFixMe) => {
              const { id: jobId } = row?.original?.dto || {};
              const { id: lastJobRunEntryId, status: lastJobRunEntryStatus } =
                row.original?.lastRunEntry || {};

              // eslint-disable-next-line no-extra-boolean-cast
              return !!projectId && !!jobId && !!lastJobRunEntryId ? (
                ![
                  JobRunStatuses.Created,
                  JobRunStatuses.Started,
                  JobRunStatuses.EntityLoading,
                  JobRunStatuses.Running
                ].includes(lastJobRunEntryStatus) ? (
                  <Link
                    className={classes.link}
                    to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
                      projectId,
                      jobId,
                      jobRunId: lastJobRunEntryId
                    })}>
                    <Typography variant="subtitle2">Canvas</Typography>
                  </Link>
                ) : (
                  <Tooltip title="Kindly wait for the run to complete before visiting the canvas.">
                    <span className={classes.linkDisabled}>Canvas</span>
                  </Tooltip>
                )
              ) : (
                "N/A"
              );
            }
          }
        ]
      : []),
    ...(!hideJobRunParameters
      ? [
          {
            id: "Last Run Global Variables",
            accessor: "lastRunParameters",
            Header: "Last Run Global Variables",
            isSortable: false,
            Cell: ({ row }: $TSFixMe) => {
              const { id, status } = row.original?.lastRunEntry || {};

              return !!id ? (
                ![
                  JobRunStatuses.Created,
                  JobRunStatuses.Started,
                  JobRunStatuses.EntityLoading,
                  JobRunStatuses.Running
                ].includes(status) ? (
                  <button
                    className={classes.link}
                    onClick={() => onJobRunParametersViewOpen(row?.original?.lastRunEntry)}>
                    View
                  </button>
                ) : (
                  <Tooltip title="Kindly wait for the run to get completed.">
                    <span className={classes.linkDisabled}>View</span>
                  </Tooltip>
                )
              ) : (
                "N/A"
              );
            }
          }
        ]
      : [])
  ];

  const handleClose = () => {
    setLogsData(null);
  };

  return (
    <>
      <Table
        size="small"
        data={jobs}
        columns={columns}
        isCellSortEnabled
        orderByDefault="Job Name"
      />
      {logsData && (
        <JobRunLogsDrawer
          isJobRunning={isJobRunning}
          name={logsData?.name}
          open={!!logsData}
          projectId={projectId}
          jobId={logsData.jobId}
          jobRunId={logsData?.jobRunId}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default JobsTable;
