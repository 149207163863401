import React, { useCallback, useEffect, useMemo, useState } from "react";

// Packages
import {
  Cell,
  ColumnDefResolved,
  ColumnDefTemplate,
  HeaderContext,
  VisibilityState
} from "@tanstack/react-table";
import _, {
  filter,
  findKey,
  fromPairs,
  includes,
  isEmpty,
  isString,
  map,
  reduce,
  size,
  toLower,
  trim,
  uniq
} from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

// Hooks
import useUserPreferences from "./useUserPreferences";

// Components
import { Table } from "src/components/custom";
import { TableCogIcon } from "src/icons/NewUX";
import { TableConfig } from "./TableData.constants";

// Types
import { ServerSideRenderingProps } from "src/components/custom/Table/Table.types";
import { Badge, Button, makeStyles, Tooltip } from "@material-ui/core";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";

const useStyles = makeStyles(() => ({
  customBadge: {
    "& span": {
      top: "2px",
      right: "3px"
    }
  }
}));

type Row = {
  [key: string]: string | React.ReactNode;
};

type Schema = {
  [key: string]: string;
};

type Props = {
  isJobPath?: boolean;
  userId?: string | null | undefined;
  datasetId?: string | null | undefined;
  datasetName?: string | null | undefined;
  isLoading: boolean;
  data: {
    rows: Row[];
    columns: string[];
  };
  showSettingIconOutside: boolean;
  schema: string[];
  allColumns: string[];
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  serverSideRenderingProps?: ServerSideRenderingProps;
};

const TableData = (props: Props) => {
  const {
    isJobPath,
    userId,
    datasetId,
    datasetName,
    isLoading,
    data: inputData,
    showSettingIconOutside,
    schema,
    allColumns,
    toggleColumns,
    serverSideRenderingProps
  } = props || {};
  const classes = useStyles();
  const getColumnId = useCallback(
    // Replacing ".", "[", "]" with "_".
    (columnVal?: ColumnDefTemplate<HeaderContext<any, unknown>> | undefined) =>
      ("" + columnVal).replace(/[.[\]]/gi, "_"),
    []
  );
  const getPairsOfColumnVisibility = (
    columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  ) => {
    return fromPairs(
      map(allColumns, (columnName: string) => [
        getColumnId(columnName),
        size(columns) === 0
          ? false
          : includes(
              map(
                columns,
                (inputDataColumnName: ColumnDefTemplate<HeaderContext<any, unknown>> | undefined) =>
                  getColumnId(inputDataColumnName)
              ),
              getColumnId(columnName)
            )
      ])
    );
  };

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    getPairsOfColumnVisibility(inputData?.columns)
  );
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectionMessage, setSelectionMessage] = useState("");
  const [togglingColumns, setTogglingColumns] = useState<
    (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  >(inputData?.columns);
  const [tableSettingsAnchorEl, setTableSettingsAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [filterValue, setFilterValue] = useState<any>();

  const schemaMap = useMemo(
    () =>
      reduce(
        schema,
        (acc: Schema, { fieldSchema }: $TSFixMe) => {
          acc[getColumnId(fieldSchema?.fieldName)] =
            // <Box fontSize="small" fontStyle="italic" fontWeight="bold" color="#7c7c7c">
            fieldSchema?.rcDataType;
          // </Box>
          return acc;
        },
        {}
      ),
    [schema]
  );

  const columns = useMemo<ColumnDefResolved<Row, any>[]>(() => {
    const thisColumns = uniq([...(allColumns || []), ...(inputData?.columns || [])]);

    return map(thisColumns, (columnName: string) => ({
      // id is optional when for accessor column is created with an object key accessor.
      accessorKey: getColumnId(columnName),
      header: columnName,
      cell: ({ cell }: { cell: Cell<Row, string> }) => cell?.getValue(),
      meta: schemaMap?.[getColumnId(columnName)],
      minSize: 50,
      sortingFn: "alphanumeric"
    }));
  }, [inputData?.columns, allColumns, schemaMap]);

  const data = useMemo<Row[]>(() => {
    if (isEmpty(inputData?.rows) || isEmpty(inputData?.columns)) {
      return [];
    }

    return reduce(
      inputData?.rows,
      (rowAcc: Row[], eachRow: Row) => {
        rowAcc?.push(
          reduce(
            // @ts-ignore
            eachRow?.cells,
            (cellAcc: Row, cellVal: string, cellIndex: number) => {
              const thisCellVal =
                !!cellVal && isString(cellVal) && includes(["nan"], toLower(trim(cellVal)))
                  ? ""
                  : cellVal;

              cellAcc[getColumnId(inputData?.columns[cellIndex])] = thisCellVal;

              return cellAcc;
            },
            {}
          )
        );

        return rowAcc;
      },
      []
    );
  }, [inputData]);

  const onToggleColumns = (
    columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]
  ) => {
    setTogglingColumns(() => columns);

    if (size(columns) === 0) {
      setColumnVisibility(() => getPairsOfColumnVisibility([]));
    } else {
      if (size(filter(columns, (columnName) => !includes(inputData?.columns, columnName))) > 0) {
        toggleColumns(columns);
      } else {
        setColumnVisibility(() => getPairsOfColumnVisibility(columns));
      }
    }
  };

  const { columnOrder, storeUserPreferences } = useUserPreferences({
    isJobPath,
    userId,
    datasetId,
    inputData,
    columns,
    data,
    togglingColumns,
    setColumnVisibility,
    getColumnId,
    getPairsOfColumnVisibility
  });

  const handleTableSettingsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setTableSettingsAnchorEl(() => event?.currentTarget);
  };
  const handleClearSearch = () => {
    EventBus.publish(EVENTBUS_EVENTS.ClearTableSearch);
    setFilterValue({ value: "" });
  };

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.TableColumnFilterChanged, (payload: any) => {
      setIsFilterApplied(payload.isColumnsFilterApplied);
      if (payload.isColumnsFilterApplied) {
        setSelectionMessage(`Showing ${payload.selectionCount}/${payload.totalColumns} Columns`);
      } else {
        setSelectionMessage("");
      }
    });
    EventBus.subscribe(EVENTBUS_EVENTS.TableSearchChanged, (payload: any) => {
      setFilterValue(payload);
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.TableColumnFilterChanged);
      EventBus.unsubscribe(EVENTBUS_EVENTS.TableSearchChanged);
    };
  }, []);

  const truncatedValue =
    filterValue?.value.length > 50
      ? `${filterValue.value.substring(0, 50)}...`
      : filterValue?.value;

  return isLoading ||
    (isEmpty(columns) && isEmpty(data) && serverSideRenderingProps?.isFetching) ? (
    <Box p={4}>
      <Grid container>
        <CircularProgress color="secondary" style={{ margin: "auto" }} />
      </Grid>
    </Box>
  ) : isEmpty(columns) || isEmpty(data) ? (
    <Box p={4}>
      <Grid container alignItems="center" justifyContent="center">
        <Alert severity="info" style={{ width: "25%", justifyContent: "center" }}>
          No data found!
        </Alert>
      </Grid>
    </Box>
  ) : (
    <>
      {showSettingIconOutside && !isEmpty(inputData?.rows) && !isEmpty(inputData?.columns) && (
        <Box style={{ padding: 8 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <div style={{ gap: "20px", alignItems: "center", display: "flex" }}>
              <Typography variant="subtitle2">{datasetName || ""}</Typography>
              {filterValue && filterValue.value && (
                <span
                  style={{
                    color: "#FF5C00",
                    fontSize: "12px"
                  }}>
                  {`Showing ${filterValue?.size} Search Results for `}
                  <Tooltip title={filterValue?.value.length > 50 ? filterValue.value : ""}>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: filterValue?.value.length > 50 ? "pointer" : "default"
                      }}>
                      {`"${truncatedValue}"`}
                    </span>
                  </Tooltip>
                  {"  "}
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ padding: "0px 9px" }}
                    color="primary"
                    onClick={handleClearSearch}>
                    Clear
                  </Button>
                </span>
              )}
            </div>

            <IconButton
              size="small"
              onClick={handleTableSettingsClick}
              color="primary"
              style={{ padding: 0 }}>
              <Tooltip title={selectionMessage}>
                <Badge
                  variant="dot"
                  color="error"
                  overlap="rectangular"
                  className={classes.customBadge}
                  invisible={!isFilterApplied}>
                  <TableCogIcon width={24} height={24} />
                </Badge>
              </Tooltip>
            </IconButton>
          </Grid>
        </Box>
      )}
      <Table
        columns={columns}
        data={data}
        size="small"
        maxHeight="calc(100vh - 275px)"
        isStickyHeader
        sortBy={findKey(columnVisibility, (column: boolean) => column === true)}
        columnVisibility={columnVisibility}
        columnOrder={columnOrder}
        tableSettingsProps={{
          resizeColumns: true,
          maxColumnsCount: TableConfig.MaxColumnsCount,
          anchorEl: tableSettingsAnchorEl,
          setAnchorEl: setTableSettingsAnchorEl
        }}
        serverSideRenderingProps={serverSideRenderingProps}
        toggleColumns={onToggleColumns}
        storeUserPreferences={storeUserPreferences}
      />
    </>
  );
};

export default TableData;
