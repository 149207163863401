/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ByteString } from './byte-string';
// May contain unused imports in some cases
// @ts-ignore
import type { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import type { FieldOptions } from './field-options';
// May contain unused imports in some cases
// @ts-ignore
import type { FieldOptionsOrBuilder } from './field-options-or-builder';
// May contain unused imports in some cases
// @ts-ignore
import type { Message } from './message';
// May contain unused imports in some cases
// @ts-ignore
import type { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface FieldDescriptorProtoOrBuilder
 */
export interface FieldDescriptorProtoOrBuilder {
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'typeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'type'?: FieldDescriptorProtoOrBuilderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'defaultValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'number'?: number;
    /**
     * 
     * @type {FieldOptions}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'options'?: FieldOptions;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'label'?: FieldDescriptorProtoOrBuilderLabelEnum;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'nameBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'jsonName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'proto3Optional'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'oneofIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'extendee'?: string;
    /**
     * 
     * @type {FieldOptionsOrBuilder}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'optionsOrBuilder'?: FieldOptionsOrBuilder;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'typeNameBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'extendeeBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'defaultValueBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'jsonNameBytes'?: ByteString;
    /**
     * 
     * @type {Message}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'defaultInstanceForType'?: Message;
    /**
     * 
     * @type {string}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {boolean}
     * @memberof FieldDescriptorProtoOrBuilder
     */
    'initialized'?: boolean;
}

export const FieldDescriptorProtoOrBuilderTypeEnum = {
    Double: 'TYPE_DOUBLE',
    Float: 'TYPE_FLOAT',
    Int64: 'TYPE_INT64',
    Uint64: 'TYPE_UINT64',
    Int32: 'TYPE_INT32',
    Fixed64: 'TYPE_FIXED64',
    Fixed32: 'TYPE_FIXED32',
    Bool: 'TYPE_BOOL',
    String: 'TYPE_STRING',
    Group: 'TYPE_GROUP',
    Message: 'TYPE_MESSAGE',
    Bytes: 'TYPE_BYTES',
    Uint32: 'TYPE_UINT32',
    Enum: 'TYPE_ENUM',
    Sfixed32: 'TYPE_SFIXED32',
    Sfixed64: 'TYPE_SFIXED64',
    Sint32: 'TYPE_SINT32',
    Sint64: 'TYPE_SINT64'
} as const;

export type FieldDescriptorProtoOrBuilderTypeEnum = typeof FieldDescriptorProtoOrBuilderTypeEnum[keyof typeof FieldDescriptorProtoOrBuilderTypeEnum];
export const FieldDescriptorProtoOrBuilderLabelEnum = {
    Optional: 'LABEL_OPTIONAL',
    Required: 'LABEL_REQUIRED',
    Repeated: 'LABEL_REPEATED'
} as const;

export type FieldDescriptorProtoOrBuilderLabelEnum = typeof FieldDescriptorProtoOrBuilderLabelEnum[keyof typeof FieldDescriptorProtoOrBuilderLabelEnum];


