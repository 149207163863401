import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios from "axios";
import { download } from "src/utils/fileUtils";
import { handleResponse } from "src/utils/apiService";

interface IVariable {
  groupId: string;
  name: string;
  scenarioId?: string;
}

const useExportRecipeLogs = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ groupId, scenarioId, name }) => {
      const data = await api.fetchResponse(() =>
        api.DfsRunConfigGroupControllerV2Api.getLogsDownloadSignedUrl(groupId, scenarioId)
      );
      if (data.signedUrl) {
        await axios
          .get(data?.signedUrl, {
            responseType: "arraybuffer",
            headers: { ...data.headers, "Access-Control-Allow-Origin": "*" }
          })
          .then((response) => {
            const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
            download(
              response.data,
              `${name}-logs-${timestamp}.log`,
              response.headers["content-type"]
            );
          })
          .catch((error) => {
            handleResponse({
              errorMessage:
                error.response?.data?.msg ||
                error.message ||
                `Error in downloading recipe logs - ${name}`
            });
          });
      } else {
        handleResponse({ errorMessage: "No logs found" });
      }
    }
  });
};

export default useExportRecipeLogs;
