import React, { useCallback, useEffect, useMemo } from "react";
import { generatePath, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  useSaveCodeRecipeMutation,
  useUpdateTransformGroupMutation,
  useTestTransformGroupV2Mutation,
  useRunTransformGroupMutation,
  useDeleteTransformMutation,
  useSaveCodeRecipeMutationV2,
  useGetInputEntities
} from "src/hooks/api";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { handleResponse } from "src/utils/apiService";
import {
  ArtifactMini,
  Entities,
  Entity,
  EntityFeaturesResponse,
  EntityTypeEnum,
  ModelMini,
  Recipe
} from "src/types";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ApiConnectorRecipeContext } from "./ApiConnectorRecipeContext";
import { useTransformErrorUtils } from "../../common/hooks/useTransformErrorUtils";
import { RecipeStatuses } from "src/constants";
import { usePollRecipe } from "src/hooks/api/transforms/usePollRecipe";
import { WebPaths } from "src/routing/routes";
import useCreateApiConnectorRecipe from "src/hooks/api/codeRecipe/useCreateApiConnectorRecipe";
import { Spinner } from "src/components";
import { useAutoSaveCodeRecipeMutation } from "src/hooks/api/codeRecipe/useAutoSaveCodeRecipeMutation";
import useAutoSave from "src/hooks/useAutoSave";
import { includes, map } from "lodash";
import useUpdateRecipeName from "src/hooks/api/transforms/useUpdateRecipeName";
import { useQuery } from "src/hooks";

export enum OUTPUT_TYPE {
  DATASET = "DATASET",
  CHART = "CHART",
  TEXT = "TEXT",
  AUTO_INFER = "AUTO_INFER",
  PROMPT_SUGGESTIONS = "PROMPT_SUGGESTIONS",
  MODEL = "MODEL"
}

export const CodeRecipeContextProvider = ({
  templates: initialTemplates,
  recipe: initialRecipe,
  entityIds,
  modelsIds,
  artifactsIds,
  children
}: $TSFixMe) => {
  const { projectId, scenarioId, jobId, jobRunId, groupId } = useParams();
  const [recipe, setRecipe] = React.useState<Recipe | null>(initialRecipe || null);
  const [recipeName, setRecipeName] = React.useState<string>(recipe?.name || "");
  const [templates, setTemplates] = React.useState<$TSFixMe[]>(initialTemplates || []);
  const [editorValue, setEditorValue] = React.useState("");
  const [savedEditorValue, setSavedEditorValue] = React.useState("");
  const [selectedApiRecipe, setSelectedApiRecipe] = React.useState<Record<string, string>[]>([]);
  const [currentSelectedApiRecipe, setCurrentSelectedApiRecipe] = React.useState<string>();

  const [previewTabs, setPreviewTabs] = React.useState<string[]>([]);
  const [inputDatasets, setInputDatasets] = React.useState<Array<Entity>>([]);
  const [selectedArtifacts, setSelectedArtifacts] = React.useState<Array<ArtifactMini>>([]);
  const [selectedModels, setSelectedModels] = React.useState<Array<ModelMini>>([]);
  const [isTestInProgress, setIsTestInProgress] = React.useState(false);
  const [codeErrorDetails, setCodeErrorDetails] = React.useState<{
    lineNo: number;
    line: string;
  } | null>(null);
  const [isSelectedEntitiesUpdateInProgess, setIsSelectedEntitiesUpdateInProgess] =
    React.useState<boolean>(false);
  const [entityFeaturesMap, setEntityFeaturesMap] = React.useState<{
    [id: string]: EntityFeaturesResponse;
  } | null>(null);

  const navigate = useNavigate();
  const queryParameters = useQuery();

  useEffect(() => {
    setTemplates(initialTemplates);
    setEditorValue(initialTemplates?.map((template: any) => template.code)?.join("\r\n\n"));
  }, [initialTemplates]);

  useEffect(() => {
    setRecipe(initialRecipe);
  }, [initialRecipe]);

  const onUpdateRecipe = (recipe: Recipe) => {
    setRecipeName(recipe?.name);
    setRecipe(recipe);
  };

  const updateTransformGroupMutation = useUpdateTransformGroupMutation();
  const updateTransformGroupName = useUpdateRecipeName();
  const saveCodeRecipeMutation = useSaveCodeRecipeMutation();
  const saveAndTestCodeRecipeMutation = useSaveCodeRecipeMutation();

  const testTransformGroupMutation = useTestTransformGroupV2Mutation();
  const runTransformGroupMutation = useRunTransformGroupMutation();
  const saveCodeRecipeMutationV2 = useSaveCodeRecipeMutationV2();
  const deleteTransformMutation = useDeleteTransformMutation();
  const { fetchAndRenderFullErrorLogs, renderTestTransformErrors } = useTransformErrorUtils();
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });

  const handleInputDatasetsChange = (
    newEntities: Entities,
    artifacts?: ArtifactMini[],
    models?: ModelMini[]
  ) => {
    if (!recipe?.id) {
      setInputDatasets(newEntities);
      if (artifacts) {
        setSelectedArtifacts(artifacts);
      }
      if (models) {
        setSelectedModels(models);
      }
      return;
    }
    const newArtifacts =
      artifacts?.map((artifact: $TSFixMe) => ({
        name: artifact.name,
        type: EntityTypeEnum.ARTIFACT
      })) || [];
    const newModels =
      models?.map((model: $TSFixMe) => ({
        name: model.name,
        type: EntityTypeEnum.MODEL
      })) || [];
    setIsSelectedEntitiesUpdateInProgess(true);
    const payload = {
      parents: [
        ...newEntities?.map((entity) => ({ id: entity.id, type: "ENTITY" })),
        ...newArtifacts,
        ...newModels
      ]
    };
    const onSuccess = () => {
      setInputDatasets(newEntities);
      if (artifacts) {
        setSelectedArtifacts(artifacts);
      }
      if (models) {
        setSelectedModels(models);
      }
      setIsSelectedEntitiesUpdateInProgess(false);
    };
    handleUpdateRecipe({ payload, onSuccess });
  };

  const handleSaveSuccess = ({
    showSuccessMsg = true,
    templatesResponse,
    transformGroupResponse,
    silent = false
  }: any) => {
    !silent &&
      showSuccessMsg &&
      handleResponse({
        successMessage: `Recipe code is saved successfully.`
      });

    setTemplates(templatesResponse);
    onUpdateRecipe(transformGroupResponse);

    setEditorValue(templatesResponse?.map((template: any) => template.code)?.join("\r\n\n"));
  };

  //Auto save recipe every two minutes
  useAutoSave(() => {
    autoSaveRecipe();
  }, 120 * 1000);

  const handleAsyncSave = React.useCallback(async () => {
    await saveCodeRecipeMutation.mutateAsync(
      {
        codeStrings: [editorValue],
        projectId: projectId!,
        recipe,
        templates,
        groupId
      },
      {
        onSuccess: ({ templatesResponse, transformGroupResponse }) => {
          handleSaveSuccess({ templatesResponse, transformGroupResponse, silent: true });
        }
      }
    );
  }, [editorValue, projectId, recipe, saveCodeRecipeMutation, templates]);

  const handleSave = (params?: { onSuccess?: () => void }) => {
    if (!editorValue) {
      return;
    }
    saveCodeRecipeMutation.mutate(
      {
        codeStrings: [editorValue],
        projectId: projectId!,
        recipe,
        templates,
        groupId
      },
      {
        onSuccess: ({ templatesResponse, transformGroupResponse }) => {
          handleSaveSuccess({ templatesResponse, transformGroupResponse });
          params?.onSuccess?.();
        }
      }
    );
  };

  const handleTest = React.useCallback(
    (sampleRows?: number) => {
      setIsTestInProgress(true);

      saveAndTestCodeRecipeMutation.mutate(
        {
          codeStrings: [editorValue],
          projectId: projectId!,
          recipe,
          templates,
          groupId: groupId || recipe?.id
        },
        {
          onSuccess: ({ templatesResponse, transformGroupResponse }) => {
            handleSaveSuccess({
              templatesResponse,
              transformGroupResponse,
              showSuccessMsg: false
            });
            testTransformGroupMutation.mutate(
              {
                recipeId: groupId || recipe?.id,
                projectId,
                sampleRows,
                testTransformConfig: transformGroupResponse?.runConfigs?.map((config: any) => ({
                  ...config,
                  templateId: config.templateId,
                  projectId,
                  name: config.name
                }))
              },
              {
                onSuccess: (response: $TSFixMe) => {
                  const outputDatasets = response?.dataMap
                    ? Object.keys(response?.dataMap).map((item) => ({
                        name: item,
                        data: response?.dataMap?.[item],
                        type: OUTPUT_TYPE.DATASET,
                        id: uuidv4()
                      }))
                    : [];
                  const outputCharts =
                    response?.entityViewData.map((item: $TSFixMe) => ({
                      ...item,
                      type: OUTPUT_TYPE.CHART,
                      id: uuidv4()
                    })) || [];

                  setPreviewTabs([...outputDatasets, ...outputCharts]);
                  setCodeErrorDetails(null);
                  handleResponse({ successMessage: `Test successful` });
                },
                onError: (error: $TSFixMe) => {
                  renderTestTransformErrors(error, false, true);
                  const responseData = error?.response?.data;
                  responseData?.extraInfo?.runErrDetails &&
                    setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
                },
                onSettled: () => {
                  setIsTestInProgress(false);
                }
              }
            );
          },
          onError: () => {
            setIsTestInProgress(false);
          }
        }
      );
    },
    [
      editorValue,
      fetchAndRenderFullErrorLogs,
      projectId,
      recipe,
      saveAndTestCodeRecipeMutation,
      templates,
      testTransformGroupMutation
    ]
  );

  const onRunSuccess = (response: any) => {
    if (response && !response.msg) {
      setCodeErrorDetails(null);
      handleResponse({ successMessage: `Run successful` });
    }
  };

  const onRunError = (error: $TSFixMe) => {
    fetchAndRenderFullErrorLogs(error, { projectId, scenarioId, groupId: recipe?.id }, false, true);
    const responseData = error?.response?.data;
    responseData?.extraInfo && setCodeErrorDetails(responseData?.extraInfo?.runErrDetails);
  };

  const handleRun = React.useCallback(async () => {
    if (!recipe?.id) {
      return;
    }
    projectId && checkEnvRelaunch(projectId);

    let timeoutId: $TSFixMe;

    runTransformGroupMutation.mutate(
      { recipeId: recipe?.id ?? groupId, scenarioId: scenarioId! },
      {
        onSuccess: onRunSuccess,
        onError: onRunError
      }
    );

    return () => clearTimeout(timeoutId);
  }, [projectId, recipe?.id, runTransformGroupMutation, scenarioId, onRunSuccess, onRunError]);

  const handleUpdateRecipe = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      const runConfigs = recipe?.runConfigs?.length
        ? recipe?.runConfigs?.map((item) => item.id)
        : [];
      const updatedPayload = {
        ...recipe,
        runConfigs,
        projectId,
        name: recipe?.name || recipeName,
        displayName: recipe?.displayName || recipe?.name || recipeName,
        parents: recipe?.parents,
        ...payload
      };
      updateTransformGroupMutation.mutate(
        {
          recipeId: recipe?.id,
          transformGroup: updatedPayload,
          projectId: projectId!
        },
        {
          onSuccess: (response: $TSFixMe) => {
            onUpdateRecipe(response);
            onSuccess?.(response);
          },
          onError: () => {
            onError?.();
          }
        }
      );
    },
    [projectId, recipe, recipeName, updateTransformGroupMutation]
  );
  const handleUpdateRecipeName = React.useCallback(
    async ({
      payload,
      onSuccess,
      onError
    }: {
      payload: $TSFixMe;
      onError?: () => void;
      onSuccess?: (recipe: Recipe) => void;
    }) => {
      if (recipe?.id)
        updateTransformGroupName.mutate(
          {
            groupId: recipe?.id,
            displayName: payload.displayName,
            condition: recipe.condition
          },
          {
            onSuccess: (response: $TSFixMe) => {
              onUpdateRecipe(response);
              onSuccess?.(response);
            },
            onError: () => {
              onError?.();
            }
          }
        );
    },
    [projectId, recipe, recipeName, updateTransformGroupMutation]
  );

  const {
    data: runningRecipe,
    isFetched: isFetchedRunningRecipe,
    isError,
    isSuccess,
    error: runError
  } = usePollRecipe({
    enabled: !!(recipe?.id && recipe?.status === RecipeStatuses.Running),
    recipeId: recipe?.id,
    scenarioId
  });

  useEffect(() => {
    if (isSuccess && runningRecipe?.status === RecipeStatuses.Success) {
      onRunSuccess(runningRecipe);
    } else if (isSuccess && runningRecipe?.status === RecipeStatuses.Error) {
      onRunError(runError);
    }
  }, [runningRecipe, runError]);

  useEffect(() => {
    isError && onRunError(runError);
  }, [runError]);

  const isRunInProgress = React.useMemo(
    () =>
      isFetchedRunningRecipe
        ? runningRecipe?.status === RecipeStatuses.Running
        : runTransformGroupMutation.isLoading || recipe?.status === RecipeStatuses.Running,
    [recipe, runTransformGroupMutation.isLoading, runningRecipe?.status, isFetchedRunningRecipe]
  );

  const isSaveInProgress = React.useMemo(
    () => saveCodeRecipeMutation.isLoading,
    [saveCodeRecipeMutation.isLoading]
  );
  const isAskAiRecipeUpdateInProgress =
    saveCodeRecipeMutationV2.isLoading || deleteTransformMutation.isLoading;

  const autoSaveCodeRecipeMutation = useAutoSaveCodeRecipeMutation();
  const autoSaveRecipe = useCallback(() => {
    if (
      !!groupId &&
      !!editorValue &&
      savedEditorValue !== editorValue &&
      !isTestInProgress &&
      !isRunInProgress &&
      !isSaveInProgress &&
      !isAskAiRecipeUpdateInProgress
    ) {
      autoSaveCodeRecipeMutation.mutate(
        {
          codeStrings: [editorValue],
          projectId: projectId!,
          recipe,
          templates,
          groupId
        },
        {
          onSuccess: (autoSavedRecipeResponse) => {
            const { templatesResponse, transformGroupResponse } = autoSavedRecipeResponse;
            const savedEditorValue = templatesResponse
              ?.map((template: any) => template.code)
              ?.join("\r\n\n");
            setSavedEditorValue(savedEditorValue);
            setTemplates(templatesResponse);
            onUpdateRecipe(transformGroupResponse);
          }
        }
      );
    }
  }, [
    groupId,
    editorValue,
    savedEditorValue,
    recipe,
    templates,
    projectId,
    isTestInProgress,
    isRunInProgress,
    isSaveInProgress
  ]);

  const isAutoSaving = autoSaveCodeRecipeMutation.isLoading;

  const isDeletableRecipe = useMemo(
    () =>
      !recipe?.condition?.expression &&
      recipe?.runConfigs?.length === 0 &&
      !editorValue &&
      previewTabs?.length === 0,
    [recipe, editorValue, previewTabs]
  );

  const isSaveDisabled =
    !editorValue ||
    isSaveInProgress ||
    isAutoSaving ||
    isTestInProgress ||
    isAskAiRecipeUpdateInProgress;

  const saveToolTip = !editorValue
    ? "Add code to enable save"
    : isAutoSaving
      ? "Autosaving code"
      : undefined;
  const runTooltip = !editorValue
    ? "Add code to enable run"
    : isRunInProgress
      ? "Recipe run is in progress"
      : !recipe?.id
        ? "Please save your changes to enable this"
        : undefined;
  const testTooltip = !editorValue ? "Add  code to enable test" : undefined;
  const isRunDisabled =
    !recipe?.id ||
    !editorValue ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress ||
    templates?.length === 0;
  const isTestDisabled =
    !editorValue ||
    isTestInProgress ||
    isSaveInProgress ||
    isAutoSaving ||
    isRunInProgress ||
    isAskAiRecipeUpdateInProgress;

  const onAddCodeToRecipe = React.useCallback(
    ({
      code: newCode,
      onSuccess,
      codeHistoryId
    }: {
      code: string;
      onSuccess?: (transformId: string) => void;
      codeHistoryId: string;
    }) => {
      saveCodeRecipeMutationV2.mutate(
        {
          recipe,
          code: newCode,
          codeHistoryId,
          projectId: projectId!
        },
        {
          onSuccess: ({ templateResponse, saveTransformResponse, transformGroupResponse }) => {
            const updatedTemplates = [...templates, templateResponse];
            onUpdateRecipe(transformGroupResponse);
            setTemplates((templates: $TSFixMe) => [...templates, templateResponse]);
            setEditorValue(updatedTemplates?.map((template) => template.code)?.join("\r\n\n"));
            onSuccess?.(saveTransformResponse.id);
          }
        }
      );
    },
    [projectId, recipe, saveCodeRecipeMutationV2, templates]
  );

  const onRemoveCodeFromRecipe = React.useCallback(
    ({ transformId, onSuccess }: { transformId: string; onSuccess: () => void }) => {
      recipe &&
        deleteTransformMutation.mutate(
          { transformId, recipeId: recipe.id },
          {
            onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
              onUpdateRecipe(transformGroupResponse);
              const recipeTemplateIds =
                transformGroupResponse?.runConfigs?.map((config) => config.templateId) || [];
              const updatedTemplates = templates?.filter((currTemplate) =>
                recipeTemplateIds.includes(currTemplate.id)
              );
              const updatedCode = updatedTemplates?.map((template) => template.code);
              setTemplates(updatedTemplates);
              setEditorValue(updatedCode?.join("\r\n\n"));
              onSuccess?.();
            }
          }
        );
    },
    [deleteTransformMutation, recipe, templates]
  );

  const isQueryAssociatedWithRecipe = React.useCallback(
    (identifier: string) => {
      return (
        (deleteTransformMutation.isLoading &&
          deleteTransformMutation.variables?.transformId === identifier) ||
        (saveCodeRecipeMutationV2.isLoading &&
          saveCodeRecipeMutationV2.variables?.codeHistoryId === identifier)
      );
    },
    [deleteTransformMutation.isLoading, saveCodeRecipeMutationV2.isLoading]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const entity = searchParams.get("entity");
  const selectedEntitiesStr = sessionStorage.getItem("selectedEntities");
  const selectedEntities = selectedEntitiesStr ? JSON.parse(selectedEntitiesStr) : null;
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const {
    isLoading,
    isSuccess: isEntitiesFetched,
    data: allEntities
  } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {})
  });

  useEffect(() => {
    const filteredInputDatasets = allEntities?.filter(
      (dataset) =>
        entityIds?.includes(dataset.id) ||
        queryParameters?.get("entity") === dataset.id ||
        (selectedEntities?.includes(dataset.id) &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== "chart" &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== "artifact" &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== "model")
    );

    const models = map(
      allEntities?.filter((dataset) => modelsIds?.includes(dataset.id)),
      (model: $TSFixMe) => ({
        name: model.name,
        type: EntityTypeEnum.MODEL
      })
    );

    const artifacts = map(
      allEntities?.filter((dataset) => artifactsIds?.includes(dataset.id)),
      (artifact: $TSFixMe) => ({
        name: artifact.name,
        type: EntityTypeEnum.MODEL
      })
    );
    setInputDatasets(filteredInputDatasets ?? []);
    setSelectedArtifacts(artifacts as ArtifactMini[]);
    setSelectedModels(models as ModelMini[]);
  }, [allEntities, artifactsIds, modelsIds, entityIds]);

  const { isFetching: isCreatingRecipe } = useCreateApiConnectorRecipe({
    projectId,
    inputDatasets,
    entity: allEntities?.filter(
      (item: $TSFixMe) => item.id === entity || includes(selectedEntities, item.id)
    ),
    options: {
      enabled: !groupId && isEntitiesFetched,
      onSuccess: (response) => {
        onUpdateRecipe(response.group);

        const entityIds = response.group.parents
          ?.filter((parent) => parent.type === "ENTITY")
          ?.map((parent) => parent.id);

        const filteredInputDatasets = allEntities?.filter(
          (dataset) => entityIds?.includes(dataset.id) || entity === dataset.id
        );
        setSearchParams({}, { replace: true });
        setInputDatasets(filteredInputDatasets ?? []);

        if (projectId && scenarioId && response.group.id) {
          navigate(
            generatePath(`${WebPaths.APIConnectorRecipeContainer}/:groupId`, {
              projectId,
              scenarioId,
              groupId: response.group.id
            }),
            { replace: true }
          );
        }
      }
    }
  });

  const contextValue = React.useMemo(() => {
    return {
      jobData,
      jobRunData,
      recipe,
      handleUpdateRecipe,
      recipeId: recipe?.id,
      isRunInProgress,
      isTestInProgress,
      isSaveInProgress: isSaveInProgress || isAutoSaving,
      isAutoSaving,
      isSaveDisabled,
      inputDatasets,
      entityFeaturesMap,
      isRunDisabled,
      saveToolTip,
      runTooltip,
      isSelectedEntitiesUpdateInProgess,
      testTooltip,
      handleSave,
      handleRun,
      isTestDisabled,
      onUpdateRecipe,
      onAddCodeToRecipe,
      onRemoveCodeFromRecipe,
      isAskAiRecipeUpdateInProgress,
      previewTabs,
      setEntityFeaturesMap,
      handleInputDatasetsChange,
      handleTest,
      setInputDatasets,
      editorValue,
      setEditorValue,
      setPreviewTabs,
      codeErrorDetails,
      isQueryAssociatedWithRecipe,
      isDeletableRecipe,
      allEntities: allEntities || [],
      setSelectedApiRecipe,
      selectedApiRecipe,
      currentSelectedApiRecipe,
      setCurrentSelectedApiRecipe,
      handleAsyncSave,
      selectedArtifacts,
      selectedModels,
      setSelectedArtifacts,
      setSelectedModels,
      handleUpdateRecipeName
    };
  }, [
    jobData,
    jobRunData,
    inputDatasets,
    entityFeaturesMap,
    isSelectedEntitiesUpdateInProgess,
    handleRun,
    handleSave,
    handleUpdateRecipe,
    setEntityFeaturesMap,
    setInputDatasets,
    handleInputDatasetsChange,
    isAskAiRecipeUpdateInProgress,
    isRunDisabled,
    isRunInProgress,
    isSaveDisabled,
    isSaveInProgress,
    isAutoSaving,
    isTestDisabled,
    isTestInProgress,
    onAddCodeToRecipe,
    onRemoveCodeFromRecipe,
    recipe,
    saveToolTip,
    runTooltip,
    testTooltip,
    previewTabs,
    handleTest,
    editorValue,
    setEditorValue,
    setPreviewTabs,
    codeErrorDetails,
    isQueryAssociatedWithRecipe,
    isDeletableRecipe,
    allEntities,
    setSelectedApiRecipe,
    selectedApiRecipe,
    currentSelectedApiRecipe,
    setCurrentSelectedApiRecipe,
    handleAsyncSave,
    selectedArtifacts,
    selectedModels,
    setSelectedArtifacts,
    setSelectedModels,
    handleUpdateRecipeName
  ]);

  if (isCreatingRecipe || isLoading) {
    return <Spinner />;
  }

  return (
    <ApiConnectorRecipeContext.Provider value={contextValue}>
      {children}
    </ApiConnectorRecipeContext.Provider>
  );
};
