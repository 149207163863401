import React from "react";

import { Table } from "@tanstack/react-table";

import { TData } from "../Table.types";

type ContextTypes = {
  table: Table<TData>;

  hideColumn: $TSFixMeFunction;
  resizeColumns: () => void;

  storeUserPreferences?: $TSFixMeFunction;
};

// @ts-ignore
export const TableContext = React.createContext<ContextTypes>();
