import React, { useEffect, useMemo, useState } from "react";
import _, { orderBy, toLower } from "lodash";
import { Checkbox, makeStyles } from "@material-ui/core";

import AccordianCard from "./AccordianCard";
import { Record } from "hooks/api/projects/useProjectDetails";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";

const useStyles = makeStyles({
  flexBetween: { display: "flex", gap: "6px", alignItems: "center" }
});

interface IProps {
  data: Record[];
  psAssociatedWithdataApp?: string[];
  onChange: (key: string, value: string[]) => void;
}

interface IPS {
  id: string;
  name: string;
  checked: boolean;
  disabled: boolean;
}

const CopyPredictionService: React.FC<IProps> = (props) => {
  const { data, psAssociatedWithdataApp, onChange } = props;
  const [predictionServices, setPredictionServices] = useState<IPS[]>([]);
  const { flexBetween } = useStyles();

  useEffect(() => {
    if (data) {
      setPredictionServices(
        _.map(data, (predictionService) => ({
          id: predictionService.id,
          name: predictionService.displayName || predictionService.name,
          checked: false,
          disabled: false
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (psAssociatedWithdataApp) {
      setPredictionServices(
        _.map(predictionServices, (ps) => ({
          ...ps,
          checked: _.includes(psAssociatedWithdataApp, ps.id) ? true : ps.checked,
          disabled: _.includes(psAssociatedWithdataApp, ps.id)
        }))
      );
    }
  }, [psAssociatedWithdataApp]);

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newPredictionServices: IPS[] = [];
    const selectedPredictionServices: string[] = [];

    _.forEach(predictionServices, (predictionService) => {
      if (predictionService.id === id) {
        newPredictionServices.push({ ...predictionService, checked: e.target.checked });
        if (e.target.checked) {
          selectedPredictionServices.push(id);
        }
      } else {
        newPredictionServices.push(predictionService);
        if (predictionService.checked) {
          selectedPredictionServices.push(predictionService.id);
        }
      }
    });

    setPredictionServices(newPredictionServices);
    onChange(projectMetadata.predictionServices.id, selectedPredictionServices);
  };

  const { checkedAll, disableCheckAll } = useMemo(() => {
    return {
      checkedAll: !_.isEmpty(predictionServices) && !_.some(predictionServices, { checked: false }),
      disableCheckAll:
        _.isEmpty(predictionServices) || _.some(predictionServices, { disabled: true })
    };
  }, [predictionServices]);

  const handleCheckAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPredictionServices: IPS[] = [];
    const selectedPredictionServices: string[] = [];

    _.forEach(predictionServices, (predictionService) => {
      newPredictionServices.push({ ...predictionService, checked: e.target.checked });
      if (e.target.checked) {
        selectedPredictionServices.push(predictionService.id);
      }
    });

    setPredictionServices(newPredictionServices);
    onChange(projectMetadata.predictionServices.id, selectedPredictionServices);
  };

  if (_.isEmpty(predictionServices)) {
    return null;
  }

  return (
    <AccordianCard
      label={projectMetadata.predictionServices.label}
      accordianDetailsStyle={{ maxHeight: "135px", overflow: "auto" }}
      title={
        <div className={flexBetween}>
          <Checkbox
            checked={checkedAll}
            disabled={disableCheckAll}
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={handleCheckAllChange}
            onClick={(e) => e.stopPropagation()}
          />
          <span>{projectMetadata.predictionServices.label}</span>
        </div>
      }>
      <div>
        {_.map(
          orderBy(predictionServices, (item) => toLower(item.name)),
          (predictionService) => (
            <div className={flexBetween} key={predictionService.id}>
              <Checkbox
                checked={predictionService.checked}
                disabled={predictionService.disabled}
                onChange={(e) => handleCheckChange(e, predictionService.id)}
                inputProps={{ "aria-label": "predictionServices checkbox" }}
              />
              <span>{predictionService.name}</span>
            </div>
          )
        )}
      </div>
    </AccordianCard>
  );
};

export default CopyPredictionService;
