import { Grid, IconButton, Link, makeStyles, Tooltip } from "@material-ui/core";
import { get, some } from "lodash";
import React, { useState } from "react";
import { OverflowTooltip } from "src/components";
import { PreviewIconNew } from "src/icons/PreviewIconNew";
import CopyNameButton from "./CopyNameButton";
import { EntityTypeEnum } from "src/types";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";

import { ArtifactIcon } from "src/icons/NewUX/ArtifactIcon";
import { EntityIcon } from "src/icons/EntityIcon";
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";
import { RapidModelIcon } from "src/icons/RapidModelIcon";
import { RecipeIcon } from "src/icons/RecipeIcon";
import { ChartIcon } from "src/icons/NewUX";

interface IProps {
  entity: any;
  navigateToDetils: (node: any) => void;
  handlePreview: (item: any) => void;
  selectedCodeRecipe: Record<string, string>[];
  isApiConnector?: boolean;
}

const useStyles = makeStyles({
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1",
    height: "35px"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "180px"
  },

  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    height: "27px"
  }
});

const IconsMapping = {
  [EntityTypeEnum.ENTITY]: <EntityIcon />,
  [EntityTypeEnum.MODEL]: <ModelIcon height={14} width={14} viewBox="0 0 16 16" />,
  [EntityTypeEnum.ARTIFACT]: <ArtifactIcon height={14} width={14} viewBox="0 0 16 16" />,
  [EntityTypeEnum.CHART]: <ChartIcon width={16} height={16} viewBox="-2 -4 40 40" />
};
const RecipeIconMapping = {
  [RecipeTypes.AiAssisted]: <AiAssistedIcon viewBox="0 0 20 20" />,
  [RecipeTypes.RapidModel]: <RapidModelIcon height={16} width={16} />,
  [RecipeTypes.ApiConnector]: <ApiConnectorIcon viewBox="0 -3 19 19" />,
  [RecipeTypes.Template]: <RecipeIcon height={20} width={20} />
};

const CanvasEntity: React.FC<IProps> = ({
  entity,
  navigateToDetils,
  handlePreview,
  selectedCodeRecipe,
  isApiConnector = false
}) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  return (
    <Grid
      container
      key={entity.name}
      className={classes.entity}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      style={
        some(selectedCodeRecipe, (item) => item.name === entity.name)
          ? { background: "#ECECF7" }
          : {}
      }>
      <div className={classes.flex}>
        {entity.type === EntityTypeEnum.DFSGROUP
          ? get(RecipeIconMapping, entity.recipeType)
          : get(IconsMapping, entity.type)}
        <div className={classes.entityText} data-testid="code-recipe-input-dataset-name">
          <Link style={{ cursor: "pointer" }} onClick={() => navigateToDetils(entity)}>
            <OverflowTooltip value={entity.displayName} style={{ whiteSpace: "nowrap" }} />
          </Link>
        </div>
      </div>
      {hovered && (
        <div style={{ display: "flex" }}>
          {entity.type === EntityTypeEnum.DFSGROUP &&
          ((isApiConnector && entity.recipeType === RecipeTypes.ApiConnector) ||
            entity.recipeType === RecipeTypes.AiAssisted) ? (
            <Tooltip title="View code">
              <IconButton
                size="small"
                data-testid={`code-recipe-preview-${entity.displayName}`}
                onClick={() => {
                  handlePreview(entity);
                }}>
                <PreviewIconNew height={16} width={16} />
              </IconButton>
            </Tooltip>
          ) : (
            <CopyNameButton name={entity.displayName} />
          )}
        </div>
      )}
    </Grid>
  );
};

export default CanvasEntity;
