/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Alert
     */
    'labels'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Alert
     */
    'annotations'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'startsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'endsAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'generatorURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'status'?: AlertStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'level'?: AlertLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'service'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'project'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'job'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'alertName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'resolvedTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    'resolvedDescription'?: string;
}

export const AlertStatusEnum = {
    Firing: 'firing',
    Resolved: 'resolved'
} as const;

export type AlertStatusEnum = typeof AlertStatusEnum[keyof typeof AlertStatusEnum];
export const AlertLevelEnum = {
    Info: 'info',
    Warning: 'warning',
    Critical: 'critical'
} as const;

export type AlertLevelEnum = typeof AlertLevelEnum[keyof typeof AlertLevelEnum];


