import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

// Icons
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { READONLY_ENTITY } from "src/constants";
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { handleResponse } from "src/utils/apiService";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useCanvasStore } from "src/store/store";
import { validateNameField } from "src/utils/formFieldUtils";
import CodeRecipeLogsButton from "../../AddCodeRecipe/CodeRecipeHeader/CodeRecipeLogsButton";
// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "../../AddCodeRecipe/CodeRecipeHeader/SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { toastWrapper } from "src/utils/toastWrapper";
import { useApiConnectorRecipeContext } from "../ApiConnectorRecipeContext/useApiConnectorRecipeContext";
import { Scenario } from "src/types";
import { useDeleteEmptyRecipe } from "../../common/hooks/useDeleteEmptyRecipe";
import { TestRecipeDropdown } from "../../AddCodeRecipe/CodeRecipeTabContainer/TestRecipeDropdown/TestRecipeDropdown";

interface IProps {
  isDefaultScenario: boolean;
  scenarioData?: Scenario;
}

interface ILogsType {
  isRunRecipeLogs: boolean;
}

const ApiConnectorRecipeHeader: React.FC<IProps> = ({ isDefaultScenario, scenarioData }) => {
  const navigate = useNavigate();
  const { projectId, scenarioId, jobRunId } = useParams<$TSFixMe>();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  const [isRecipeUpdateInProgress, setIsRecipeUpdateInProgress] = React.useState(false);
  const {
    jobData,
    jobRunData,
    isRunDisabled,
    runTooltip,
    handleUpdateRecipe,
    handleUpdateRecipeName,
    handleTest,
    isTestDisabled,
    isTestInProgress,
    testTooltip,
    isRunInProgress,
    recipe,
    handleRun,
    isDeletableRecipe,
    isSaveDisabled,
    handleSave
  } = useApiConnectorRecipeContext();

  const canSaveRecipe = !isSaveDisabled && isDefaultScenario;

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openLogsType, setOpenLogsType] = useState<ILogsType | null>(null);
  const { canAccessRecipeTimeout } = useAccessControlContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  const nodes = useCanvasStore((state) => state.nodes);

  const dfsGroupNodes = useMemo(() => {
    return nodes?.filter(
      (node: $TSFixMe) => (node as $TSFixMe)?.type?.toLowerCase() === "dfsgroup"
    );
  }, [nodes]);

  const handleLogsClick = (isRunRecipeLogs: boolean) => {
    setOpenLogsType({ isRunRecipeLogs });
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick(true);
    }
  }, [isOpenLogs]);

  const validateName = useCallback(
    (name: string) => {
      const nameExist = dfsGroupNodes.some(
        (item: $TSFixMe) => item.name === name || item.displayName === name
      );
      const { error } = validateNameField({
        fieldName: name,
        fieldNameLabel: `recipe name`
      });
      return { nameExist, error };
    },
    [dfsGroupNodes]
  );

  const updateTransformName = useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else if (recipe?.id) {
        handleUpdateRecipeName({
          payload: { displayName: cleanedName },
          onError: () => setShouldResetName(Date.now()),
          onSuccess: () =>
            toastWrapper({ type: "success", content: "Recipe Name updated Successfully" })
        });
      }
    },
    [handleUpdateRecipe, recipe?.id, validateName]
  );

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleBack = useCallback(() => {
    navigate(-1);
  }, [projectId, scenarioId]);

  const { onDeleteSuccess, onBack, onProjectClick } = useDeleteEmptyRecipe({
    isDeletableRecipe,
    projectId,
    scenarioId,
    recipeId: recipe?.id,
    handleBack,
    canSaveRecipe,
    handleSave
  });

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    setIsRecipeUpdateInProgress(true);
    handleUpdateRecipe({
      payload: { timeout },
      onSuccess: () => {
        setIsRecipeUpdateInProgress(false);
        handleResponse({ successMessage: "Recipe Run Timeout updated successfully." });
      },
      onError: () => setIsRecipeUpdateInProgress(false)
    });
  };

  const handleCloseLogs = () => {
    setOpenLogsType(null);
    setSearchParams({});
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={onDeleteSuccess}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: </span>
            {psMessage}
          </div>
        }
      />
      {!!openLogsType && (
        <RecipeLogsDrawer
          isRecipeRunning={isRunInProgress}
          isRunRecipeLogs={openLogsType.isRunRecipeLogs}
          name={recipe?.displayName ?? ""}
          open={!!openLogsType}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavAction: onBack,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              onProjectClick={onProjectClick}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              recipeName={recipe?.displayName || "Recipe"}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !recipe?.id || !isDefaultScenario,
                    ...(!isDefaultScenario ? { tooltip: READONLY_ENTITY } : {})
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          disabled: !recipe?.id,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={onUpdateRecipeRunTimeout}
                              disabled={!recipe?.id || isRecipeUpdateInProgress}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {!isJobPath && (
                <>
                  <Tooltip
                    title={
                      runTooltip ||
                      "Please remember to save your changes. Unsaved modifications won't be considered in the recipe run"
                    }>
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleRun}
                        disabled={isRunDisabled}
                        startIcon={isRunInProgress ? <CircularProgress size={18} /> : <RunIcon />}>
                        Run
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
              <Tooltip title={testTooltip || ""}>
                <span>
                  <TestRecipeDropdown
                    onTest={handleTest}
                    isTestDisabled={isTestDisabled}
                    isTestInProgress={isTestInProgress}
                  />
                </span>
              </Tooltip>
              <CodeRecipeLogsButton
                id={recipe?.id}
                isRunning={isRunInProgress}
                isTesting={isTestInProgress}
                onLogsClick={handleLogsClick}
              />
            </>
          )
        }}
      />
    </>
  );
};

export default ApiConnectorRecipeHeader;
